import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, createVNode as _createVNode, withKeys as _withKeys, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { name: "patientListEditor" }
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = { class: "row align-items-center gap-2" }
const _hoisted_4 = { class: "col-12 col-md row align-items-center" }
const _hoisted_5 = { class: "col col-md-6" }
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 0,
  class: "col-12 col-md row align-items-center"
}
const _hoisted_8 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_9 = { class: "badge badge-primary text-wrap" }
const _hoisted_10 = { class: "row pt-2 pb-4 align-items-center" }
const _hoisted_11 = { class: "col-auto toggle-switch" }
const _hoisted_12 = ["checked", "disabled"]
const _hoisted_13 = { class: "row justify-content-center mb-4" }
const _hoisted_14 = { class: "col-12 row align-items-stretch" }
const _hoisted_15 = { class: "col-6 p-0" }
const _hoisted_16 = { class: "row no-gutters align-items-center" }
const _hoisted_17 = { class: "col" }
const _hoisted_18 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_19 = {
  key: 1,
  class: "text-success"
}
const _hoisted_20 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_21 = { class: "btn btn-link" }
const _hoisted_22 = { class: "col-auto" }
const _hoisted_23 = ["disabled"]
const _hoisted_24 = { class: "col-auto" }

import { InputAutoCompletion, SancareOcticon } from '@sancare/ui-frontend-commons'
import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'
import _ from 'lodash'
import { computed, ref, watch } from 'vue'

import AdvancedConditionGroup from '@/filters/AdvancedConditionGroup.vue'
import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import { aggregatedVariableListColumns, variableListColumns } from '@/patient-list/PatientListColumns'
import router from '@/router.js'
import { useStore } from '@/store'
import { Condition, SearchTypeEnum } from '@/store/modules/search/types'
import { SnomedConcept } from '@/store/modules/snomed/types'
import { AggregationModifiers } from '@/variables/types'

import AdvancedConditionGroups from '../filters/AdvancedConditionGroups.vue'
import AggregateCondition from './AggregateCondition.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchEditor',
  props: {
    parentId: { default: null },
    studyId: { default: null },
    addRequest: {},
    editRequest: {},
    fetchRequest: {},
    searchType: {}
  },
  emits: ['refresh-patients', 'upsert-search'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const emit = __emit

const props = __props

const searchError = ref(null)
const searchSuccess = ref(null)
const stepId = computed(() => router.currentRoute.value.query.stepId && Number(router.currentRoute.value.query.stepId))

const currentUser = computed(() => store.state.settings.currentUser)
const currentSearch = computed(() => store.state.search.currentSearch)
const isSearchEdited = computed(() => store.state.search.isSearchEdited)
const currentOperatorSystem = computed(() => store.state.search.currentSearch.searchOperators)
const isSearchCreation = computed(() => {
  return currentSearch.value.id === null || props.parentId
})
const snomedServiceEnabled = computed(() => store.getters.snomedServiceEnabled)

// snomed
const snomedCompletionOpen = ref(false)
const typedSnomed = ref('')
const snomedCompletionLoading = computed(() => store.state.snomed.requests.conceptCompletion.fetching)
const snomedCompletionItems = computed(() => store.state.snomed.completionConcepts)
function selectSnomed(completionItem: SnomedConcept) {
  store.commit('setSearchSnomed', completionItem)
  closeSnomedCompletion()
}
function openSnomedCompletion() {
  snomedCompletionOpen.value = true
}
function closeSnomedCompletion() {
  snomedCompletionOpen.value = false
}
function removeSnomedConcept() {
  store.commit('setSearchSnomed', { id: null, description: null })
}

function refreshPatients(stepId: number | null) {
  if (hasPermission(RoleEnum.ROLE_STAY_READER, currentUser.value.role)) {
    emit('refresh-patients', stepId)
  }
}

function setSearchName(name: string) {
  store.commit('setSearchName', name)
}
function switchOperators() {
  const newOperators = currentOperatorSystem.value === 'orAnd' ? 'andOr' : 'orAnd'
  store.commit('updateOperators', newOperators)
  refreshPatients(stepId.value)
}

function resetEdition() {
  store.commit('resetSearchConditions')
  searchError.value = null
  refreshPatients(stepId.value)
}

// here we can save a criterion (library), a studyNode (in study) or a variable (library or in study)
async function saveSearch() {
  if (!currentSearch.value.name || currentSearch.value.name === '') {
    searchError.value = 'Le nom est invalide'
    return
  }
  await emit('upsert-search', currentSearch.value)
}
function setAggregateCondition(condition: Condition, aggregationModifiers: AggregationModifiers) {
  const currentCondition = currentSearch.value.aggregateCondition ? { ...currentSearch.value.aggregateCondition } : null
  if (condition === null) {
    store.commit('removeAggregateCondition')
  } else {
    store.commit('setAggregateCondition', { condition, aggregationModifiers })
  }
  const newCondition = currentSearch.value.aggregateCondition ? { ...currentSearch.value.aggregateCondition } : null
  if(!_.isEqual(currentCondition, newCondition)) {
    refreshPatientList()
  }
}
function refreshPatientList() {
  emit('refresh-patients', stepId.value)
  store.commit('setPatientListColumns', currentSearch.value.aggregateCondition ? aggregatedVariableListColumns : variableListColumns)
  store.commit('setDisplayedColumns', store.getters.getUserVariableColumnsSettings(currentSearch.value.aggregateCondition))
}

watch(() => props.addRequest, (req) => {
  if (req.error) {
    searchSuccess.value = null
    searchError.value = req.error
  } else if (req.ok) {
    searchError.value = null
    searchSuccess.value = 'Critère enregistré'
  }
})
watch(() => props.editRequest, (req) => {
  if (req.error) {
    searchSuccess.value = null
    searchError.value = req.error
  } else if (req.ok) {
    searchError.value = null
    searchSuccess.value = 'Modifications enregistrées'
  }
})
watch(() => isSearchEdited.value, () => {
  if (isSearchEdited.value) {
    searchError.value = null
    searchSuccess.value = null
  }
})
watch(typedSnomed, _.debounce(function() {
  if (typedSnomed.value.length > 2) {
    store.dispatch('snomed/conceptCompletion', typedSnomed.value)
  } else if (snomedCompletionItems.value.length) {
    store.commit('snomed/clearCompletionItems')
  }
}, 500, { leading: false, maxWait: 500, trailing: true }))

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(isSearchCreation.value ? 'Création' : 'Modification'), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-5 col-md-auto" }, " Nom ", -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("input", {
            value: currentSearch.value.name,
            type: "text",
            class: "form-control",
            onChange: _cache[0] || (_cache[0] = event => setSearchName(event.srcElement.value))
          }, null, 40, _hoisted_6)
        ])
      ]),
      (_ctx.searchType === _unref(SearchTypeEnum).CRITERION && !_ctx.studyId && snomedServiceEnabled.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _cache[3] || (_cache[3] = _createElementVNode("label", {
              class: "col-5 col-md-auto col-form-label",
              for: "snomedConcept"
            }, " Concept SNOMED ", -1)),
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: "col col-md-6 autocompletion-container",
              onKeydown: _withKeys(closeSnomedCompletion, ["esc"])
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((typedSnomed).value = $event)),
                name: "snomedConcept",
                class: "form-control",
                onClick: openSnomedCompletion
              }, null, 512), [
                [_vModelText, typedSnomed.value]
              ]),
              _withDirectives(_createVNode(_unref(InputAutoCompletion), {
                "completion-list": snomedCompletionItems.value,
                "is-loading": snomedCompletionLoading.value,
                "title-prop": 'id',
                "description-prop": 'description',
                onSelectItem: selectSnomed
              }, null, 8, ["completion-list", "is-loading"]), [
                [_vShow, snomedCompletionOpen.value]
              ])
            ], 32)), [
              [_directive_on_click_outside, closeSnomedCompletion]
            ]),
            (currentSearch.value.snomedConceptId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, [
                    _createTextVNode(_toDisplayString(currentSearch.value.snomedConceptId) + " : " + _toDisplayString(currentSearch.value.snomedConceptTerm) + " ", 1),
                    _createElementVNode("span", { onClick: removeSnomedConcept }, [
                      _createVNode(_unref(SancareOcticon), {
                        name: "x",
                        "custom-class": "cp",
                        width: 14,
                        height: 11
                      })
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", null, [
      _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "mb-2" }, " Conditions ", -1)),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", _hoisted_11, [
          _createElementVNode("input", {
            id: "switch-operators",
            checked: currentOperatorSystem.value === 'orAnd',
            disabled: _ctx.fetchRequest.fetching,
            type: "checkbox",
            onChange: switchOperators
          }, null, 40, _hoisted_12),
          _cache[4] || (_cache[4] = _createElementVNode("div", null, null, -1))
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col" }, " Inverser les opérateurs \"ET\"/\"OU\" dans les conditions ", -1))
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createVNode(AdvancedConditionGroup, {
            "internal-operator": 'ET',
            group: currentSearch.value.globalConditionGroup,
            "group-is-global": true,
            "search-type": _ctx.searchType,
            class: "col-12"
          }, null, 8, ["group", "search-type"])
        ])
      ]),
      _createVNode(AdvancedConditionGroups, {
        "condition-groups": currentSearch.value.conditionGroups,
        disabled: _ctx.fetchRequest.fetching,
        "search-operators": currentSearch.value.searchOperators,
        "search-type": _ctx.searchType,
        onRefreshPatients: refreshPatients
      }, null, 8, ["condition-groups", "disabled", "search-operators", "search-type"]),
      (_ctx.searchType === _unref(SearchTypeEnum).VARIABLE)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
            _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "mb-2" }, " Agrégat ", -1)),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(AggregateCondition, {
                "is-fast-condition": false,
                condition: currentSearch.value.aggregateCondition,
                modifiers: currentSearch.value.modifiers,
                onAddCondition: setAggregateCondition
              }, null, 8, ["condition", "modifiers"])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        (searchError.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(searchError.value), 1))
          : (searchSuccess.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(searchSuccess.value), 1))
            : _createCommentVNode("", true)
      ]),
      (_ctx.studyId)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createElementVNode("button", _hoisted_21, [
              _createVNode(_component_router_link, {
                to: {name: 'study', params: {studyId: _ctx.studyId}}
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode(" Retour à l'étude sans enregistrer ")
                ])),
                _: 1
              }, 8, ["to"])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_22, [
        (isSearchEdited.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              disabled: !currentSearch.value.name,
              class: "btn btn-link",
              onClick: saveSearch
            }, " Enregistrer ", 8, _hoisted_23))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_24, [
        (isSearchEdited.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-link",
              onClick: resetEdition
            }, " Annuler les modifications "))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})