import Api from '@sancare/ui-frontend-commons/src/misc/api'
import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'
import _ from 'lodash'

import LegacyApi from '../misc/api'
import { formatSearchOut } from '../misc/formatter'

export default {
  state: {
    criteria: [],
    searchCriterionResults: [],

    addCriterionRequest: { fetching: false, error: null, ok: false },
    editCriterionRequest: { fetching: false, error: null, ok: false },
    copyCriterionRequest: { fetching: false, error: null, ok: false },
    fetchCriterionRequest: { fetching: false, error: null, ok: false },
    fetchCriteriaRequest: { fetching: false, error: null, ok: false },
    removeCriterionRequest: { fetching: false, error: null, ok: false },
    searchCriterionRequest: { fetching: false, error: null, ok: false },
  },
  mutations: {
    // criterion mutations
    copyCriterionStart(state) {
      state.copyCriterionRequest = { fetching: true, error: null, ok: false }
    },
    copyCriterionSuccess(state, response) {
      state.copyCriterionRequest = { fetching: false, error: null, ok: true }
      state.criteria.push(response)
    },
    copyCriterionError(state, res) {
      state.copyCriterionRequest = { fetching: false, error: res.message, ok: false }
    },
    fetchCriterionStart(state) {
      state.fetchCriterionRequest = { fetching: true, error: null, ok: false }
    },
    fetchCriterionSuccess(state) {
      state.fetchCriterionRequest = { fetching: false, error: null, ok: true }
    },
    fetchCriterionError(state, res) {
      state.fetchCriterionRequest = { fetching: false, error: res.message, ok: false }
    },
    fetchCriteriaStart(state) {
      state.fetchCriteriaRequest = { fetching: true, error: null, ok: false }
    },
    fetchCriteriaSuccess(state, response) {
      state.fetchCriteriaRequest = { fetching: false, error: null, ok: true }
      state.criteria = response
    },
    fetchCriteriaError(state, res) {
      state.fetchCriteriaRequest = { fetching: false, error: res.message, ok: false }
      state.criteria = []
    },
    removeCriterionStart(state) {
      state.removeCriterionRequest = { fetching: true, error: null, ok: false }
    },
    removeCriterionSuccess(state, criterionId) {
      state.removeCriterionRequest = { fetching: false, error: false, ok: true }
      state.criteria = _.filter(state.criteria, (s) => s.id !== criterionId)
    },
    removeCriterionError(state, res) {
      state.removeCriterionRequest = { fetching: false, error: res.message, ok: false }
    },
    searchCriterionStart(state) {
      state.searchCriterionRequest = { fetching: true, error: null, ok: false }
    },
    searchCriterionSuccess(state, response) {
      state.searchCriterionRequest = { fetching: false, error: false, ok: true }
      state.searchCriterionResults = response
    },
    searchCriterionError(state, res) {
      state.searchCriterionRequest = { fetching: false, error: res.message, ok: false }
    },
    clearSearchCriterionResults(state) {
      state.searchCriterionResults = []
    },
    addCriterionStart(state) {
      state.addCriterionRequest = { fetching: true, error: null, ok: false }
    },
    addCriterionSuccess(state, criterion) {
      state.addCriterionRequest = { fetching: false, error: null, ok: true }
      state.criteria.push(criterion)
    },
    addCriterionError(state, res) {
      state.addCriterionRequest = { fetching: false, error: res.message, ok: false }
    },
    editCriterionStart(state) {
      state.editCriterionRequest = { fetching: true, error: null, ok: false }
    },
    editCriterionSuccess(state) {
      state.editCriterionRequest = { fetching: false, error: null, ok: true }
    },
    editCriterionError(state, res) {
      state.editCriterionRequest = { fetching: false, error: res.message, ok: false }
    },
  },
  actions: {
    addCriterion({ commit }, criterion) {
      return LegacyApi.post(
        {
          url: '/api/criterion/add',
          body: formatSearchOut(criterion),
          startMut: 'addCriterionStart',
          successMut: (criterion) => {
            commit('addCriterionSuccess')
            commit('setCurrentSearch', criterion)
            commit('unmarkSearchEdited')
          },
          errorMut: 'addCriterionError',
        },
        commit
      )
    },
    editCriterion({ commit }, criterion) {
      return LegacyApi.put(
        {
          url: `/api/criterion/${criterion.id}`,
          body: formatSearchOut(criterion),
          startMut: 'editCriterionStart',
          successMut: (criterion) => {
            commit('editCriterionSuccess')
            commit('setCurrentSearch', criterion)
            commit('unmarkSearchEdited')
          },
          errorMut: 'editCriterionError',
        },
        commit
      )
    },
    copyCriterion({ commit }, criterionId) {
      return LegacyApi.get(
        {
          url: `/api/criterion/copy/${criterionId}`,
          startMut: 'copyCriterionStart',
          successMut: 'copyCriterionSuccess',
          errorMut: 'copyCriterionError',
        },
        commit
      )
    },
    fetchCriterion({ commit }, criterionId) {
      return LegacyApi.get(
        {
          url: `/api/criterion/${criterionId}`,
          startMut: 'fetchCriterionStart',
          successMut: (criterion) => {
            commit('setCurrentSearch', criterion)
            commit('fetchCriterionSuccess')
          },
          errorMut: () => {
            commit('resetNewSearch')
            commit('fetchCriterionError')
          },
        },
        commit
      )
    },
    fetchCriteria({ commit, state }, { force }) {
      force = Boolean(force)
      // Check if already loaded or loading
      if (!force && (state.fetchCriteriaRequest.fetching || state.fetchCriteriaRequest.ok)) {
        return null
      }

      return LegacyApi.get(
        {
          url: '/api/criterion/',
          startMut: 'fetchCriteriaStart',
          successMut: 'fetchCriteriaSuccess',
          errorMut: 'fetchCriteriaError',
        },
        commit
      )
    },
    removeCriterion({ commit }, criterionId) {
      return LegacyApi.del(
        {
          url: `/api/criterion/${criterionId}`,
          body: '',
          startMut: 'removeCriterionStart',
          successMut: () => commit('removeCriterionSuccess', criterionId),
          errorMut: 'removeCriterionError',
        },
        commit
      )
    },
    searchCriterion({ commit }, { search, signal }) {
      return Api.request(
        new ApiRequest('searchCriterion', 'GET', '/api/criterion/search-criterion'),
        {
          urlParams: { search },
          signal,
          startMut: 'searchCriterionStart',
          successMut: 'searchCriterionSuccess',
          errorMut: 'searchCriterionError',
        },
        commit,
      )
    },
  },
}
