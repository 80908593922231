import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createVNode as _createVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-warning mt-3"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 1,
  class: "row no-gutters align-items-center gap-1"
}
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "row mb-2 gap-2 no-gutters" }
const _hoisted_7 = { class: "col-12 col-md" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "col-12 col-md" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "input-group align-items-center justify-content-center gap-2" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "row col-auto" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "col-auto" }
const _hoisted_16 = { class: "mt-2" }

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import { DurationUnitType } from 'dayjs/plugin/duration'
import _ from 'lodash'
import { computed, reactive, Ref, ref } from 'vue'
import VueSlider from 'vue-slider-component'

import { FilterData } from '@/filters/types'
import { useStore } from '@/store'

interface Range {
  min: string | number
  max: string | number
  list: number[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpositionFilter',
  props: {
    filterData: {},
    isFastCondition: { type: Boolean, default: false }
  },
  emits: ['add-filter'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const props = __props
// the keys for unitLabels must be valid units for dayjs.duration()
// see https://day.js.org/docs/en/durations/creating
const unitLabels = ref({
  days: 'jour(s)',
  weeks: 'semaine(s)',
  months: 'mois',
  years: 'année(s)',
})
const whenLabels = ref({
  after: 'après',
  before: 'avant',
})
const unit: Ref<DurationUnitType> = ref('months')
const when = ref('after')
const emit = __emit

const inputMode = ref('slider')
const selectedValues = ref([0, 30]) as Ref<(number)[]>
const range = reactive({ min: 0, max: 30, list: _.range(0, 31, 1) }) as Range

const stepNames = computed(() => {
  return store.getters.stepNames
})
const currentStep = computed(() => {
  return store.state.study.currentStudyStep
})
const refStep = ref(stepNames.value.length > 0 ? stepNames.value[0].position : null)

function addFilter() {
  const name = props.filterData.name
  const type = props.filterData.format
  const unitDuration = dayjs.duration(1, unit.value)
  const signFactor = when.value === 'after' ? -1 : 1
  const values = selectedValues.value.map((el: number) => el * unitDuration.asDays() * signFactor)
    .sort((a, b) => a - b)
  values.push(refStep.value)
  const value = values.join('_')

  emit('add-filter', { name, type, value })
}
function toggleInputMode() {
  inputMode.value = inputMode.value === 'slider' ? 'manual' : 'slider'
}

return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!

  return (refStep.value === null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Ce type de condition n'est pas disponible en dehors d'une étude "))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["mb-2", inputMode.value === 'slider' ? 'mt-5 col' : 'mt-3'])
        }, [
          (inputMode.value === 'slider')
            ? (_openBlock(), _createBlock(_unref(VueSlider), {
                key: 0,
                modelValue: selectedValues.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedValues).value = $event)),
                "v-data": range.list,
                tooltip: 'always',
                range: ""
              }, null, 8, ["modelValue", "v-data"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-1 text-center text-nowrap" }, " de ", -1)),
                _createElementVNode("div", _hoisted_4, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedValues.value[0]) = $event)),
                    type: "number",
                    min: "0",
                    max: "100",
                    step: "1",
                    class: "form-control"
                  }, null, 512), [
                    [_vModelText, selectedValues.value[0]]
                  ])
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-1 text-center" }, " à ", -1)),
                _createElementVNode("div", _hoisted_5, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedValues.value[1]) = $event)),
                    type: "number",
                    min: "0",
                    max: "100",
                    step: "1",
                    class: "col form-control"
                  }, null, 512), [
                    [_vModelText, selectedValues.value[1]]
                  ])
                ])
              ]))
        ], 2),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((unit).value = $event)),
              class: "custom-select w-100",
              name: "exposition-unit-select"
            }, [
              _cache[9] || (_cache[9] = _createElementVNode("option", {
                value: "",
                disabled: ""
              }, " --- ", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(unitLabels.value, (label, key) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: 'expoUnit_'+key,
                  value: key
                }, _toDisplayString(label), 9, _hoisted_8))
              }), 128))
            ], 512), [
              [_vModelSelect, unit.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((when).value = $event)),
              class: "custom-select w-100",
              name: "exposition-when-select"
            }, [
              _cache[10] || (_cache[10] = _createElementVNode("option", {
                value: "",
                disabled: ""
              }, " --- ", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(whenLabels.value, (label, key) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: 'expoWhen_'+key,
                  value: key
                }, _toDisplayString(label), 9, _hoisted_10))
              }), 128))
            ], 512), [
              [_vModelSelect, when.value]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((refStep).value = $event)),
            class: "custom-select",
            name: "exposition-step-select"
          }, [
            _cache[11] || (_cache[11] = _createElementVNode("option", {
              value: "",
              disabled: ""
            }, " ---Etape considérée comme date d'index--- ", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stepNames.value, (step) => {
              return (_openBlock(), _createElementBlock("option", {
                key: step.position,
                value: step.position
              }, _toDisplayString(step.name), 9, _hoisted_12))
            }), 128))
          ], 512), [
            [_vModelSelect, refStep.value]
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("button", {
              class: "btn btn-primary",
              type: "button",
              disabled: currentStep.value && refStep.value >= currentStep.value.position,
              onClick: addFilter
            }, " Ok ", 8, _hoisted_14),
            (currentStep.value && refStep.value >= currentStep.value.position)
              ? (_openBlock(), _createBlock(_component_v_dropdown, {
                  key: 0,
                  triggers: ['hover'],
                  placement: "top",
                  "popover-class": "warning-popover",
                  class: "align-self-center"
                }, {
                  popper: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createElementVNode("div", { style: {"width":"30rem"} }, " L'étape considérée comme date d'index doit être positionnée avant l'étape courante ", -1)
                  ])),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_unref(SancareOcticon), {
                        name: "alert",
                        width: 20,
                        height: 20
                      })
                    ]),
                    _renderSlot(_ctx.$slots, "default")
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("span", {
            class: "btn btn-link",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (toggleInputMode()))
          }, _toDisplayString(inputMode.value === 'slider' ? 'Mode manuel' : 'Mode normal'), 1)
        ])
      ]))
}
}

})