import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import { DrugEventsViewer } from '@sancare/ui-frontend-commons'
import { HealthConstantsViewer } from '@sancare/ui-frontend-commons'
import { LabResultsViewer } from '@sancare/ui-frontend-commons'
import { NamedEntitiesViewer } from '@sancare/ui-frontend-commons'
import { DocumentSourceIdentifier, HealthDataType } from '@sancare/ui-frontend-commons/src/types/health-data'
import _ from 'lodash'
import { computed, ref } from 'vue'

import { useStore } from '@/store'
import { Report, TextualHealthEntry } from '@/store/modules/health-data/types'

import ReportViewer from './health-data/ReportViewer.vue'
import TextualHealthEntriesViewer from './health-data/TextualHealthEntriesViewer.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentViewer',
  setup(__props) {

const store = useStore()

const currentStay = computed(() => store.state.patient.currentStay)
const currentDocument = computed(() => store.state.patient.currentDocument)
const currentDocumentType = computed(() => store.state.patient.currentDocumentType)
const currentDrugEventPrestationType = computed(() => store.state.patient.currentDrugEventPrestationType)

const isDocumentSource = ref(false)

function setCurrentDocument(documentSource: DocumentSourceIdentifier) {
  let document: Report | TextualHealthEntry

  switch (documentSource.type) {
    case HealthDataType.REPORT:
      document = _.find(currentStay.value.reports, (report: Report) => report.id === documentSource.id)
      break
    case HealthDataType.TEXTUAL_HEALTH_ENTRY:
      document = _.find(currentStay.value.textualHealthEntries, (textualHealthEntry: TextualHealthEntry) => textualHealthEntry.id === documentSource.id)
      break
    default:
      document = null
  }
  store.commit('patient/setCurrentDocument', { document, documentType: documentSource.type })
  isDocumentSource.value = true
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (currentDocumentType.value === _unref(HealthDataType).REPORT)
      ? (_openBlock(), _createBlock(ReportViewer, {
          key: 0,
          report: (currentDocument.value as Report),
          "is-document-source": isDocumentSource.value
        }, null, 8, ["report", "is-document-source"]))
      : (currentDocumentType.value === _unref(HealthDataType).DRUG_EVENT)
        ? (_openBlock(), _createBlock(_unref(DrugEventsViewer), {
            key: 1,
            "drug-events": currentStay.value.drugEvents.filter((item) => item.prestationType === currentDrugEventPrestationType.value),
            "drug-event-prestation-type": currentDrugEventPrestationType.value
          }, null, 8, ["drug-events", "drug-event-prestation-type"]))
        : (currentDocumentType.value === _unref(HealthDataType).CONSTANT)
          ? (_openBlock(), _createBlock(_unref(HealthConstantsViewer), {
              key: 2,
              "health-constants": currentStay.value.healthConstants,
              onShowSource: _cache[0] || (_cache[0] = (documentSource) => setCurrentDocument(documentSource))
            }, null, 8, ["health-constants"]))
          : (currentDocumentType.value === _unref(HealthDataType).LAB_RESULT)
            ? (_openBlock(), _createBlock(_unref(LabResultsViewer), {
                key: 3,
                "numerical-lab-results": currentStay.value.labResults,
                "categorical-lab-results": currentStay.value.categoricalLabResults,
                onShowSource: _cache[1] || (_cache[1] = (documentSource) => setCurrentDocument(documentSource))
              }, null, 8, ["numerical-lab-results", "categorical-lab-results"]))
            : (currentDocumentType.value === _unref(HealthDataType).NAMED_ENTITY)
              ? (_openBlock(), _createBlock(_unref(NamedEntitiesViewer), {
                  key: 4,
                  "named-entities": currentStay.value.namedEntities,
                  "highlighted-named-entity-ids": [],
                  onShowSource: _cache[2] || (_cache[2] = (documentSource) => setCurrentDocument(documentSource))
                }, null, 8, ["named-entities"]))
              : (currentDocumentType.value === _unref(HealthDataType).TEXTUAL_HEALTH_ENTRY)
                ? (_openBlock(), _createBlock(TextualHealthEntriesViewer, {
                    key: 5,
                    "textual-health-entries": currentStay.value.textualHealthEntries,
                    "active-textual-health-entry": (currentDocument.value as TextualHealthEntry),
                    "is-document-source": isDocumentSource.value
                  }, null, 8, ["textual-health-entries", "active-textual-health-entry", "is-document-source"]))
                : _createCommentVNode("", true)
  ]))
}
}

})