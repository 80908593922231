import { Criterion } from '@/store/modules/search/types'
import { Variable } from '@/variables/types'

export function formatSearchOut(search: Criterion | Variable) {
  const globalConditionGroup = search.globalConditionGroup?.conditionList.length === 0
    ? null
    : search.globalConditionGroup

  return {
    ...search,
    globalConditionGroup,
  }
}
