import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "px-5 px-md-3" }
const _hoisted_2 = { class: "criteria-groups-wrapper gap-3" }
const _hoisted_3 = { class: "operator d-flex align-items-center justify-content-center text-center" }
const _hoisted_4 = { class: "d-flex flex-column flex-md-row align-items-center gap-2 align-items-stretch mb-2 pb-2" }

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { computed } from 'vue'

import router from '@/router'
import { getDisplayableOperator } from '@/search/utils'
import { useStore } from '@/store'
import { ConditionGroup, operatorLabels, SearchOperatorEnum, SearchTypeEnum } from '@/store/modules/search/types'

import AdvancedConditionGroup from './AdvancedConditionGroup.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AdvancedConditionGroups',
  props: {
    conditionGroups: {},
    disabled: { type: Boolean, default: false },
    searchOperators: {},
    searchType: {}
  },
  emits: ['clear-message', 'refresh-patients'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()

const props = __props
const emit = __emit
const externalOperator = computed(() => getDisplayableOperator(props.searchOperators))
const internalOperator = computed(() => getDisplayableOperator(props.searchOperators, true))
const stepId = computed(() => router.currentRoute.value.query.stepId && Number(router.currentRoute.value.query.stepId))

function clearMessage() {
  emit('clear-message')
}

function addConditionGroup() {
  clearMessage()
  if (!props.disabled) {
    store.commit('addConditionGroup')
  }
}

function refreshPatients(stepId) {
  emit('refresh-patients', stepId)
}

function deleteConditionGroup({ groupId }) {
  clearMessage()
  store.commit('removeConditionGroup', { groupId })
  refreshPatients(stepId.value)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.conditionGroups, (group, idx) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: group.id
        }, [
          _createVNode(AdvancedConditionGroup, {
            class: "w-100",
            "internal-operator": internalOperator.value,
            group: group,
            "group-is-global": false,
            "search-type": _ctx.searchType,
            onDeleteConditionGroup: deleteConditionGroup,
            onClearMessage: clearMessage,
            onRefreshPatients: refreshPatients
          }, null, 8, ["internal-operator", "group", "search-type"]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(operatorLabels)[externalOperator.value]), 1)
        ], 64))
      }), 128)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(["condition-group-add text-center gap-2", { disabled: _ctx.disabled }]),
          onClick: addConditionGroup
        }, [
          _createVNode(_unref(SancareOcticon), {
            name: "diff-added",
            width: 30
          }),
          _cache[0] || (_cache[0] = _createElementVNode("div", null, "Ajouter un groupe de conditions", -1))
        ], 2)
      ])
    ])
  ]))
}
}

})