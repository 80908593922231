import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row m-1 mt-4" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = ["value", "disabled"]
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12 mt-2" }
const _hoisted_8 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_9 = { class: "row m-1" }
const _hoisted_10 = { class: "col-7" }
const _hoisted_11 = {
  key: 0,
  class: "row"
}
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "col-auto" }
const _hoisted_14 = { class: "col-3 text-right" }

import { SancareOcticon, SancareToggle } from '@sancare/ui-frontend-commons'
import { computed, nextTick, onMounted, ref } from 'vue'

import CriterionLibrary from '@/criteria/CriterionLibrary.vue'
import { studyPermissions } from '@/misc/permissions/permissionsHelper'
import PatientListCount from '@/patient-list/PatientListCount.vue'
import FastConditionCreator from '@/search/FastConditionCreator.vue'
import { useStore } from '@/store'
import { SearchTypeEnum } from '@/store/modules/search/types'
import { Condition, Criterion } from '@/store/modules/search/types'
import { StudyStep } from '@/store/modules/study/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsStep',
  props: {
    step: {},
    isReadonly: { type: Boolean },
    canShowPatients: { type: Boolean }
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const showRemoveConfirm = ref(false)
const showAddCondition = ref(false)

const currentUser = computed(() => store.state.settings.currentUser)
const currentStudy = computed(() => store.state.study.currentStudy)
const previousStep = computed(() => props.step.position === 0 ? null : currentStudy.value.steps.find((step) => step.position === props.step.position - 1))
const nextStep = computed(() => currentStudy.value.steps.find((step) => step.position === props.step.position + 1) ?? null)
const patientCount = computed(() => store.state.study.currentStudyStats[props.step.id] ? store.state.study.currentStudyStats[props.step.id].patientCount : 0)
const coreToggleEnabled = computed(() => {
  return props.step.position !== 0
    && (!previousStep.value || previousStep.value.isCore)
    && (!nextStep.value || !nextStep.value.isCore)
    && studyPermissions.canEditCoreStep(currentStudy.value, currentUser.value)
})

onMounted(() => {
  store.commit('setCurrentStudyStep', props.step.id)
})

function addFastCondition(condition: Condition, name: string) {
  if (name === '') {
    return
  }
  store.commit(
    'addStudyNodeFromCondition',
    { parent: props.step.rootNode, name, condition }
  )
  showAddCondition.value = false
  store.dispatch('updateStudy')
}
async function addStudyNode() {
  // wait for rendering so coordinates are computed before dispatch
  await store.commit('addStudyNode', { parent: props.step.rootNode })
  store.dispatch('addStudyNode', {
    parentId: props.step.rootNode.id,
    studyNode: props.step.rootNode.children[props.step.rootNode.children.length - 1],
  })
}
async function copyStep() {
  // wait for rendering so coordinates are computed before dispatch
  await store.commit('addStep', { copyFrom: props.step })
  store.dispatch('updateStudy')
}
function updateStepAtrtibute(attribute: string, value: string | boolean) {
  store.commit('updateStepProperty', { stepId: props.step.id, prop: attribute, value })
  store.dispatch('updateStudyStepAttributes', props.step)
  nextTick(function() {
    store.commit('refreshStep', props.step.id)
  })
}
function setStepName(value: string) {
  updateStepAtrtibute('name', value)
}
function setStepIsCore(isCore: boolean) {
  updateStepAtrtibute('isCore', isCore)
}
function removeStep() {
  store.commit('removeStep', props.step)
  showRemoveConfirm.value = false
  store.dispatch('updateStudy')
}
function resetCurrentNode() {
  store.commit('resetNewSearch')
  store.commit('resetNewStudyNode')
}
function displayFastConditionEditor() {
  showAddCondition.value = true
}
async function selectCriterion(criterion: Criterion) {
  // wait for rendering so coordinates are computed before dispatch
  await store.commit('addStudyCriterion', { parentId: props.step.rootNode.id, criterion })
  showAddCondition.value = false
  store.dispatch('updateStudy')
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-3 py-2" }, " Nom ", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("input", {
                value: _ctx.step.name,
                type: "text",
                class: "form-control",
                disabled: _ctx.isReadonly,
                onChange: _cache[0] || (_cache[0] = event => setStepName(event.srcElement.value))
              }, null, 40, _hoisted_5)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_unref(SancareToggle), {
                disabled: !coreToggleEnabled.value,
                label: 'Etape socle',
                value: _ctx.step.isCore,
                onToggleChange: setStepIsCore
              }, null, 8, ["disabled", "value"])
            ])
          ])
        ]),
        _createElementVNode("div", null, [
          (!_ctx.isReadonly)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "d-block btn btn-rounded",
                onClick: addStudyNode
              }, " Ajouter un opérateur "))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          (!_ctx.isReadonly)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "d-block btn btn-rounded",
                onClick: displayFastConditionEditor
              }, " Ajouter un critère d'étude "))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          (!_ctx.isReadonly)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "d-block btn btn-rounded",
                onClick: copyStep
              }, " Copier l'étape "))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          (showRemoveConfirm.value)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-link text-danger",
                onClick: removeStep
              }, " Confirmer "))
            : (!_ctx.isReadonly)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "d-block btn btn-link text-danger",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (showRemoveConfirm.value = true), ["prevent"]))
                }, " Supprimer l'étape "))
              : _createCommentVNode("", true)
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col" }, null, -1)),
        (_ctx.canShowPatients)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(PatientListCount, {
                class: "col-auto",
                "patient-count": patientCount.value
              }, null, 8, ["patient-count"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "col" }, null, -1)),
      _createElementVNode("div", _hoisted_9, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-1" }, null, -1)),
        _createElementVNode("div", _hoisted_10, [
          (showAddCondition.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(FastConditionCreator, {
                    "initial-condition-name": "Créer un critère rapide",
                    "search-type": _unref(SearchTypeEnum).CRITERION,
                    onAddCondition: addFastCondition
                  }, null, 8, ["search-type"])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-uppercase" }, " Utiliser un critère existant ", -1)),
                  _createVNode(CriterionLibrary, { onSelectCriterion: selectCriterion }),
                  _createVNode(_component_router_link, {
                    to: {name: 'studyNodeNew', query: {studyId: currentStudy.value.id, parentId: _ctx.step.rootNode.id, stepId: _ctx.step.id}},
                    onClick: resetCurrentNode
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" Créer un nouveau critère ")
                    ])),
                    _: 1
                  }, 8, ["to"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-1" }, null, -1)),
        _createElementVNode("div", _hoisted_14, [
          (_ctx.canShowPatients)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: {name: 'patientList', query: {studyId: currentStudy.value.id, stepId: _ctx.step.id}},
                class: "btn btn-rounded col-auto"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(SancareOcticon), {
                    name: "arrow-right",
                    width: 18,
                    height: 18
                  }),
                  _cache[6] || (_cache[6] = _createTextVNode(" Voir la liste des patients "))
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})