import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = { class: "table" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import { computed } from 'vue'

import { getChunkClass } from '@/misc/JustificationHelper'
import { useStore } from '@/store'
import { TextualHealthEntry, TextualHealthEntryType } from '@/store/modules/health-data/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'TextualHealthEntriesViewer',
  props: {
    textualHealthEntries: {},
    activeTextualHealthEntry: {},
    isDocumentSource: { type: Boolean }
  },
  setup(__props: any) {

const store = useStore()



const textWorkerReady = computed(() => store.state.patient.textWorkerReady)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'highlight-on-click' : _ctx.isDocumentSource })
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-body" }, [
      _createElementVNode("h1", { class: "report-title" }, " Rapports infirmiers ")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, "Date"),
            _createElementVNode("th", null, "Type"),
            _createElementVNode("th", null, "Contenu")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textualHealthEntries, (textualHealthEntry) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: `drug-event-${textualHealthEntry.id}`,
              class: _normalizeClass({ 'hinted-line-hightlighted': textualHealthEntry === _ctx.activeTextualHealthEntry })
            }, [
              _createElementVNode("td", null, _toDisplayString(textualHealthEntry.creationDate ? _unref(dayjs)(textualHealthEntry.creationDate).format('L') : '-'), 1),
              _createElementVNode("td", null, _toDisplayString(_unref(TextualHealthEntryType)[textualHealthEntry.type] || '-'), 1),
              (textualHealthEntry.chunks && textWorkerReady.value)
                ? (_openBlock(), _createElementBlock("td", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(textualHealthEntry.chunks, (chunk) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: chunk.hash,
                        class: _normalizeClass([_unref(getChunkClass)(chunk), "text-data-content"])
                      }, _toDisplayString(chunk.string), 3))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("td", _hoisted_4, _toDisplayString(textualHealthEntry.content || '-'), 1))
            ], 2))
          }), 128))
        ])
      ])
    ])
  ], 2))
}
}

})