import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "search-list" }
const _hoisted_2 = { class: "home-list-part" }
const _hoisted_3 = { class: "row no-gutters" }
const _hoisted_4 = { class: "col home-list-part-title" }
const _hoisted_5 = { class: "row no-gutters d-flex flex-wrap" }
const _hoisted_6 = {
  key: 0,
  class: "col-3 fixed-thumbnail"
}
const _hoisted_7 = { class: "row no-gutters" }
const _hoisted_8 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = {
  key: 1,
  class: "col-auto"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 2,
  class: "col-auto"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { key: 1 }
const _hoisted_16 = ["onClick"]

import { computed, Ref, ref } from 'vue'
import { LocationQueryRaw } from 'vue-router'

import { studyPermissions } from '@/misc/permissions/permissionsHelper'
import { useStore } from '@/store'
import { Study } from '@/store/modules/study/types'

import useRedirection from '../misc/composables/redirection'
import HomeThumbnail from './HomeThumbnail.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyList',
  props: {
    studyList: {},
    title: {}
  },
  setup(__props: any) {

const store = useStore()


const studyThumbnailButton = {
  name: 'create-study',
  label: 'Créer une étude',
  headerItem: '+',
  linkPath: '/study/new',
  linkQuery: {},
}
const showRemoveConfirm = ref([]) as Ref<number[]>

const currentUser = computed(() => store.state.settings.currentUser)

function removeStudy(study: Study) {
  if (study.validatedAt !== null) {
    store.dispatch('disableStudy', study.id)
    return
  }
  store.dispatch('removeStudy', study.id)
}
function removeConfirmStudy(studyId: number) {
  showRemoveConfirm.value = []
  showRemoveConfirm.value.push(studyId)
}

const { redirect } = useRedirection()

const handleRedirect = (link: string, query: LocationQueryRaw) => {
  redirect(link, query)
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_unref(studyPermissions).canCreate(currentUser.value))
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(HomeThumbnail, {
                label: studyThumbnailButton.label,
                "header-item": studyThumbnailButton.headerItem,
                onClick: _cache[0] || (_cache[0] = () => handleRedirect(studyThumbnailButton.linkPath, studyThumbnailButton.linkQuery))
              }, null, 8, ["label", "header-item"])
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.studyList, (study) => {
          return (_openBlock(), _createElementBlock("div", {
            key: study.id,
            class: "col-3 fixed-thumbnail"
          }, [
            _createVNode(HomeThumbnail, {
              label: study.name,
              "header-item": study.countPatient,
              onClick: () => handleRedirect('/patient-list', {studyId: study.id})
            }, {
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  (_unref(studyPermissions).canUpdateVariables(study, currentUser.value))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_router_link, {
                          class: "btn btn-link",
                          to: { name: 'studyVariable', params: {studyId: study.id} }
                        }, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode(" Définir les variables d'intérêt ")
                          ])),
                          _: 2
                        }, 1032, ["to"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_router_link, {
                      class: "btn btn-link",
                      to: { name: 'study', params: {studyId: study.id} }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(studyPermissions).canEditCoreStep(study, currentUser.value) || _unref(studyPermissions).canEditSteps(study, currentUser.value) ? 'Définir la cohorte' : 'Voir'), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  (_unref(studyPermissions).canExport(study, currentUser.value))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("a", {
                          href: '/api/study/'+study.id+'/export',
                          class: "btn btn-link",
                          target: "_blank"
                        }, " Exporter la structure ", 8, _hoisted_11)
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(studyPermissions).canDelete(study, currentUser.value))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        (showRemoveConfirm.value.find(studyId => studyId === study.id))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              _createElementVNode("button", {
                                class: "btn btn-link text-danger",
                                onClick: ($event: any) => (removeStudy(study))
                              }, " Confirmer ", 8, _hoisted_14)
                            ]))
                          : _createCommentVNode("", true),
                        (!showRemoveConfirm.value.find(studyId => studyId === study.id))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                              _createElementVNode("button", {
                                class: "btn btn-link text-danger",
                                onClick: ($event: any) => (removeConfirmStudy(study.id))
                              }, " Supprimer ", 8, _hoisted_16)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["label", "header-item", "onClick"])
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})