<script>
import { generateType, initializeSubFiltersValues } from '@/filters/utils'

export default {
  props: {
    filterData: { type: Object, required: true },
    isAdvancedSearch: { type: Boolean, required: true }
  },
  emits: ['add-filter'],
  data() {
    return {
      subFiltersValues: initializeSubFiltersValues(this.filterData),
      value: this.filterData.default || '',
    }
  },
  methods: {
    addFilter() {
      if (!this.value || !this.value.trim()) {
        return
      }
      const name = this.filterData.name
      const value = this.value
      const type = generateType(this.filterData.format, this.subFiltersValues)
      this.$emit('add-filter', { name, type, value })
    },
  },
}
</script>

<template>
  <div class="row">
    <div
      :class="{
        'col-4': !isAdvancedSearch,
        'col-12': isAdvancedSearch
      }"
      class="input-group"
    >
      <select
        v-for="filter in filterData.subFilters"
        :key="filter.name"
        v-model="subFiltersValues[filter.name]"
        class="custom-select"
      >
        <option
          v-for="opt in filter.options"
          :key="opt"
          :value="opt"
        >
          {{ filter.labels[opt] ? filter.labels[opt] : opt }}
        </option>
      </select>
      <input
        v-model="value"
        name="presentTitleCriteria_input"
        class="form-control col p-1"
        @keyup.enter="addFilter"
      >
      <div class="input-group-append">
        <button
          class="btn btn-primary"
          type="button"
          @click="addFilter"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</template>
