import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, unref as _unref, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_4 = { class: "input-group" }
const _hoisted_5 = ["value"]
const _hoisted_6 = {
  key: 0,
  class: "input-group-append"
}
const _hoisted_7 = ["onUpdate:modelValue", "name"]
const _hoisted_8 = ["value"]
const _hoisted_9 = { key: 1 }

import { MedicalUnitFilter } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { computed, Ref, ref, watch } from 'vue'

import FiltersData from '@/filters/FiltersData'
import { FilterDataType } from '@/filters/types'
import { useStore } from '@/store'
import { AggregationModifiers } from '@/variables/types'

import SelectFilter from './advanced-filters/AdvancedSelectFilter.vue'
import AdvancedTextContentCondition from './advanced-filters/AdvancedTextContentCriteria.vue'
import BiologyFilter from './BiologyFilter.vue'
import CompletionFilter from './CompletionFilter.vue'
import ConstantFilter from './ConstantFilter.vue'
import DateFilter from './DateFilter.vue'
import ExpositionFilter from './ExpositionFilter.vue'
import { aggregationFunctionList } from './FiltersData'
import RangeFilter from './RangeFilter.vue'
import TextCondition from './TextCriteria.vue'
import VariableFilter from './VariableFilter.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AdvancedFilterBuilder',
  props: {
    groupIsGlobal: { type: Boolean, default: false },
    isFastCondition: { type: Boolean, default: false },
    withAggregation: { type: Boolean, default: false }
  },
  emits: ['add-condition'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const emit = __emit
const props = __props

const filterName = ref(null)
const filterAggregation = ref(null)
const filterAggregationModifiers = ref (null) as Ref<AggregationModifiers>
const allowedAggregateTypes = computed(() => {
  return _.pickBy(aggregationFunctionList, (aggFunc) => {
    return FiltersData[filterName.value].allowedAggregateTypes.indexOf(aggFunc.type) !== -1
  })
})
const filterAggregationSubFilters = computed(() => aggregationFunctionList[filterAggregation.value] ? (aggregationFunctionList[filterAggregation.value].subFilters ?? []) : [])

const filterType = computed(() => {
  if (!FiltersData[filterName.value]) {
    return null
  }

  return props.withAggregation ? (FiltersData[filterName.value].aggregationType ?? FiltersData[filterName.value].type) : FiltersData[filterName.value].type
})
const filterList = computed(() => {
  return _.keyBy(
    _.sortBy(
      _.filter(FiltersData, (filter) => {
        if (!props.withAggregation && !filter.allowedInCondition) {
          return false
        }
        if (props.withAggregation && filter.allowedAggregateTypes.length === 0) {
          return false
        }
        const isGlobalCondition = (filter.isGlobalCondition || 'false').toString()

        return (isGlobalCondition === 'both' || isGlobalCondition === props.groupIsGlobal.toString())
      }), (el) => el['label']),
    (el) => el['name'])
})

function addNoneCondition() {
  addCondition({
    name: FiltersData[filterName.value].name,
    type: props.withAggregation ? (FiltersData[filterName.value].aggregationFormat ?? FiltersData[filterName.value].format) : FiltersData[filterName.value].format,
    value: '',
  })
}
function addCondition({ name, type, value }) {
  value = value.trim()
  if (['diagnosis', 'act', 'ghm', 'drug'].indexOf(name) > -1) {
    // Diagnoses are always in uppercase: K670, C059 etc...
    value = value.toUpperCase()
  }
  emit(
    'add-condition',
    {
      type,
      value,
      aggregation: (props.withAggregation ? filterAggregation.value : null),
      aggregationModifiers: filterAggregationModifiers.value,
    }
  )
}

watch(filterName, (newName, oldName) => {
  if (newName !== oldName) {
    store.commit('medicalUnitFilter/clearCompletionItems')

    // when another filter is chosen, keep the aggregation only if it is allowed. Else, pick the first one available.
    if (props.withAggregation) {
      if (!Object.keys(allowedAggregateTypes.value).includes(filterAggregation.value)) {
        filterAggregation.value = Object.keys(allowedAggregateTypes.value).length === 1 ? Object.keys(allowedAggregateTypes.value)[0] : ''
      }
    }

    // if no filter add condition
    if (filterType.value === FilterDataType.NONE && allowedAggregateTypes.value.length <= 1) {
      addNoneCondition()
    }
  }
})
watch(filterAggregation, () => {
  filterAggregationModifiers.value = null
  if (filterAggregation.value === '') {
    return
  }
  if (aggregationFunctionList[filterAggregation.value].subFilters) {
    filterAggregationModifiers.value = {}
    aggregationFunctionList[filterAggregation.value].subFilters.forEach((filter) => {
      if (filter.default) {
        filterAggregationModifiers.value[filter.name] = filter.default
        return
      }
      filterAggregationModifiers.value[filter.name] = filter.options ? filter.options[0] : ''
    })
  }
  if (filterType.value === FilterDataType.NONE) {
    addCondition({
      name: FiltersData[filterName.value].name,
      type: props.withAggregation ? (FiltersData[filterName.value].aggregationFormat ?? FiltersData[filterName.value].format) : FiltersData[filterName.value].format,
      value: '',
    })
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterName).value = $event)),
        name: "filterBuilder_filterName",
        class: "col-auto custom-select"
      }, [
        _cache[2] || (_cache[2] = _createElementVNode("option", {
          value: "",
          disabled: ""
        }, " --- ", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterList.value, (filter, type) => {
          return (_openBlock(), _createElementBlock("option", {
            key: 'filterType_'+type,
            value: type
          }, _toDisplayString(filter.label), 9, _hoisted_2))
        }), 128))
      ], 512), [
        [_vModelSelect, filterName.value]
      ])
    ]),
    (props.withAggregation && !!filterName.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createTextVNode(" Sélectionner une fonction d'agrégat : ")),
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filterAggregation).value = $event)),
              name: "filterBuilder_aggrFunc",
              class: "col-auto custom-select"
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("option", {
                value: "",
                disabled: ""
              }, " --- ", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allowedAggregateTypes.value, (filter, type) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: 'aggFunc_'+type,
                  value: type
                }, _toDisplayString(filter.label), 9, _hoisted_5))
              }), 128))
            ], 512), [
              [_vModelSelect, filterAggregation.value]
            ]),
            (filterType.value === _unref(FilterDataType).NONE)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("button", {
                    class: "btn btn-primary",
                    type: "button",
                    onClick: addNoneCondition
                  }, " Ok ")
                ]))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterAggregationSubFilters.value, (filter) => {
            return (_openBlock(), _createElementBlock("div", {
              key: filter.name
            }, [
              _createTextVNode(_toDisplayString(filter.label) + ": ", 1),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": ($event: any) => ((filterAggregationModifiers.value[filter.name]) = $event),
                name: 'filterBuilder_modifier_'+filter.name,
                class: "custom-select"
              }, [
                _cache[4] || (_cache[4] = _createElementVNode("option", {
                  value: "",
                  disabled: ""
                }, " --- ", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.options, (opt) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: opt,
                    value: opt
                  }, _toDisplayString(filter.labels[opt] ? filter.labels[opt] : opt), 9, _hoisted_8))
                }), 128))
              ], 8, _hoisted_7), [
                [_vModelSelect, filterAggregationModifiers.value[filter.name]]
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.withAggregation || filterAggregation.value !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (filterType.value === _unref(FilterDataType).SELECT)
            ? (_openBlock(), _createBlock(SelectFilter, {
                key: 0,
                "filter-data": _unref(FiltersData)[filterName.value],
                onAddFilter: addCondition
              }, null, 8, ["filter-data"]))
            : _createCommentVNode("", true),
          (filterType.value === _unref(FilterDataType).COMPLETION)
            ? (_openBlock(), _createBlock(CompletionFilter, {
                key: 1,
                "filter-data": _unref(FiltersData)[filterName.value],
                onAddFilter: addCondition
              }, null, 8, ["filter-data"]))
            : _createCommentVNode("", true),
          (filterType.value === _unref(FilterDataType).RANGE)
            ? (_openBlock(), _createBlock(RangeFilter, {
                key: 2,
                class: "mt-5",
                "filter-data": _unref(FiltersData)[filterName.value],
                onAddFilter: addCondition
              }, null, 8, ["filter-data"]))
            : (filterType.value === _unref(FilterDataType).CONSTANT)
              ? (_openBlock(), _createBlock(ConstantFilter, {
                  key: filterName.value,
                  "filter-data": _unref(FiltersData)[filterName.value],
                  "is-fast-condition": _ctx.isFastCondition,
                  onAddFilter: addCondition
                }, null, 8, ["filter-data", "is-fast-condition"]))
              : _createCommentVNode("", true),
          (filterType.value === _unref(FilterDataType).MEDICAL_UNIT)
            ? (_openBlock(), _createBlock(_unref(MedicalUnitFilter), {
                key: 4,
                "filter-data-name": _unref(FiltersData)[filterName.value].name,
                "medical-unit-only": _ctx.withAggregation,
                "has-presence-sub-filter": !_ctx.withAggregation,
                onAddFilter: addCondition
              }, null, 8, ["filter-data-name", "medical-unit-only", "has-presence-sub-filter"]))
            : _createCommentVNode("", true),
          (filterType.value === _unref(FilterDataType).TEXT_CONTENT)
            ? (_openBlock(), _createBlock(AdvancedTextContentCondition, {
                key: filterName.value,
                "filter-data": _unref(FiltersData)[filterName.value],
                "is-global-condition": _ctx.groupIsGlobal,
                onAddFilter: addCondition
              }, null, 8, ["filter-data", "is-global-condition"]))
            : (filterType.value === _unref(FilterDataType).TEXT)
              ? (_openBlock(), _createBlock(TextCondition, {
                  key: 6,
                  "filter-data": _unref(FiltersData)[filterName.value],
                  "is-advanced-search": true,
                  onAddFilter: addCondition
                }, null, 8, ["filter-data"]))
              : (filterType.value === _unref(FilterDataType).BIOLOGY)
                ? (_openBlock(), _createBlock(BiologyFilter, {
                    key: 7,
                    "filter-data": _unref(FiltersData)[filterName.value],
                    onAddFilter: addCondition
                  }, null, 8, ["filter-data"]))
                : (filterType.value === _unref(FilterDataType).DATE)
                  ? (_openBlock(), _createBlock(DateFilter, {
                      key: 8,
                      "filter-data": _unref(FiltersData)[filterName.value],
                      "is-fast-condition": _ctx.isFastCondition,
                      onAddFilter: addCondition
                    }, null, 8, ["filter-data", "is-fast-condition"]))
                  : (filterType.value === _unref(FilterDataType).EXPOSITION)
                    ? (_openBlock(), _createBlock(ExpositionFilter, {
                        key: 9,
                        "filter-data": _unref(FiltersData)[filterName.value],
                        "is-fast-condition": _ctx.isFastCondition,
                        onAddFilter: addCondition
                      }, null, 8, ["filter-data", "is-fast-condition"]))
                    : (filterType.value === _unref(FilterDataType).VARIABLE)
                      ? (_openBlock(), _createBlock(VariableFilter, {
                          key: 10,
                          "filter-data": _unref(FiltersData)[filterName.value],
                          "is-fast-condition": _ctx.isFastCondition,
                          onAddFilter: addCondition
                        }, null, 8, ["filter-data", "is-fast-condition"]))
                      : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})