import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "large-container" }
const _hoisted_2 = { class: "home-list mb-2" }

import { computed, onMounted } from 'vue'

import { variablePermissions } from '@/misc/permissions/permissionsHelper'
import { useStore } from '@/store'
import { SearchTypeEnum } from '@/store/modules/search/types'

import SearchList from './SearchList.vue'
import SearchListManager from './SearchListManager.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'VariableListManager',
  setup(__props) {

const store = useStore()

const variables = computed(() => store.state.variables.variables)
const currentUser = computed(() => store.state.settings.currentUser)
const variableButtons = computed(() => {
  const buttons = []
  if (variablePermissions.canCreate(currentUser.value)) {
    buttons.push({
      label: 'Créer une variable d\'intérêt',
      addPath: 'variable/new',
      bgClass: '',
    })
  }
  return buttons
})

onMounted(() => {
  store.dispatch('fetchVariables', {})
  store.commit(
    'addBreadcrumbEntry',
    {
      name:'Bibliothèque des variables d\'intérêt',
      customKey: '/variable-manager/',
      routerLinkArg: location.pathname + location.search
    },
  )
})

function copyVariable(variableId: number) {
  store.dispatch('copyVariable', variableId)
}

function removeVariable(variableId: number) {
  store.dispatch('removeVariable', variableId)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(SearchListManager, {
        title: 'variables d\'intérêt',
        buttons: variableButtons.value,
        "button-key": 'variables'
      }, null, 8, ["buttons"]),
      _createVNode(SearchList, {
        "search-type": _unref(SearchTypeEnum).VARIABLE,
        title: 'variables d\'intérêt',
        "edit-path": 'variable',
        "bg-class": '',
        searches: variables.value,
        onCopySearch: copyVariable,
        onRemoveSearch: removeVariable
      }, null, 8, ["search-type", "searches"])
    ])
  ]))
}
}

})