import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "m-1" }
const _hoisted_2 = {
  key: 0,
  class: "text-uppercase m-2"
}
const _hoisted_3 = {
  key: 1,
  class: "m-2"
}

import Chart from 'chart.js/auto'
import _ from 'lodash'
import { onUnmounted, ref, watch } from 'vue'

import { StudyStats, StudyStep } from '@/store/modules/study/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyStatistics',
  props: {
    studyStats: {},
    selectedStep: {}
  },
  setup(__props: any) {

const props = __props

let genreChart = null
let patientAgeChart = null
let medicalUnitsChart = null

const genreCanvas = ref(null)
const patientAgeCanvas = ref(null)
const medicalUnitsCanvas = ref(null)

function getContextualStat() {
  if(_.isEmpty(props.studyStats)) {
    return false
  }
  const globalStats = props.studyStats
  if (props.selectedStep) {
    return globalStats[props.selectedStep.id]
  }

  return globalStats[_.findLastKey(globalStats)]
}

function getDisplayableStat() {
  if(_.isEmpty(props.studyStats)) {
    return false
  }

  const stats = getContextualStat()

  return stats && stats.patientCount > 0
}

function getCanvasHeight(statType: string) {
  const minHeight = 150
  if (!_.isEmpty(props.studyStats)) {
    const globalStats = getContextualStat().stats
    return globalStats[statType].length * 30 >= minHeight ? globalStats[statType].length * 30 : minHeight
  }

  return minHeight
}

function getPatientAgeChartLabel() {
  const globalStats = getContextualStat().stats
  const ageStats = globalStats.patientAge

  const labels = []
  _.forEach (ageStats, (stat) => {
    const ageLimits = stat.patientAge.split(' - ')
    switch (stat.patientAge) {
      case '<= 10':
        labels.push('Jusqu\'à 10 ans')
        break
      case '>= 90':
        labels.push('Plus de 90 ans')
        break
      default:
        labels.push(`De ${ageLimits[0]} à ${ageLimits[1]} ans`)
        break
    }
  })

  return labels
}

function getGenderChartLabel() {
  const globalStats = getContextualStat().stats
  const genderStats = globalStats.gender

  const labels = []
  _.forEach (genderStats, (stat) => {
    switch (stat.gender) {
      case '2':
        labels.push('Femmes')
        break
      case '1':
      default:
        labels.push('Hommes')
        break
    }
  })

  return labels
}

function getOptions(titleText: string): object {
  return {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    hover: { mode: 'nearest', intersect: true },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: titleText
      }
    },
  }
}


function getGenreDataSets() {
  const colorSet = ['#E331A9', '#0E3C6A']
  const dataSets = []

  const genreStats = getContextualStat().stats.gender

  const data = []
  _.forEach(genreStats, (stat) => {
    data.push(stat.patientCount === -1 ? 0 : stat.patientCount)
  })

  dataSets.push({
    data: data,
    fill: true,
    borderColor: colorSet,
    backgroundColor: colorSet,
    borderWidth: 2,
    borderSkipped: false,
  })

  return dataSets
}

function getAgeDataSets() {
  const colorSet = ['#ED9821', '#AC31E3', '#E331A9', '#0E3C6A', '#318BE3', '#31E1E3', '#FD6565']
  const dataSets = []

  const patientStats = getContextualStat().stats.patientAge
  const data = []
  _.forEach(patientStats, (stat) => {
    data.push(stat.patientCount === -1 ? 0 : stat.patientCount)
  })

  dataSets.push({
    data: data,
    fill: true,
    borderColor: colorSet,
    backgroundColor: colorSet,
    borderWidth: 2,
    borderSkipped: false,
  })

  return dataSets
}

function getMedicalUnitsDataSets() {
  const colorSet = ['#E331A9', '#0E3C6A', '#318BE3', '#31E1E3', '#FD6565', '#ED9821', '#AC31E3']
  const dataSets = []

  const medicalUnitsStats = getContextualStat().stats.medicalUnits
  const data = []
  _.forEach(medicalUnitsStats, (stat) => {
    data.push(stat.patientCount === -1 ? 0 : stat.patientCount)
  })

  dataSets.push({
    data: data,
    fill: true,
    borderColor: colorSet,
    backgroundColor: colorSet,
    borderWidth: 2,
    borderSkipped: false,
  })

  return dataSets
}

function refreshGenreChart() {
  if (genreChart !== null) {
    genreChart.destroy()
  }

  const canvas = genreCanvas.value.getContext('2d')

  genreChart = new Chart(canvas,
    {
      type: 'pie',
      data: {
        labels: getGenderChartLabel(),
        datasets: getGenreDataSets()
      },
      options: getOptions('Répartition des patients par genre')
    }
  )
}

function refreshPatientAgeChart() {
  if (patientAgeChart !== null) {
    patientAgeChart.destroy()
  }

  const canvas = patientAgeCanvas.value.getContext('2d')

  patientAgeChart = new Chart(canvas,
    {
      type: 'bar',
      data: {
        labels: getPatientAgeChartLabel(),
        datasets: getAgeDataSets()
      },
      options: getOptions('Répartition des patients par tranches d\'âge')
    }
  )
}

function refreshMedicalUnitsChart() {
  if (medicalUnitsChart !== null) {
    medicalUnitsChart.destroy()
  }

  const muStats = getContextualStat().stats.medicalUnits
  const canvas = medicalUnitsCanvas.value.getContext('2d')

  medicalUnitsChart = new Chart(canvas,
    {
      type: 'bar',
      data: {
        labels: _.map(muStats, 'medicalUnit'),
        datasets: getMedicalUnitsDataSets()
      },
      options: getOptions('Répartition des patients par unités médicales')
    }
  )
}

function refreshCharts() {
  if (_.isEmpty(props.studyStats)) {
    return
  }
  refreshGenreChart()
  refreshPatientAgeChart()
  refreshMedicalUnitsChart()
}

onUnmounted(() => {
  if (genreChart !== null) {
    genreChart.destroy()
  }
  if (patientAgeChart !== null) {
    patientAgeChart.destroy()
  }
  if (medicalUnitsChart !== null) {
    medicalUnitsChart.destroy()
  }
})

watch(() => props.studyStats, () => {
  refreshCharts()
})
watch(() => props.selectedStep, () => {
  refreshCharts()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (getDisplayableStat())
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.selectedStep ? 'Statistiques de l\'étape '+ _ctx.selectedStep.name : 'Statistiques de l\'étude'), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, " Aucune statistique à afficher ")),
    _createElementVNode("div", {
      class: "col stats-canvas",
      style: _normalizeStyle(getDisplayableStat() ? '' : 'display: none;')
    }, [
      _createElementVNode("canvas", {
        ref_key: "genreCanvas",
        ref: genreCanvas
      }, null, 512)
    ], 4),
    _createElementVNode("div", {
      class: "col stats-canvas",
      style: _normalizeStyle('height:'+ getCanvasHeight('patientAge') +'px;' + (getDisplayableStat() ? '' : 'display: none;'))
    }, [
      _createElementVNode("canvas", {
        ref_key: "patientAgeCanvas",
        ref: patientAgeCanvas
      }, null, 512)
    ], 4),
    _createElementVNode("div", {
      class: "col stats-canvas",
      style: _normalizeStyle('height:'+ getCanvasHeight('medicalUnits') +'px;' + (getDisplayableStat() ? '' : 'display: none;'))
    }, [
      _createElementVNode("canvas", {
        ref_key: "medicalUnitsCanvas",
        ref: medicalUnitsCanvas
      }, null, 512)
    ], 4)
  ]))
}
}

})