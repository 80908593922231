import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { name: "ConditionGroupDisplayer" }
const _hoisted_2 = {
  key: 0,
  class: "container"
}
const _hoisted_3 = { class: "col-5 px-2 condition-label" }
const _hoisted_4 = { class: "col row no-gutters px-0 py-1" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "container"
}
const _hoisted_8 = { class: "internal-operator col-auto ml-2" }
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = { class: "badge badge-primary text-wrap" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 0,
  class: "text-center"
}

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { computed } from 'vue'

import ConditionJustifications from '@/filters/ConditionJustifications.vue'
import { useStore } from '@/store'
import { ConditionGroup, operatorLabels } from '@/store/modules/search/types'
import { Condition } from '@/store/modules/search/types'
import { ConditionModifiers, MultipleCriterion } from '@/study/types'

import { getAggregationLabel, getCriteriaLabel, getModifiers, renderCriteria } from './FilterUtils'
import MultipleValueFilter from './MultipleValueFilter.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ConditionGroupDisplayer',
  props: {
    canRemoveCondition: { type: Boolean },
    conditionGroup: {},
    internalOperator: {},
    displayGroupedCondition: { type: Boolean },
    maxConditions: { default: 0 },
    showJustifications: { type: Boolean, default: false },
    softAbsentContents: { default: () => [] }
  },
  emits: ['remove-condition'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const emit = __emit
const props = __props

const healthConstantsList = computed(() => store.state.medicalUnitFilter.remoteLists['constants'])
const namedEntitiesList = computed(() => store.state.medicalUnitFilter.remoteLists['namedEntities'])
const currentStudy = computed(() => store.state.study.currentStudy)
const variablesList = computed(() => currentStudy.value.variables ?? [])
const stepNames = computed(() => {
  return store.getters.stepNames
})

const hasUnknownVariableWarning = computed(() => props.conditionGroup.conditionList.some((condition) => {
  if (condition.type !== 'variableResults') {
    return false
  }
  const arr = condition.value.split('_')
  const conditionVarId = Number(arr[0])
  return variablesList.value.findIndex((variable) => variable.id === conditionVarId) === -1
}))
const hasUnknownStepWarning = computed(() => props.conditionGroup.conditionList.some((condition) => {
  if (condition.type !== 'exposition') {
    return false
  }
  const arr = condition.value.split('_')
  const stepPosition = Number(arr[2])
  return stepNames.value.findIndex((step) => step.position === stepPosition) === -1
}))

const warningMessages = computed(() => {
  const messages = []
  if (hasUnknownVariableWarning.value) {
    messages.push('Attention, les conditions basées sur des variables inconnues sont ignorées')
  }
  if (hasUnknownStepWarning.value) {
    messages.push('Attention, les conditions basées sur des étapes inconnues sont ignorées')
  }

  return messages
})

const conditionList = computed((): { [key: string]: MultipleCriterion } => {
  const conditionList = {}
  _.forEach(props.conditionGroup.conditionList, (currentField) => {
    const conditionType = currentField.type
    const currentValue = currentField.value
    const aggregation = currentField.aggregation

    if (currentValue) {
      const label = getCriteriaLabel(conditionType)
      const content = renderCriteria(conditionType, currentValue, stepNames.value, healthConstantsList.value, namedEntitiesList.value, variablesList.value)
      if (conditionList[conditionType]) {
        conditionList[conditionType].currentValues.push(currentField)
      } else {
        conditionList[conditionType] = {
          label,
          content,
          currentValues: [currentField],
          aggregation,
        }
      }
    }
  })

  return conditionList
})

function removeCondition(condition: Condition) {
  emit('remove-condition', { condition, groupId: props.conditionGroup.id })
}

function selectDisplayedCondition(values: Condition[]): (ConditionModifiers)[] {
  if (props.maxConditions > 0) {
    values = values.slice(0, props.maxConditions)
  }

  return values.map((condition) => {
    return {
      condition,
      type: condition.type,
      label: getCriteriaLabel(condition.type),
      aggregation: getAggregationLabel((condition.aggregation ?? null)),
      content: renderCriteria(condition.type, condition.value, stepNames.value, healthConstantsList.value, namedEntitiesList.value, variablesList.value),
      modifiers: getModifiers(condition.value),
    }
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.displayGroupedCondition)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conditionList.value, (condition, conditionName) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `condition_list_${conditionName}`,
              class: "row no-gutters align-items-start"
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(getAggregationLabel)(condition.aggregation)) + _toDisplayString(condition.label), 1),
              _createElementVNode("div", _hoisted_4, [
                (condition.currentValues)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectDisplayedCondition(condition.currentValues), (value, idx) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `condition__${conditionName}__${idx}`,
                          class: "col-auto mx-1 mb-1 pl-2 pr-2 badge badge-primary text-wrap"
                        }, [
                          _createVNode(MultipleValueFilter, {
                            filter: value,
                            "can-edit": _ctx.canRemoveCondition,
                            "show-justifications": _ctx.showJustifications,
                            "soft-absent-contents": _ctx.softAbsentContents,
                            onRemoveCondition: removeCondition
                          }, null, 8, ["filter", "can-edit", "show-justifications", "soft-absent-contents"])
                        ]))
                      }), 128)),
                      (_ctx.maxConditions > 0 && condition.currentValues.length > _ctx.maxConditions)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_6, " ... "))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectDisplayedCondition(_ctx.conditionGroup.conditionList), (criterionModifier, conditionIdx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `condition_list_${conditionIdx}`,
              class: "text-center row"
            }, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(conditionIdx > 0 ? _unref(operatorLabels)[_ctx.internalOperator] : ''), 1),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, [
                  _createTextVNode(_toDisplayString(criterionModifier.aggregation) + " ", 1),
                  (['presentContent', 'absentContent', 'namedEntity'].indexOf(criterionModifier.type.split('__')[0]) < 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                        _createTextVNode(_toDisplayString(criterionModifier.label) + " : " + _toDisplayString(criterionModifier.content) + " ", 1),
                        (_ctx.canRemoveCondition)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              onClick: ($event: any) => (removeCondition(criterionModifier.condition))
                            }, [
                              _createVNode(_unref(SancareOcticon), {
                                name: "x",
                                width: 14,
                                height: 11
                              })
                            ], 8, _hoisted_12))
                          : _createCommentVNode("", true),
                        (_ctx.showJustifications && 'presentDrug' === criterionModifier.type)
                          ? (_openBlock(), _createBlock(ConditionJustifications, {
                              key: 1,
                              condition: criterionModifier.condition
                            }, null, 8, ["condition"]))
                          : _createCommentVNode("", true)
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_13, [
                        _createTextVNode(_toDisplayString(criterionModifier.label) + " : ", 1),
                        _createVNode(MultipleValueFilter, {
                          filter: criterionModifier,
                          "can-edit": _ctx.canRemoveCondition,
                          "show-justifications": _ctx.showJustifications,
                          "soft-absent-contents": _ctx.softAbsentContents,
                          onRemoveCondition: removeCondition
                        }, null, 8, ["filter", "can-edit", "show-justifications", "soft-absent-contents"])
                      ]))
                ])
              ])
            ]))
          }), 128)),
          (_ctx.maxConditions > 0 && _ctx.conditionGroup.conditionList.length > _ctx.maxConditions)
            ? (_openBlock(), _createElementBlock("p", _hoisted_14, " ... "))
            : _createCommentVNode("", true)
        ])),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(warningMessages.value, (warningMessage) => {
      return (_openBlock(), _createElementBlock("div", {
        key: warningMessage,
        class: "alert alert-warning"
      }, _toDisplayString(warningMessage), 1))
    }), 128))
  ]))
}
}

})