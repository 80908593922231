import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "home-list-part" }
const _hoisted_2 = { class: "row no-gutters" }
const _hoisted_3 = { class: "col home-list-part-title" }
const _hoisted_4 = { class: "d-flex flex-wrap home-list-part" }

import useRedirection from '@/misc/composables/redirection'

import HomeThumbnail from './HomeThumbnail.vue'
import { GenericThumbnailButton } from './types'


export default /*@__PURE__*/_defineComponent({
  __name: 'GenericThumbnailList',
  props: {
    title: {},
    buttons: {}
  },
  setup(__props: any) {



const { redirect } = useRedirection()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
        return (_openBlock(), _createElementBlock("div", {
          key: button.name,
          class: "col-3 p-2 fixed-thumbnail"
        }, [
          _createVNode(HomeThumbnail, {
            count: button.count,
            label: button.label,
            onClick: () => _unref(redirect)(button.linkPath, button.linkQuery)
          }, null, 8, ["count", "label", "onClick"])
        ]))
      }), 128))
    ])
  ]))
}
}

})