import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, onUnmounted, ref, watch } from 'vue'

import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'DiagramPaper',
  props: {
    isReadonly: { type: Boolean }
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const elementRef = ref(null)

onMounted(() => {
  store.commit('setPaperElement', elementRef.value)
  store.commit('setDiagramReadonly', props.isReadonly)
})
onUnmounted(() => {
  store.commit('setPaperElement', null)
})

watch(() => props.isReadonly, (newVal) => {
  store.commit('setDiagramReadonly', newVal)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "elementRef",
    ref: elementRef,
    class: "diagram"
  }, null, 512))
}
}

})