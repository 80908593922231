import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row align-items-center gap-2 no-gutters" }
const _hoisted_2 = {
  key: 0,
  class: "input-group col-12"
}
const _hoisted_3 = ["onUpdate:modelValue"]
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "input-group align-items-center justify-content-center col-12" }

import { DatePicker } from '@sancare/ui-frontend-commons'
import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import { Dayjs } from 'dayjs'
import _ from 'lodash'
import { computed, reactive } from 'vue'

import { FilterData } from '@/filters/types'
import { generateType, initializeSubFiltersValues } from '@/filters/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'DateFilter',
  props: {
    filterData: {},
    isFastCondition: { type: Boolean, default: false }
  },
  emits: ['add-filter'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const subFiltersValues = reactive(initializeSubFiltersValues(props.filterData))
const date = reactive({ value: props.filterData.subFilters ? dayjs() : (props.filterData.default ? props.filterData.default : dayjs()) })
const currentValue = computed(() => {
  return dayjs.isDayjs(date.value) ? date.value : dayjs()
})

function setDate(newDate: Dayjs) {
  date.value = newDate
}
function addFilter() {
  const name = props.filterData.name

  if (_.isEmpty(date.value)) {
    date.value = dayjs().format('YYYY-MM-DD')
    return
  }
  const value = date.value.format('YYYY-MM-DD')
  const type = generateType(props.filterData.format, subFiltersValues)
  emit('add-filter', { name, type, value })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.filterData && _ctx.filterData.subFilters && _ctx.filterData.subFilters.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterData.subFilters, (filter) => {
            return _withDirectives((_openBlock(), _createElementBlock("select", {
              key: filter.name,
              "onUpdate:modelValue": ($event: any) => ((subFiltersValues[filter.name]) = $event),
              class: "custom-select form-control"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.options, (opt) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: opt,
                  value: opt
                }, _toDisplayString(filter.labels[opt] ? filter.labels[opt] : opt), 9, _hoisted_4))
              }), 128))
            ], 8, _hoisted_3)), [
              [_vModelSelect, subFiltersValues[filter.name]]
            ])
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(DatePicker), {
        "model-value": currentValue.value,
        "onUpdate:modelValue": setDate
      }, null, 8, ["model-value"]),
      _createElementVNode("div", { class: "offset-1" }, [
        _createElementVNode("button", {
          class: "btn btn-primary",
          type: "button",
          onClick: addFilter
        }, " Ok ")
      ])
    ])
  ]))
}
}

})