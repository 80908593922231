import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-4"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "row" }
const _hoisted_6 = {
  key: 0,
  class: "col-12 pl-5 py-2"
}
const _hoisted_7 = { class: "col-12 report-content py-2 mx-0" }
const _hoisted_8 = {
  key: 1,
  class: "p-4"
}

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { getDrugEventPrestationTypeLabel } from '@sancare/ui-frontend-commons/src/misc/DrugEventHelper'
import { DrugEventPrestationType } from '@sancare/ui-frontend-commons/src/types/health-data'
import { HealthDataType } from '@sancare/ui-frontend-commons/src/types/health-data'
import _ from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'

import { getChunkClass, getJustifications, Justification } from '@/misc/JustificationHelper'
import { getStayLabel } from '@/patient/StayLabel'
import { useStore } from '@/store'
import { Condition } from '@/store/modules/search/types'

interface MatchingDocument {
  id: number
  type: string
  drugEventPrestationType?: DrugEventPrestationType | null
  title: string
  justifications: Justification[]
}
interface MatchingStay {
  id: number
  stayStart: string
  stayEnd: string
  stayDuration: number
  matchingDocuments: MatchingDocument[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ConditionJustifications',
  props: {
    condition: {},
    softAbsentContents: { default: () => [] }
  },
  setup(__props: any) {

const props = __props
const store = useStore()

const selectedStay = ref(null)
const selectedDocId = ref(null)
const openJustifPopup = ref(false)

const matchingStays = ref([] as MatchingStay[])
const textWorkerReady = computed(() => store.state.patient.textWorkerReady)
const patient = computed(() => store.state.patient.currentPatient)
const studyNodeMatches = computed(() => store.state.patient.studyNodeMatches)
const variableMatches = computed(() => store.state.patient.variableMatches)
const allMatches = computed(() => ({
  keywords: studyNodeMatches.value.keywords.concat(variableMatches.value.keywords),
  drugs: studyNodeMatches.value.drugs.concat(variableMatches.value.drugs),
}))

function reset() {
  matchingStays.value = []
  selectedStay.value = null
  selectedDocId.value = null
  openJustifPopup.value = false
}

function refresh() {
  reset()
  if (props.condition.type.startsWith('presentContent')) {
    const matchingReportIds = []
    const textualHealthEntryIds = []
    const categoricalLabResultIds = []
    for (const studyNodeMatch of allMatches.value.keywords) {
      if (studyNodeMatch.condition.value === props.condition.value && _.isEqual(_.sortBy(studyNodeMatch.condition.softAbsentContents), _.sortBy(props.softAbsentContents))) {
        for (const stay of Object.values(studyNodeMatch.stays)) {
          const matchingStay = {
            id: stay.id,
            stayStart:stay.stayStart,
            stayEnd: stay.stayEnd,
            stayDuration: stay.stayDuration,
            matchingDocuments: [],
          }
          matchingStay.matchingDocuments = _.concat(
            Object.values(stay.reports).filter((report) => !matchingReportIds.includes(report.id) && report.limits.length).map((report) => {
              matchingReportIds.push(report.id)
              return {
                id: report.id,
                type: HealthDataType.REPORT,
                title: report.title,
                justifications: getJustifications(report.text, report.limits)
              }
            }),
            Object.values(stay.textualHealthEntries).filter((textualHealthEntry) => !textualHealthEntryIds.includes(textualHealthEntry.id) && textualHealthEntry.limits.length).map((textualHealthEntry) => {
              textualHealthEntryIds.push(textualHealthEntry.id)
              return {
                id: textualHealthEntry.id,
                type: HealthDataType.TEXTUAL_HEALTH_ENTRY,
                title: 'Rapports infirmiers',
                justifications: getJustifications(textualHealthEntry.text, textualHealthEntry.limits)
              }
            }),
            Object.values(stay.categoricalLabResults).filter((catLabResult) => !categoricalLabResultIds.includes(catLabResult.id) && catLabResult.limits.length).map((catLabResult) => {
              categoricalLabResultIds.push(catLabResult.id)
              return {
                id: catLabResult.id,
                type: HealthDataType.CAT_LAB_RESULT,
                title: 'Résultats de Biologie',
                justifications: getJustifications(catLabResult.text, catLabResult.limits)
              }
            }),
          )
          if (matchingStay.matchingDocuments.length) {
            matchingStays.value.push(matchingStay)
          }
        }
      }
    }
  }

  if (props.condition.type === 'presentDrug') {
    const matchingDrugEventIds = []
    for (const studyNodeMatch of allMatches.value.drugs) {
      if (studyNodeMatch.condition.value === props.condition.value) {
        const justifications = [{ index: 0, chunks: [{ text: studyNodeMatch.condition.value, isHighlighted: true }] }]
        for (const stay of Object.values(studyNodeMatch.stays)) {
          if (!Object.values(stay.drugEvents).length) {
            continue
          }
          const matchingStay = {
            id: stay.id,
            stayStart:stay.stayStart,
            stayEnd: stay.stayEnd,
            stayDuration: stay.stayDuration,
            matchingDocuments: _.uniqBy(Object.values(stay.drugEvents).filter((drugEvent) => !matchingDrugEventIds.includes(drugEvent.id)).map((drugEvent) => {
              matchingDrugEventIds.push(drugEvent.id)
              return {
                id: drugEvent.id,
                type: HealthDataType.DRUG_EVENT,
                drugEventPrestationType: drugEvent.prestationType,
                title: getDrugEventPrestationTypeLabel(drugEvent.prestationType),
                justifications,
              }
            }), 'title')
          }
          if (matchingStay.matchingDocuments.length) {
            matchingStays.value.push(matchingStay)
          }
        }
      }
    }
  }

  if (matchingStays.value.length) {
    matchingStays.value = matchingStays.value.sort((a, b) => a.stayEnd > b.stayEnd ? 1 : (a.stayEnd < b.stayEnd ? -1 : 0))
    selectedStay.value = matchingStays.value[0]
  }
}

onMounted(() => {
  if (textWorkerReady.value) {
    refresh()
  }
})

watch(() => textWorkerReady.value, (newValue) => {
  if (!newValue) {
    return
  }
  refresh()
})

function selectDocument(document: MatchingDocument) {
  selectedDocId.value = document.id
  const currentStay = patient.value.stays.find((s) => s.id === selectedStay.value.id)
  let currentDocument = null
  if (HealthDataType.REPORT) {
    currentDocument = currentStay.reports.find((r) => r.id === document.id)
  }
  store.commit('patient/setCurrentStay', currentStay)
  store.commit('patient/setCurrentDocument', {
    document: currentDocument,
    documentType: document.type,
    drugEventPrestationType: document.drugEventPrestationType ?? null,
  })
}

return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.condition.type.split('__')[0] === 'presentContent' || 'presentDrug' === _ctx.condition.type)
      ? (_openBlock(), _createBlock(_component_v_dropdown, {
          key: 0,
          open: openJustifPopup.value,
          "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((openJustifPopup).value = $event)),
          trigger: "hover",
          placement: "top",
          container: "#side-menu",
          "auto-boundary-max-size": ""
        }, {
          popper: _withCtx(() => [
            (matchingStays.value.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Séjours", -1)),
                  _createElementVNode("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(matchingStays.value, (stay) => {
                      return (_openBlock(), _createElementBlock("button", {
                        key: stay.id,
                        class: _normalizeClass(["mx-2 mb-2 btn btn-sm btn-primary btn-padded", {active: selectedStay.value && selectedStay.value.id === stay.id}]),
                        onClick: ($event: any) => (selectedStay.value = stay)
                      }, _toDisplayString(_unref(getStayLabel)(stay)), 11, _hoisted_3))
                    }), 128))
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedStay.value.matchingDocuments, (document) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: document.id,
                      onClick: ($event: any) => (selectDocument(document))
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["btn btn-sm btn-report-select", {active: selectedDocId.value === document.id}])
                        }, _toDisplayString(document.title || '[sans titre]'), 3)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(document.justifications, (justification) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: justification.index,
                          class: "row"
                        }, [
                          (document.justifications.length > 1 && justification.index !== document.justifications[0].index)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, "[...]"))
                            : _createCommentVNode("", true),
                          _createElementVNode("p", _hoisted_7, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(justification.chunks, (chunk, idx) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: idx,
                                class: _normalizeClass(["text-data-content", _unref(getChunkClass)(chunk)])
                              }, _toDisplayString(chunk.text), 3))
                            }), 128))
                          ])
                        ]))
                      }), 128))
                    ], 8, _hoisted_4))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, " Pas de justifications "))
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: "cp",
              onClick: _cache[0] || (_cache[0] = ($event: any) => {selectedDocId.value = null; openJustifPopup.value = true})
            }, [
              (textWorkerReady.value)
                ? (_openBlock(), _createBlock(_unref(SancareOcticon), {
                    key: 0,
                    name: "search",
                    width: 14,
                    height: 11
                  }))
                : _createCommentVNode("", true)
            ]),
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["open"]))
      : _createCommentVNode("", true)
  ]))
}
}

})