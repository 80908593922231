import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row m-1 p-2" }
const _hoisted_3 = { class: "row col-3" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = ["value", "disabled"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "row m-1" }
const _hoisted_8 = {
  key: 0,
  class: "text-center mr-2"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "row m-1 mt-4" }
const _hoisted_11 = ["value", "disabled"]
const _hoisted_12 = { class: "row m-1" }
const _hoisted_13 = { class: "col-7" }
const _hoisted_14 = {
  key: 0,
  class: "row"
}
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "col-auto" }

import { computed, nextTick, onMounted, ref } from 'vue'

import CriterionLibrary from '@/criteria/CriterionLibrary.vue'
import ConditionGroupDisplayer from '@/filters/ConditionGroupDisplayer.vue'
import FastConditionCreator from '@/search/FastConditionCreator.vue'
import { User } from '@/settings/types'
import { useStore } from '@/store'
import { Condition, Criterion, OperatorEnum, operatorLabels, SearchTypeEnum } from '@/store/modules/search/types'
import { StudyNode, StudyStep } from '@/store/modules/study/types'

interface RemoveConditionPayload {
  condition: Condition
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsStudyNode',
  props: {
    studyNode: {},
    studyNodeStep: {},
    readonly: { type: Boolean },
    currentUser: {}
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const showRemoveConfirm = ref(false)
const showAddCondition = ref(false)

const currentStudy = computed(() => store.state.study.currentStudy)

onMounted(() => {
  store.commit('setCurrentStudyStep', props.studyNodeStep.id)
})

async function addFastCondition(condition: Condition, name: string) {
  if (name === '') {
    return
  }
  await store.commit(
    'addStudyNodeFromCondition',
    { parent: props.studyNode, name, condition }
  )
  showAddCondition.value = false
  store.dispatch('updateStudy')
}
async function addStudyNode() {
  // wait for rendering so coordinates are computed before dispatch
  await store.commit('addStudyNode', { parent: props.studyNode })
  store.dispatch('addStudyNode', {
    parentId: props.studyNode.id,
    studyNode: props.studyNode.children[props.studyNode.children.length - 1],
  })
}
async function updateStudyNode(propName, value) {
  await store.commit('updateStudyNodeProperty', {
    studyNodeId: props.studyNode.id,
    prop: propName,
    value: value,
  })
  if (propName === 'name') {
    store.dispatch('updateStudyNodeAttributes', props.studyNode)
  } else {
    store.dispatch('updateStudy')
    store.commit('refreshSelected')
    nextTick(function() {
      store.commit('refreshStudyNode', props.studyNode.id)
    })
  }
}
function removeCondition({ condition }: RemoveConditionPayload) {
  store.commit('removeStudyCondition', { studyNodeId: props.studyNode.id, condition })
  store.dispatch('updateStudy')
  store.commit('refreshSelected')
}
function removeStudyNode() {
  store.commit('removeStudyNode', props.studyNode)
  showRemoveConfirm.value = false
  store.dispatch('updateStudy')
}
async function selectCriterion(criterion: Criterion) {
  // wait for rendering so coordinates are computed before dispatch
  await store.commit('addStudyCriterion', { parentId: props.studyNode.id, criterion })
  showAddCondition.value = false
  store.dispatch('updateStudy')
}
function resetCurrentNode() {
  store.commit('resetNewSearch')
  store.commit('resetNewStudyNode')
}
function displayFastConditionEditor() {
  showAddCondition.value = true
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.studyNode.name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col-3 py-2" }, " Nom ", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("input", {
                  value: _ctx.studyNode.name,
                  type: "text",
                  disabled: _ctx.readonly,
                  class: "form-control",
                  onChange: _cache[0] || (_cache[0] = event => updateStudyNode('name', event.srcElement.value))
                }, null, 40, _hoisted_5)
              ])
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", null, [
              _createVNode(_component_router_link, {
                to: _ctx.readonly ? '' : {name: 'studyNodeEdit', params: {studyNodeId: _ctx.studyNode.id}, query: {studyId: currentStudy.value.id, stepId: _ctx.studyNodeStep.id}},
                class: _normalizeClass([{ disabled: _ctx.readonly }, "btn btn-rounded"]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (resetCurrentNode()))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Éditer le critère ")
                ])),
                _: 1
              }, 8, ["to", "class"])
            ])), [
              [
                _directive_tooltip,
                _ctx.readonly ? 'Vous ne pouvez pas modifier un critère d\'une étape socle dans une étude validée' : '',
                void 0,
                { bottom: true }
              ]
            ]),
            (showRemoveConfirm.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn btn-link text-danger",
                  onClick: removeStudyNode
                }, " Confirmer "))
              : (!_ctx.readonly)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn btn-link text-danger",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (showRemoveConfirm.value = true), ["prevent"]))
                  }, " Supprimer le noeud "))
                : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-uppercase m-1 p-2" }, " Conditions ", -1)),
            (_ctx.studyNode.conditionList.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(ConditionGroupDisplayer, {
                    class: "row",
                    "can-remove-condition": !_ctx.studyNode.name && !_ctx.readonly,
                    "internal-operator": _unref(OperatorEnum)[_ctx.studyNode.operator],
                    "condition-group": _ctx.studyNode,
                    "display-grouped-condition": true,
                    onRemoveCondition: removeCondition
                  }, null, 8, ["can-remove-condition", "internal-operator", "condition-group"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.studyNode.children, (child, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: child.id,
                  class: "row mx-2 align-items-center"
                }, [
                  (idx > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_unref(operatorLabels)[_unref(OperatorEnum)[_ctx.studyNode.operator]]), 1))
                    : _createCommentVNode("", true),
                  _createVNode(ConditionGroupDisplayer, {
                    class: "card",
                    "can-remove-condition": false,
                    "internal-operator": _unref(OperatorEnum)[child.operator],
                    "condition-group": child,
                    "display-grouped-condition": false
                  }, null, 8, ["internal-operator", "condition-group"])
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "col-auto py-2" }, " Opérateur ", -1)),
            _createElementVNode("select", {
              value: _ctx.studyNode.operator,
              class: "interactive mr-3",
              disabled: _ctx.readonly,
              onChange: _cache[3] || (_cache[3] = event => updateStudyNode('operator', event.srcElement.value))
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("option", { value: "OR" }, " OU ", -1),
              _createElementVNode("option", { value: "AND" }, " ET ", -1)
            ]), 40, _hoisted_11),
            (!_ctx.readonly)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn btn-rounded",
                  onClick: addStudyNode
                }, " Ajouter un opérateur "))
              : _createCommentVNode("", true),
            (!_ctx.readonly)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "btn btn-rounded",
                  onClick: displayFastConditionEditor
                }, " Ajouter un critère d'étude "))
              : _createCommentVNode("", true),
            (showRemoveConfirm.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  class: "btn btn-link text-danger",
                  onClick: removeStudyNode
                }, " Confirmer "))
              : (!_ctx.readonly)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 3,
                    class: "btn btn-link text-danger",
                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (showRemoveConfirm.value = true), ["prevent"]))
                  }, " Supprimer le noeud "))
                : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "col" }, null, -1)),
            _createElementVNode("div", _hoisted_12, [
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "col-1" }, null, -1)),
              _createElementVNode("div", _hoisted_13, [
                (showAddCondition.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(FastConditionCreator, {
                          "initial-condition-name": "Créer un critère rapide",
                          "search-type": _unref(SearchTypeEnum).CRITERION,
                          onAddCondition: addFastCondition
                        }, null, 8, ["search-type"])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-uppercase" }, " Utiliser un critère existant ", -1)),
                        _createVNode(CriterionLibrary, { onSelectCriterion: selectCriterion }),
                        (!_ctx.studyNode.name)
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              to: {name: 'studyNodeNew', query: {studyId: currentStudy.value.id, parentId: _ctx.studyNode.id}},
                              onClick: resetCurrentNode
                            }, {
                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createTextVNode(" Créer un nouveau critère ")
                              ])),
                              _: 1
                            }, 8, ["to"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "col-4" }, null, -1))
            ])
          ])
        ]))
  ]))
}
}

})