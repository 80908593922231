import { setRequest } from '@sancare/ui-frontend-commons/src/misc/api'

import {
  AnnotatedDocument,
  AnnotatedEntity,
  AnnotatedHint,
  DocumentEntityExplorerState,
  DocumentEntityExplorerValueType,
  Hint,
  SourceType
} from './types'

interface HandleValuePayload {
  valueIndex?: number
  annotationValue: string
  valueType: DocumentEntityExplorerValueType
}

export interface ReplaceHintsPayload {
  annotationToEdit: string
  newHints: AnnotatedHint[]
}

export interface AddHintsPayload {
  annotationToEdit: string
  newHints: Hint[]
}

function getEntity(state: DocumentEntityExplorerState) {
  return state.currentDocument.entities.find(
    (entity) => entity.entityId === state.currentEntityId
  )
}

function getAnnotation(state: DocumentEntityExplorerState, annotationValue: string) {
  const entity = getEntity(state)

  const annotationIdx = entity.annotations.findIndex(
    (annotation) => annotation.value === annotationValue
  )

  return { annotation: entity.annotations[annotationIdx] ?? null, annotationIdx }
}

export const mutations = {
  setDocuments(state: DocumentEntityExplorerState, response: AnnotatedDocument[]) {
    state.documents = response
    state.currentDocument = state.documents.length > 0 ? state.documents[0] : null
    state.currentEntityId = state.currentDocument !== null ? state.currentDocument.entities[0].entityId : null
  },
  setDocument(state: DocumentEntityExplorerState, document: AnnotatedDocument) {
    state.currentDocument = document
    state.currentEntityId = document.entities[0].entityId
  },
  setCurrentEntity(state: DocumentEntityExplorerState, entityId: string) {
    state.currentEntityId = entityId !== state.currentEntityId ? entityId : null
  },
  updateDocumentEntity(state: DocumentEntityExplorerState, entity: AnnotatedEntity) {
    const storedEntityIdx = state.currentDocument.entities.findIndex(
      (e) => e.entityId === entity.entityId
    )
    state.currentDocument.entities[storedEntityIdx] = entity
  },
  addHints(state: DocumentEntityExplorerState, payload: AddHintsPayload) {
    const annotationToEdit = getAnnotation(state, payload.annotationToEdit).annotation
    annotationToEdit.hints = [...annotationToEdit.hints, ...payload.newHints]
  },
  replaceHints(state: DocumentEntityExplorerState, payload: ReplaceHintsPayload) {
    const annotation = getAnnotation(state, payload.annotationToEdit).annotation
    annotation.hints = payload.newHints
  },
  addAnnotation(state: DocumentEntityExplorerState, annotationValue: string) {
    const entity = getEntity(state)
    entity.annotations.push({
      value: annotationValue,
      hints: [],
      source: SourceType.USER,
      removed: false
    })
  },
  removeValue(state: DocumentEntityExplorerState, payload: HandleValuePayload) {
    const { valueIndex, annotationValue, valueType } = payload
    const { annotation, annotationIdx } = getAnnotation(state, annotationValue)
    switch (valueType) {
      case DocumentEntityExplorerValueType.ANNOTATION:
        if (annotation.source === SourceType.AI) {
          annotation.removed = true
        } else {
          const entity = getEntity(state)
          entity.annotations.splice(annotationIdx, 1)
        }
        break
      case DocumentEntityExplorerValueType.HINT: {
        const hint = annotation.hints[valueIndex]
        if (hint.source === SourceType.AI) {
          hint.removed = true
        } else {
          annotation.hints.splice(valueIndex, 1)
        }
        break
      }
      case DocumentEntityExplorerValueType.POST_PROCESSING:
        annotation.postProcessingValues.isRemoved = true
        break
    }
  },
  restoreValue(state: DocumentEntityExplorerState, payload: HandleValuePayload) {
    const { valueIndex, annotationValue, valueType } = payload
    const annotation = getAnnotation(state, annotationValue).annotation
    switch (valueType) {
      case DocumentEntityExplorerValueType.ANNOTATION:
        annotation.removed = false
        break
      case DocumentEntityExplorerValueType.HINT:
        annotation.hints[valueIndex].removed = false
        break
      case DocumentEntityExplorerValueType.POST_PROCESSING:
        annotation.postProcessingValues.isRemoved = false
        break
    }
  },
  setRequest,
}
