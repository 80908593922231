import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "large-container" }

import { computed, onMounted } from 'vue'

import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import { criterionListColumns } from '@/patient-list/PatientListColumns'
import router from '@/router'
import SearchEditionLayout from '@/search/SearchEditionLayout.vue'
import { useStore } from '@/store'
import { Criterion, SearchTypeEnum } from '@/store/modules/search/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'CriterionEditorPage',
  setup(__props) {

const store = useStore()

const criterionId = computed(() => router.currentRoute.value.params.criterionId && Number(router.currentRoute.value.params.criterionId))
const studyId = computed(() => router.currentRoute.value.query.studyId && Number(router.currentRoute.value.query.studyId))

const backgroundJobsEnabled = computed(() => store.state.settings.sancareSettings.boolSettings.enable_compute_study_jobs.value)
const currentUser = computed(() => store.state.settings.currentUser)
const addCriterionRequest = computed(() => store.state.criteria.addCriterionRequest)
const editCriterionRequest = computed(() => store.state.criteria.editCriterionRequest)
const fetchCriterionRequest = computed(() => store.state.criteria.fetchCriterionRequest)
const canShowPatientList = computed(() => hasPermission(RoleEnum.ROLE_STAY_READER, currentUser.value.role))
const currentSearch = computed(() => store.state.search.currentSearch)

onMounted(() => {
  store.commit(
    'addBreadcrumbEntry',
    {
      name: 'Édition de critère',
      customKey: '/criterion/',
      routerLinkArg: location.pathname + location.search
    },
  )
  store.commit('setPatientListColumns', criterionListColumns)

  if (!studyId.value) {
    store.commit('resetStudy')
  }
})

// always in library so we donot need to convert search in studyNode
async function upsertCriterion(criterion: Criterion) {
  if (criterionId.value) {
    store.dispatch('editCriterion', criterion)
    return
  }
  await store.dispatch('addCriterion', criterion)
  await store.dispatch('fetchCriteria', { force: true })
  router.push({ name: 'criterion', params: { criterionId: currentSearch.value.id } })
}

function refreshPatients() {
  if (backgroundJobsEnabled.value) {
    return
  }
  if (canShowPatientList.value) {
    store.dispatch('searchPatients')
  } else {
    store.dispatch('countPatientSearch')
  }
}

// Load selected criterion, then refresh patient list
async function fetchCriterion() {
  if (criterionId.value && !(currentSearch.value && currentSearch.value.id === criterionId.value)) {
    await store.dispatch('fetchCriterion', criterionId.value)
  }
  refreshPatients()
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SearchEditionLayout, {
      "can-show-patient-list": canShowPatientList.value,
      "add-request": addCriterionRequest.value,
      "edit-request": editCriterionRequest.value,
      "fetch-request": fetchCriterionRequest.value,
      "search-type": _unref(SearchTypeEnum).CRITERION,
      onUpsertSearch: upsertCriterion,
      onFetchSearch: fetchCriterion,
      onRefreshPatients: refreshPatients
    }, null, 8, ["can-show-patient-list", "add-request", "edit-request", "fetch-request", "search-type"])
  ]))
}
}

})