import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { ReplaceHintsPayload } from './mutations'
import { state } from './state'
import { AnnotatedEntity, AnnotatedHint, DocumentEntityExplorerValueType } from './types'
import { insertHint } from './utils'

interface RemoveHintPayload {
  currentHints: AnnotatedHint[]
  hintToRemove: AnnotatedHint
  annotationToEdit: string
}

interface AddHintPayload {
  currentHints: AnnotatedHint[]
  newHint: AnnotatedHint
  annotationToEdit: string
}

export const actions = {
  fetchDocuments({ commit }, payload: { offset: number, limit: number }) {
    return Api.request(state.requests.fetchDocuments, {
      urlParams: payload,
      successMut: 'setDocuments',
    }, commit)
  },
  upsertDocumentEntity({ commit }, payload: AnnotatedEntity & { documentId: string }) {
    return Api.request(state.requests.fetchDocuments, {
      body: payload,
      successMut: 'updateDocumentEntity',
    }, commit)
  },
  setDocument({ commit }) {
    const document = undefined
    commit('setDocument', document)
  },
  addHint({ commit }, { currentHints, newHint, annotationToEdit }: AddHintPayload) {
    const newHints = insertHint(currentHints, newHint)
    const replaceHintsPayload: ReplaceHintsPayload = { annotationToEdit: annotationToEdit, newHints: newHints }
    commit('replaceHints', replaceHintsPayload)
  },
  removeHint({ commit }, { currentHints, hintToRemove, annotationToEdit }: RemoveHintPayload) {
    const newHints = currentHints.filter((hint) => hint !== hintToRemove)
    commit('replaceHints', { annotationToEdit, newHints })
  },
  addAnnotation({ commit }, annotationValue: string) {
    commit('addAnnotation', annotationValue)
  },
  removeAnnotation({ commit }, annotationValue: string) {
    commit('removeValue', { annotationValue, valueType: DocumentEntityExplorerValueType.ANNOTATION })
  }
}
