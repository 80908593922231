import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "large-container d-flex flex-column gap-4" }
const _hoisted_2 = { class: "d-flex align-items-center gap-3" }
const _hoisted_3 = { class: "navigation" }
const _hoisted_4 = {
  key: 0,
  class: "loader loader-sm"
}
const _hoisted_5 = {
  key: 1,
  class: "document-title"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = {
  key: 0,
  class: "document-entity-explorer-body"
}

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { computed, onMounted, ref } from 'vue'

import DocumentEntityAnnotationsList from '@/document-entity-explorer/DocumentEntityAnnotationsList.vue'
import DocumentVisualizer from '@/document-entity-explorer/DocumentVisualizer.vue'
import { useStore } from '@/store'
import { AnnotatedChunk } from '@/store/modules/document-entity-explorer/types'
import { getValidHintsFromAnnotations } from '@/store/modules/document-entity-explorer/utils'
import { computeSingleSourceChunksFromMlHints } from '@/text-processing/splitReportContent.js'


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentEntityExplorerPage',
  setup(__props) {

const store = useStore()
const currentDocumentIndex = ref(0)
const nbDocuments = computed(() => store.state.documentEntityExplorer.nbDocuments)
const currentDocument = computed(() => store.state.documentEntityExplorer.currentDocument)
const currentEntityId = computed(() => store.state.documentEntityExplorer.currentEntityId)
const fetchDocumentRequest = computed(() => store.state.documentEntityExplorer.requests.fetchDocuments)
const currentEntity = computed(() => currentDocument.value.entities.find((entity) => entity.entityId === currentEntityId.value))
const currentHints = computed(() => currentEntity.value ? getValidHintsFromAnnotations(currentEntity.value.annotations) : [])
const chunks = computed(() =>
  computeSingleSourceChunksFromMlHints({ id: currentDocument.value.documentId, content: currentDocument.value.documentContent }, currentHints.value)
)
const highlightedChunks = computed(() =>
  chunks.value.filter((chunk: AnnotatedChunk) => chunk.isHighlighted)
)

function loadDocument() {
  store.dispatch('documentEntityExplorer/fetchDocuments', { offset: currentDocumentIndex.value, limit: 1 })
}

onMounted(() => {
  store.commit(
    'addBreadcrumbEntry',
    {
      name: 'Interface de dépouillement',
      customKey: '/document-entity-explorer/',
      routerLinkArg: location.pathname + location.search
    },
  )
  loadDocument()
})

function setCurrentDocumentIndex(idx: number) {
  currentDocumentIndex.value = Math.min(Math.max(0, idx), nbDocuments.value - 1)
  loadDocument()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(SancareOcticon), {
          class: "cp",
          name: "chevron-left",
          width: 10,
          height: 10,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (setCurrentDocumentIndex(currentDocumentIndex.value-1)))
        }),
        _createTextVNode(" " + _toDisplayString(currentDocumentIndex.value + 1) + "/? ", 1),
        _createVNode(_unref(SancareOcticon), {
          class: "cp",
          name: "chevron-right",
          width: 10,
          height: 10,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (setCurrentDocumentIndex(currentDocumentIndex.value+1)))
        })
      ]),
      (fetchDocumentRequest.value.fetching)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (currentDocument.value && currentDocument.value.documentContent)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(currentDocument.value.documentTitle), 1))
              : (null === currentDocument.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Document introuvable dans l'API LLM... "))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, " Contenu du document introuvable en base Web... "))
          ]))
    ]),
    (!fetchDocumentRequest.value.fetching && currentDocument.value && currentDocument.value.documentContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(DocumentVisualizer, {
            class: "document-visualizer",
            chunks: chunks.value
          }, null, 8, ["chunks"]),
          _createVNode(DocumentEntityAnnotationsList, {
            class: "document-annotations",
            entities: currentDocument.value.entities,
            chunks: highlightedChunks.value
          }, null, 8, ["entities", "chunks"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})