/* Filter Configuration file
 *
 * filters informations :
 *   for each filter you must have :
 *     a name   : specify the filter name, use as value in filter selector option
 *     a type   : specify the filter type, it's explain after.
 *     a label  : specify the filter label, use as innerHTML in filter selector option
 *     a format : specify how to build filter type with subfilters params
 *     a subFilters: Array that can contain ( or not ) more usable sub filters
 *   each filter can have additional parameters base on it's type
 *
 * filters type informations :
 *  [completion]
 *     decription: filter with suggestion list based on typed user value
 *     params:
 *       name: in lowercase will be send to searchControllers
 *  [confidence]
 *     filter for confidence, has it's own type cause it's note a regular case
 *  [free]
 *     description : free text input filter
 *  [range]
 *     description: range filter
 *     params:
 *       less: specify if value can be less than min
 *       min: minimum range value
 *       interval: specify interval between step in the slider
 *       max: maximum range value
 *       more: specify if value can me more than max
 *       unit: specify unity
 *  [remoteList]
 *    description: select based on database value
 *    params:
 *      listPath: name send to search controller to get the list
 *      listKey: field name of the object to use for value
 *  [select]
 *    description: select fix value
 *    params:
 *      options: array with each option value
 *      default: default select value
 *      labels: object that specify labels for each key, if one is not present value will be use as label
 *
 */
import { AdmissionModeFr, ReleaseModeFr } from '@sancare/ui-frontend-commons/src/types/admission-release-mode'
import { DestinationFr, OriginFr } from '@sancare/ui-frontend-commons/src/types/origin-destination'

import { AggregateTypeEnum, FilterData, FilterDataType, FilterValueType } from '@/filters/types'
import { documentCategories, textualHealthDataCategories } from '@/stay-displayer/health-data/documentLabels'

export enum AggregationFunctionEnum {
  AVERAGE = 'AVERAGE',
  COUNT = 'COUNT',
  COUNT_STAY = 'COUNT_STAY',
  MAX = 'MAX',
  MIN = 'MIN',
  QUANTILE = 'QUANTILE',
  SUM = 'SUM',
}

export const aggregationFunctionList = {
  [AggregationFunctionEnum.AVERAGE]: {
    label: 'Moyenne',
    type: AggregateTypeEnum.VALUE_AGGR,
  },
  [AggregationFunctionEnum.COUNT]: {
    label: 'Compte',
    type: AggregateTypeEnum.COUNT,
  },
  [AggregationFunctionEnum.COUNT_STAY]: {
    label: 'Compte (séjours)',
    type: AggregateTypeEnum.COUNT_STAY,
  },
  [AggregationFunctionEnum.MAX]: {
    label: 'Maximum',
    type: AggregateTypeEnum.VALUE_MIN_MAX,
  },
  [AggregationFunctionEnum.MIN]: {
    label: 'Minimum',
    type: AggregateTypeEnum.VALUE_MIN_MAX,
  },
  [AggregationFunctionEnum.QUANTILE]: {
    label: 'Quantile',
    type: AggregateTypeEnum.VALUE_AGGR,
    subFilters: [
      {
        name: 'quantile',
        type: FilterDataType.SELECT,
        options: ['0.01', '0.05', '0.1', '0.5', '0.9', '0.95', '0.99'],
        default: '0.5',
        label: 'Sélectionner un quantile',
        labels: {
          0.01: '0.01',
          0.05: '0.05',
          0.1: '0.1',
          0.5: '0.5',
          0.9: '0.9',
          0.95: '0.95',
          0.99: '0.99',
        },
      },
    ],
  },
  [AggregationFunctionEnum.SUM]: {
    label: 'Somme',
    type: AggregateTypeEnum.SUM,
  },
}

export default {
  content: {
    name: 'content',
    type: FilterDataType.TEXT_CONTENT,
    markedIndicesOptionsEnabled: true,
    valueType: FilterValueType.MULTI_VALUE,
    isGlobalCondition: 'both',
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Recherche Textuelle',
    format: '{presence}Content',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataType.SELECT,
        options: ['present', 'absent'],
        isGlobalOptions: ['absent'],
        notGlobalOptions: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Mots-clés présents',
          absent: 'Passages exclus',
        }
      }
    ]
  },
  namedEntity: {
    name: 'namedEntity',
    type: FilterDataType.TEXT_CONTENT,
    valueType: FilterValueType.MULTI_VALUE,
    markedIndicesOptionsEnabled: false,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Recherche d\'entité nommée',
    format: 'namedEntity',
    completionSubFilter: 'namedEntity',
  },
  title: {
    name: 'title',
    type: FilterDataType.TEXT,
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Titre du Document',
    format: '{presence}Content',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataType.SELECT,
        options: ['present'],
        default: 'present',
        labels: {
          present: 'Présent',
        },
      },
    ],
  },
  exposition: {
    name: 'exposition',
    type: FilterDataType.EXPOSITION,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Recherche temporelle',
    format: 'exposition',
  },
  healthConstant: {
    name: 'healthConstant',
    type: FilterDataType.CONSTANT,
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [AggregateTypeEnum.COUNT, AggregateTypeEnum.VALUE_AGGR, AggregateTypeEnum.VALUE_MIN_MAX],
    label: 'Constante',
    format: '{part}HealthConstant',
    subFilters: [
      {
        name: 'part',
        type: FilterDataType.SELECT,
        options: ['equalTo', 'greaterThan', 'lessThan'],
        default: 'equal',
        labels: {
          equalTo: 'Egale à',
          greaterThan: 'Supérieure à',
          lessThan: 'Inférieure à',
        }
      }
    ]
  },
  drug: {
    name: 'drug',
    type: FilterDataType.COMPLETION,
    completionName: 'drug',
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [AggregateTypeEnum.COUNT],
    label: 'Médicaments',
    format: '{presence}Drug',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataType.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  biology: {
    name: 'biology',
    type: FilterDataType.BIOLOGY,
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [AggregateTypeEnum.COUNT, AggregateTypeEnum.VALUE_AGGR, AggregateTypeEnum.VALUE_MIN_MAX],
    label: 'Données Biologiques',
    format: 'biologyResult',
  },
  diagnosis: {
    name: 'diagnosis',
    type: FilterDataType.COMPLETION,
    completionName: 'diagnosis',
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Diagnostics',
    format: '{presence}{diagType}Diagnosis',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataType.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
      {
        name: 'diagType',
        type: FilterDataType.SELECT,
        options: ['global', 'principal', 'related', 'associated'],
        default: 'global',
        labels: {
          global: 'Tous diagnostics',
          principal: 'DP',
          related: 'DR',
          associated: 'DAS',
        },
      },
    ],
  },
  stayDate: {
    name: 'stayDate',
    type: FilterDataType.DATE,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [AggregateTypeEnum.VALUE_MIN_MAX],
    label: 'Date de venue',
    format: '{dateType}StayDate',
    subFilters: [
      {
        name: 'dateType',
        type: FilterDataType.SELECT,
        options: ['from', 'to'],
        default: 'from',
        labels: {
          from: 'À partir du',
          to: 'Jusqu\'au',
        },
      },
    ],
    aggregationType: FilterDataType.NONE,
    aggregationFormat: 'stayDate',
  },
  deceaseDate: {
    name: 'deceaseDate',
    type: FilterDataType.DATE,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Date de décès',
    format: '{dateType}DeceaseDate',
    subFilters: [
      {
        name: 'dateType',
        type: FilterDataType.SELECT,
        options: ['from', 'to'],
        default: 'from',
        labels: {
          from: 'À partir du',
          to: 'Jusqu\'au',
        },
      },
    ],
  },
  medicalActActivity: {
    name: 'medicalActActivity',
    type: FilterDataType.SELECT,
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Activité d\'acte CCAM',
    options: ['1', '2', '3', '4', '5'],
    default: '',
    format: 'medicalActActivity',
    labels: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5'
    },
  },
  admissionMode: {
    name: 'admissionMode',
    type: FilterDataType.SELECT,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Mode d\'admission',
    options: Object.keys(AdmissionModeFr),
    default: '',
    format: 'admissionMode',
    labels: { ...AdmissionModeFr },
  },
  releaseMode: {
    name: 'releaseMode',
    type: FilterDataType.SELECT,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Mode de sortie',
    options: Object.keys(ReleaseModeFr),
    default: '',
    format: 'releaseMode',
    labels: { ...ReleaseModeFr },
  },
  medicalAct: {
    name: 'medicalAct',
    type: FilterDataType.COMPLETION,
    completionName: 'medicalAct',
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [AggregateTypeEnum.COUNT],
    label: 'Actes CCAM',
    format: '{presence}MedicalAct',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataType.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  ghm : {
    name: 'ghm',
    type: FilterDataType.COMPLETION,
    completionName: 'ghm',
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'GHM',
    format: '{presence}Ghm',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataType.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  ghmLevel: {
    name: 'ghmLevel',
    type: FilterDataType.SELECT,
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Sévérités',
    options: ['1', '2', '3', '4', 'A', 'B', 'C', 'D', 'E', 'J', 'T', 'Z'],
    default: '1',
    format: '{presence}GhmLevel',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataType.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  ghmType: {
    name: 'ghmType',
    type: FilterDataType.SELECT,
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Type de GHM',
    options: ['C', 'M', 'K', 'Z'],
    format: '{presence}GhmType',
    subFilters: [
      {
        name: 'presence',
        type: FilterDataType.SELECT,
        options: ['present', 'absent'],
        default: 'present',
        labels: {
          present: 'Présent',
          absent: 'Absent',
        },
      },
    ],
  },
  medicalUnit: {
    name: 'medicalUnit',
    type: FilterDataType.MEDICAL_UNIT,
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Unité médicale',
    format: 'medicalUnit',
  },
  medicalUnitDuration: {
    name: 'medicalUnitDuration',
    type: FilterDataType.MEDICAL_UNIT,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: false,
    allowedAggregateTypes: [AggregateTypeEnum.SUM],
    label: 'Durée dans une unité médicale',
    format: 'medicalUnitDuration',
  },
  stayDuration: {
    name: 'stayDuration',
    type: FilterDataType.RANGE,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [AggregateTypeEnum.SUM],
    aggregationType: FilterDataType.NONE,
    label: 'Durée de séjour',
    format: 'stayDuration',
    range: {
      less: false,
      min: 0,
      interval: 1,
      max: 20,
      more: true,
      unit: 'j'
    }
  },
  rumCount: {
    name: 'rumCount',
    type: FilterDataType.RANGE,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [AggregateTypeEnum.SUM],
    aggregationType: FilterDataType.NONE,
    label: 'Nombre de RUM',
    format: 'rumCount',
    range: {
      less: false,
      min: 0,
      interval: 1,
      max: 10,
      more: true,
      unit: '',
    }
  },
  patientAge: {
    name: 'patientAge',
    type: FilterDataType.RANGE,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Age du patient',
    format: 'patientAge',
    range: {
      less: false,
      min: 0,
      interval: 1,
      max: 100,
      more: true,
      unit: 'ans',
    },
  },
  gender: {
    name: 'gender',
    type: FilterDataType.SELECT,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    format: 'gender',
    label: 'Sexe',
    options: ['1', '2', '3'],
    labels: {
      1: 'Homme',
      2: 'Femme',
      3: 'Indéterminé',
    },
  },
  hasSession: {
    name: 'hasSession',
    type: FilterDataType.SELECT,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Seances',
    format: 'hasSession',
    options: ['0', '1'],
    labels: {
      0: 'Non',
      1: 'Oui',
    },
  },
  isDeceased: {
    name: 'isDeceased',
    type: FilterDataType.SELECT,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Patient décédé',
    format: 'isDeceased',
    options: ['0', '1'],
    labels: {
      0: 'Non',
      1: 'Oui',
    },
  },
  documentCategory: {
    name: 'documentCategory',
    type: FilterDataType.SELECT,
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Catégorie de document',
    format: 'documentCategory',
    options: Object.keys({ ...documentCategories, ...textualHealthDataCategories }),
    labels: { ...documentCategories, ...textualHealthDataCategories },
  },
  stay: {
    name: 'stay',
    type: FilterDataType.NONE,
    allowedInCondition: false,
    allowedAggregateTypes: [AggregateTypeEnum.COUNT_STAY],
    label: 'Séjours',
    format: 'stay',
  },
  variableResults: {
    name: 'variableResults',
    type: FilterDataType.VARIABLE,
    valueType: FilterValueType.MULTI_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Variable d\'agrégat',
    format: 'variableResults',
  },
  origin: {
    name: 'origin',
    type: FilterDataType.SELECT,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Provenance du patient',
    options: Object.keys(OriginFr),
    format: 'origin',
    default: '',
    labels: { ...OriginFr },
  },
  destination: {
    name: 'destination',
    type: FilterDataType.SELECT,
    valueType: FilterValueType.SINGLE_VALUE,
    allowedInCondition: true,
    allowedAggregateTypes: [],
    label: 'Destination du patient',
    options: Object.keys(DestinationFr),
    format: 'destination',
    labels: { ...DestinationFr },
  },
} as { [key: string]: FilterData }
