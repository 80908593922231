<script>
import { generateType, initializeSubFiltersValues } from '@/filters/utils'

export default {
  props: {
    filterData: { type: Object, required: true },
  },
  emits: ['add-filter'],
  data() {
    return {
      subFiltersValues: initializeSubFiltersValues(this.filterData),
      value: this.filterData.default ? this.filterData.default : '',
    }
  },
  methods: {
    addFilter() {
      if (!this.value || this.value === '') {
        return
      }

      const name = this.filterData.name
      const value = this.value
      const type = generateType(this.filterData.format, this.subFiltersValues)
      this.$emit('add-filter', { name, type, value })
    },
  },
}
</script>

<template>
  <div class="row gap-2">
    <div
      v-if="filterData.subFilters"
      class="col-auto"
    >
      <select
        v-for="filter in filterData.subFilters"
        :key="filter.name"
        v-model="subFiltersValues[filter.name]"
        class="custom-select"
      >
        <option
          value=""
          disabled
        >
          ---
        </option>
        <option
          v-for="opt in filter.options"
          :key="opt"
          :value="opt"
        >
          {{ filter.labels[opt] ? filter.labels[opt] : opt }}
        </option>
      </select>
    </div>
    <div
      class="input-group col"
    >
      <select
        v-model="value"
        class="custom-select"
        @change="addFilter"
      >
        <option
          value=""
          disabled
        >
          ---
        </option>
        <option
          v-for="opt in filterData.options"
          :key="opt"
          :value="opt"
        >
          {{ (filterData.labels && filterData.labels[opt]) ? filterData.labels[opt] : opt }}
        </option>
      </select>
      <div class="input-group-append">
        <button
          class="btn btn-primary"
          type="button"
          @click="addFilter"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</template>
