import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "m-1" }
const _hoisted_3 = {
  key: 0,
  class: "text-center mr-2"
}
const _hoisted_4 = { key: 1 }

import ConditionDisplayer from '@/filters/ConditionDisplayer.vue'
import ConditionGroupDisplayer from '@/filters/ConditionGroupDisplayer.vue'
import { Criterion } from '@/store/modules/search/types'

import { getDisplayableOperator } from './utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchDisplayer',
  props: {
    search: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-uppercase m-1 p-2" }, " Conditions ", -1)),
    (_ctx.search.globalConditionGroup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(ConditionGroupDisplayer, {
            "can-remove-condition": false,
            "internal-operator": _unref(getDisplayableOperator)(_ctx.search.searchOperators),
            "condition-group": _ctx.search.globalConditionGroup,
            "display-grouped-condition": true
          }, null, 8, ["internal-operator", "condition-group"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.search.conditionGroups, (conditionGroup, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'conditionGroup_'+conditionGroup.id,
          class: "row mx-2 align-items-center"
        }, [
          (idx > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(getDisplayableOperator)(_ctx.search.searchOperators)), 1))
            : _createCommentVNode("", true),
          _createVNode(ConditionGroupDisplayer, {
            class: "card",
            "can-remove-condition": false,
            "internal-operator": _unref(getDisplayableOperator)(_ctx.search.searchOperators, true),
            "condition-group": conditionGroup,
            "display-grouped-condition": false
          }, null, 8, ["internal-operator", "condition-group"])
        ]))
      }), 128))
    ]),
    (_ctx.search.aggregateCondition)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-uppercase m-1 p-2" }, " Condition d'agrégat ", -1)),
          _createVNode(ConditionDisplayer, {
            class: "card",
            "can-remove-condition": false,
            condition: _ctx.search.aggregateCondition
          }, null, 8, ["condition"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})