import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-2" }
const _hoisted_2 = { class: "p-3 border" }

import { SancareModal, SancareOcticon } from '@sancare/ui-frontend-commons'
import { ref } from 'vue'

import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyJsonImport',
  emits: ['redirect-to-new-route'],
  setup(__props, { emit: __emit }) {

const store = useStore()

const emit = __emit

const studyImportModalShow = ref(false)
const studyFileToImport = ref(null)

function openStudyImportModal() {
  studyImportModalShow.value = true
}

function closeStudyImportModal() {
  studyImportModalShow.value = false
  studyFileToImport.value = null
}

function selectFileToImport(event) {
  studyFileToImport.value = event.target.files[0]
}

function submitStudyFile() {
  store.dispatch('createStudyFromFile', studyFileToImport.value).then(() => {
    closeStudyImportModal()
    emit('redirect-to-new-route')
  })
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      type: "button",
      class: "btn btn-outline-secondary d-flex align-items-center",
      "data-toggle": "modal",
      "data-target": "#exampleModal",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (openStudyImportModal()))
    }, [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_unref(SancareOcticon), { name: "file-code" })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("span", null, " Importer la structure d'une étude ", -1))
    ]),
    _createVNode(_unref(SancareModal), {
      open: studyImportModalShow.value,
      small: true,
      onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (closeStudyImportModal()))
    }, {
      header: _withCtx(() => _cache[5] || (_cache[5] = [
        _createElementVNode("span", null, "Importer la structure d'une étude", -1)
      ])),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "btn btn-link",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (closeStudyImportModal()))
          }, " Annuler "),
          _createElementVNode("button", {
            class: "btn btn-push btn-primary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (submitStudyFile()))
          }, " Importer ")
        ])
      ]),
      default: _withCtx(() => [
        _cache[6] || (_cache[6] = _createElementVNode("p", null, "Fichier JSON à importer :", -1)),
        _createElementVNode("input", {
          name: "file",
          type: "file",
          accept: "application/json",
          onChange: selectFileToImport
        }, null, 32)
      ]),
      _: 1
    }, 8, ["open"])
  ], 64))
}
}

})