import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-uppercase" }

import { Ref, ref } from 'vue'

import AdvancedFilterBuilder from '@/filters/AdvancedFilterBuilder.vue'
import ConditionDisplayer from '@/filters/ConditionDisplayer.vue'
import { Condition, SearchTypeEnum } from '@/store/modules/search/types'
import { AggregationModifiers } from '@/variables/types'

// see eslint issue https://github.com/vuejs-templates/webpack/issues/592
import AggregateCondition from './AggregateCondition.vue' // eslint-disable-line @typescript-eslint/no-unused-vars, no-unused-vars


export default /*@__PURE__*/_defineComponent({
  __name: 'FastConditionCreator',
  props: {
    initialConditionName: {},
    searchType: {}
  },
  emits: ['add-condition'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit

const props = __props

const conditionName = ref(props.initialConditionName)
const fastCondition = ref(null) as Ref<Condition>
const aggregateCondition = ref(null) as Ref<Condition>
const aggregateModifiers = ref(null) as Ref<AggregationModifiers>

function addFastCondition() {
  if (fastCondition.value || aggregateCondition.value) {
    emit('add-condition', fastCondition.value, conditionName.value, aggregateCondition.value, aggregateModifiers.value)
  }
}
function setFastCondition(condition: Condition) {
  fastCondition.value = condition
  if (props.searchType === SearchTypeEnum.CRITERION) {
    addFastCondition()
  }
}
function removeFastCondition() {
  fastCondition.value = null
}
function setAggregateCondition(condition: Condition, modifiers: AggregationModifiers) {
  aggregateCondition.value = condition
  aggregateModifiers.value = modifiers
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, " Créer " + _toDisplayString(_ctx.searchType === _unref(SearchTypeEnum).CRITERION ? 'un critère' : 'une variable') + " rapide ", 1),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((conditionName).value = $event)),
      class: _normalizeClass(["form-control", {'is-invalid': conditionName.value === ''}]),
      type: "text"
    }, null, 2), [
      [_vModelText, conditionName.value]
    ]),
    _createVNode(ConditionDisplayer, {
      "can-remove-condition": true,
      condition: fastCondition.value,
      onRemoveCondition: removeFastCondition
    }, null, 8, ["condition"]),
    _createVNode(AdvancedFilterBuilder, {
      class: "mt-2",
      "group-is-global": false,
      "is-fast-condition": true,
      onAddCondition: setFastCondition
    }),
    (_ctx.searchType === _unref(SearchTypeEnum).VARIABLE)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(AggregateCondition, {
            "is-fast-condition": true,
            condition: aggregateCondition.value,
            modifiers: aggregateModifiers.value,
            onAddCondition: setAggregateCondition
          }, null, 8, ["condition", "modifiers"]),
          _createElementVNode("button", {
            class: "btn btn-primary",
            type: "button",
            onClick: addFastCondition
          }, " Créer la variable ")
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})