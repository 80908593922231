import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "full-height" }
const _hoisted_2 = { class: "text-center font-italic" }

import { SancareToast } from '@sancare/ui-frontend-commons'
import { computed, onMounted, watch } from 'vue'

import { useStore } from '@/store'

import SancareBreadcrumb from './home/SancareBreadcrumb.vue'
import SancareLogo from './home/SancareLogo.vue'
import LoginModal from './login/LoginModal.vue'
import { studyPermissions } from './misc/permissions/permissionsHelper'
import router from './router'
import SancareSettings from './settings/SancareSettings.vue'

const remoteListApiUrlPrefix = '/api/stay-criterion-condition/'


export default /*@__PURE__*/_defineComponent({
  __name: 'SancareApp',
  setup(__props) {

const store = useStore()

const isAuthenticated = computed(() => store.getters.isAuthenticated)
const sessionIsValid = computed(() => store.getters.sessionIsValid)
const userRole = computed(() => store.getters.userRole)
const username = computed(() => store.getters.username)
const appVersion = computed(() => store.getters.appVersion)
const toast = computed(() => store.state.toast)
const currentUser = computed(() => store.state.settings.currentUser)

async function fetchInitializationData() {
  store.dispatch('getUserSettings')
  if (!studyPermissions.canCreate(currentUser.value)) {
    return
  }
  await store.dispatch('medicalUnitFilter/fetchConstants', {
    url: `${remoteListApiUrlPrefix}list?type=constant`,
  })
  await store.dispatch('medicalUnitFilter/fetchMedicalUnits', {
    url: `${remoteListApiUrlPrefix}list?type=medicalUnit`,
  })
  await store.dispatch('medicalUnitFilter/fetchNamedEntities', {
    url: `${remoteListApiUrlPrefix}list?type=namedEntity`,
  })
}

watch(sessionIsValid, async (sess) => {
  await store.dispatch('getSelf')
  if (sess) {
    await fetchInitializationData()
  }
})
watch(isAuthenticated, (isAuth, wasAuthenticated) => {
  if (isAuth && !wasAuthenticated) {
    router.push({
      path: store.state.route.query.redirect || '/',
    })
  } else if (!isAuth) {
    router.push({
      path: '/login',
      query: {
        redirect: store.state.route.fullPath,
      }
    })
  }
})

onMounted(async () => {
  await store.dispatch('getSelf')
  if (isAuthenticated.value && sessionIsValid.value) {
    await fetchInitializationData()
  } else if (isAuthenticated.value && !sessionIsValid.value) {
    store.commit('sessionExpired')
  } else if (username.value && !isAuthenticated.value) {
    store.dispatch('logout')
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["full-height", isAuthenticated.value ? '' : 'unlogged-page'])
  }, [
    (isAuthenticated.value && !sessionIsValid.value)
      ? (_openBlock(), _createBlock(LoginModal, { key: 0 }))
      : _createCommentVNode("", true),
    (isAuthenticated.value)
      ? (_openBlock(), _createBlock(SancareLogo, { key: 1 }))
      : _createCommentVNode("", true),
    (isAuthenticated.value)
      ? (_openBlock(), _createBlock(SancareSettings, {
          key: 2,
          "user-role": userRole.value
        }, null, 8, ["user-role"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (isAuthenticated.value)
        ? (_openBlock(), _createBlock(SancareBreadcrumb, {
            key: 0,
            id: "breadcrumb"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view, { class: "full-height-leaf" }),
      _createVNode(_unref(SancareToast), {
        color: toast.value.color,
        "is-open": toast.value.isOpen,
        message: toast.value.message,
        title: toast.value.title,
        onCloseToast: _cache[0] || (_cache[0] = ($event: any) => (_unref(store).commit('toast/setIsOpen', false)))
      }, null, 8, ["color", "is-open", "message", "title"]),
      _createElementVNode("footer", _hoisted_2, " Sancare · Analyse de données de vie réelle · " + _toDisplayString(appVersion.value), 1)
    ])
  ], 2))
}
}

})