import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "patient-list-table" }
const _hoisted_3 = { class: "align-items-center" }
const _hoisted_4 = ["name", "onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  class: "order-button",
  xmlns: "http://www.w3.org/2000/svg",
  width: "10",
  height: "12",
  viewBox: "0 0 17 20",
  version: "1.1"
}
const _hoisted_8 = ["fill"]
const _hoisted_9 = ["fill"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["colspan"]
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { class: "patient-list-line" }
const _hoisted_14 = ["colspan"]

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'
import { computed, onMounted } from 'vue'
import { RouteLocationNormalizedLoaded } from 'vue-router'

import { sortColumnList } from '@/patient-list/PatientListColumns'
import { useStore } from '@/store'
import { Patient, PatientListColumn } from '@/store/modules/patient/types'

import PatientListLine from './PatientListLine.vue'
import PatientListSettings from './PatientListSettings.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientListTable',
  props: {
    displayedColumns: {},
    patientList: {},
    order: { default: 'ipp' },
    reverseOrder: { type: Boolean, default: true },
    fetchPatientListRequest: {}
  },
  emits: ['refresh-list', 'goto-patient-page', 'set-columns'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const emit = __emit
const props = __props

const selectedPatients = computed(() => store.state.patientList.selectedPatients)
const columnList = computed(() => sortColumnList(store.state.search.patientListColumns))
const sortedDisplayedColumns = computed(() => sortColumnList(props.displayedColumns))

onMounted(() => {
  store.commit('resetPatientSelection')
})

function coloredButtonIndex(columnInfo: PatientListColumn): number {
  if (columnInfo.name !== props.order) {
    return -1
  }

  return ((!props.reverseOrder && columnInfo.invertedOrder) || (props.reverseOrder && !columnInfo.invertedOrder)) ? 1 : 0
}
function setOrder(column: PatientListColumn) {
  if (column.sortDisabled) {
    return
  }
  store.commit('setPatientListParam', { type: 'currentPage', value: 1 })
  if (column.name === props.order) {
    store.commit('setPatientListParam', { type: 'reverseOrder', value: !props.reverseOrder })
  } else {
    store.commit('setPatientListParam', { type: 'order', value: column.name })
    store.commit('setPatientListParam', { type: 'reverseOrder', value: false })
  }
  emit('refresh-list', { resetPatientSelection: true, refreshUrl: false })
}
function setColumns(columns: { [key: string]: PatientListColumn }) {
  emit('set-columns', columns)
  emit('refresh-list', { resetPatientSelection: true, refreshUrl: false })
}
function isSelectedPatient(patient: Patient): boolean {
  return Boolean(selectedPatients.value.find((s) => s.ipp === patient.ipp))
}
function toggleSelectedPatient(patient: Patient) {
  store.commit('toggleSelectedPatient', patient)
}
function gotoPatientPage(forceNewWindow: boolean, newRoute: RouteLocationNormalizedLoaded) {
  emit('goto-patient-page', forceNewWindow, newRoute)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedDisplayedColumns.value, (columnInfo, columnName) => {
            return (_openBlock(), _createElementBlock("th", {
              key: 'column_'+columnName,
              name: 'column_'+columnName,
              class: _normalizeClass({'sort-disabled': columnInfo.sortDisabled}),
              onClick: ($event: any) => (setOrder(columnInfo))
            }, [
              (columnInfo.icon)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _createVNode(_unref(SancareOcticon), {
                      name: columnInfo.icon,
                      width: 14,
                      height: 14
                    }, null, 8, ["name"])
                  ]))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(columnInfo.label) + " ", 1),
              (!columnInfo.sortDisabled)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    (_openBlock(), _createElementBlock("svg", _hoisted_7, [
                      _createElementVNode("path", {
                        fill: coloredButtonIndex(columnInfo) === 0 ? 'blue' : 'lightgrey',
                        d: "M0 8 l 7 -7 7 7 z"
                      }, null, 8, _hoisted_8),
                      _createElementVNode("path", {
                        fill: coloredButtonIndex(columnInfo) === 1 ? 'blue' : 'lightgrey',
                        d: "M0 13 l 7 7 7 -7 z"
                      }, null, 8, _hoisted_9)
                    ]))
                  ]))
                : _createCommentVNode("", true)
            ], 10, _hoisted_4))
          }), 128)),
          _createElementVNode("th", null, [
            _createVNode(PatientListSettings, {
              "user-columns": columnList.value,
              "displayed-columns": sortedDisplayedColumns.value,
              onSetColumns: setColumns
            }, null, 8, ["user-columns", "displayed-columns"])
          ])
        ])
      ]),
      (_ctx.fetchPatientListRequest.fetching)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_10, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", {
                colspan: Object.keys(columnList.value).length + 2
              }, _cache[0] || (_cache[0] = [
                _createElementVNode("div", {
                  name: "patientList_loader",
                  class: "loader loader-lg loader-center"
                }, null, -1)
              ]), 8, _hoisted_11)
            ])
          ]))
        : (_ctx.patientList && _ctx.patientList.length)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.patientList, (patient) => {
              return (_openBlock(), _createElementBlock("tbody", {
                key: 'tbody_Patient_'+patient.ipp
              }, [
                (_openBlock(), _createBlock(PatientListLine, {
                  key: 'patientList_Patient_'+patient.ipp,
                  patient: patient,
                  columns: sortedDisplayedColumns.value,
                  selected: isSelectedPatient(patient),
                  onToggleSelectedPatient: toggleSelectedPatient,
                  onGotoPatientPage: gotoPatientPage
                }, null, 8, ["patient", "columns", "selected"]))
              ]))
            }), 128))
          : (_openBlock(), _createElementBlock("tbody", _hoisted_12, [
              _createElementVNode("tr", _hoisted_13, [
                _createElementVNode("td", {
                  colspan: Object.keys(columnList.value).length + 2
                }, " Aucun patient correspondant pour la requête donnée ", 8, _hoisted_14)
              ])
            ]))
    ])
  ]))
}
}

})