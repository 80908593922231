import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "row col-12" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "m-1 row" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = {
  key: 0,
  class: "row principal-diagnosis"
}
const _hoisted_20 = { class: "m-1 badge text-wrap" }
const _hoisted_21 = {
  key: 1,
  class: "row related-diagnosis"
}
const _hoisted_22 = { class: "m-1 badge text-wrap" }
const _hoisted_23 = {
  key: 2,
  class: "row associated-diagnosis"
}
const _hoisted_24 = {
  key: 3,
  class: "row acts"
}
const _hoisted_25 = { class: "col" }

import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'

import filtersData from '@/filters/FiltersData'
import DocumentSelector from '@/patient/DocumentSelector.vue'
import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'StayDetails',
  setup(__props) {

const store = useStore()
const selectedRumIdx = ref(0)

const currentStay = computed(() => store.state.patient.currentStay)
const currentPatient = computed(() => store.state.patient.currentPatient)

watch(() => currentStay.value, () => {
  selectedRumIdx.value = 0
})

function explainModeAndPlace(modeType: 'admissionMode' | 'releaseMode', placeType: 'origin' | 'destination', mode: string | null, place: string | null) {
  return `${filtersData[modeType]['labels'][mode] ?? 'Non renseigné'} `
    + `/ ${filtersData[placeType]['labels'][place] || 'Non renseigné'}`
}

function selectRum(idx: number) {
  selectedRumIdx.value = idx
}

function showDeceaseInfo(): string {
  if (currentPatient.value.isDeceased === false) {
    return 'Non'
  }
  if (currentPatient.value.deceaseDate) {
    return dayjs(currentPatient.value.deceaseDate).format('DD/MM/YYYY')
  }
  return currentPatient.value.isDeceased === true ? 'Oui (date inconnue)' : 'Inconnu'
}

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (currentStay.value !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "py-2" }, [
          _createElementVNode("h2", null, " Séjour en cours de consultation ")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "col-4" }, " Dates du séjour ", -1)),
                _createElementVNode("div", _hoisted_6, " Du " + _toDisplayString(_unref(dayjs)(currentStay.value.stayStart).format('XX/MM/YYYY')) + " au " + _toDisplayString(_unref(dayjs)(currentStay.value.stayEnd).format('XX/MM/YYYY')) + " (" + _toDisplayString(currentStay.value.stayDuration) + "j) ", 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "col-4" }, " GHM ", -1)),
                _createElementVNode("div", _hoisted_8, _toDisplayString(currentStay.value.standardModeGhm ? currentStay.value.standardModeGhm.reference : ''), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "col-4" }, " Âge en entrée ", -1)),
                _createElementVNode("div", _hoisted_10, _toDisplayString(currentStay.value.patientAge ? currentStay.value.patientAge + ' ans' : 'Non renseigné'), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "col-4" }, " Patient décédé ", -1)),
                _createElementVNode("div", _hoisted_12, _toDisplayString(showDeceaseInfo()), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentStay.value.rums, (rum, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: rum.id,
                    class: _normalizeClass(["btn btn-sm btn-report-select", {active: idx === selectedRumIdx.value}]),
                    onClick: ($event: any) => (selectRum(idx))
                  }, " RUM " + _toDisplayString(idx + 1), 11, _hoisted_15))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "col-4" }, " Unité médicale ", -1)),
                _createElementVNode("div", null, _toDisplayString(currentStay.value.rums[selectedRumIdx.value].medicalUnit
                ? currentStay.value.rums[selectedRumIdx.value].medicalUnit.reference
                : 'Non renseignée'), 1)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "col-4" }, " Mode d'entrée/provenance ", -1)),
                _createElementVNode("div", null, _toDisplayString(explainModeAndPlace('admissionMode', 'origin', currentStay.value.rums[selectedRumIdx.value].admissionMode, currentStay.value.rums[selectedRumIdx.value].origin)), 1)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "col-4" }, " Mode de sortie/destination ", -1)),
                _createElementVNode("div", null, _toDisplayString(explainModeAndPlace('releaseMode', 'destination', currentStay.value.rums[selectedRumIdx.value].releaseMode, currentStay.value.rums[selectedRumIdx.value].destination)), 1)
              ]),
              (currentStay.value.rums[selectedRumIdx.value].standardModeDiagnosisGroup.DP)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "col-4" }, " DP ", -1)),
                    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _createTextVNode(_toDisplayString(currentStay.value.rums[selectedRumIdx.value].standardModeDiagnosisGroup.DP.reference), 1)
                    ])), [
                      [_directive_tooltip, currentStay.value.rums[selectedRumIdx.value].standardModeDiagnosisGroup.DP.description]
                    ])
                  ]))
                : _createCommentVNode("", true),
              (currentStay.value.rums[selectedRumIdx.value].standardModeDiagnosisGroup.DR)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "col-4" }, " DR ", -1)),
                    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createTextVNode(_toDisplayString(currentStay.value.rums[selectedRumIdx.value].standardModeDiagnosisGroup.DR.reference), 1)
                    ])), [
                      [_directive_tooltip, currentStay.value.rums[selectedRumIdx.value].standardModeDiagnosisGroup.DR.description]
                    ])
                  ]))
                : _createCommentVNode("", true),
              (currentStay.value.rums[selectedRumIdx.value].standardModeDiagnosisGroup.associatedDiags.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _cache[9] || (_cache[9] = _createElementVNode("h2", { class: "col-4" }, " DAS ", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentStay.value.rums[selectedRumIdx.value].standardModeDiagnosisGroup.associatedDiags, (diag) => {
                      return _withDirectives((_openBlock(), _createElementBlock("div", {
                        key: diag.id,
                        class: "m-1 badge text-wrap"
                      }, [
                        _createTextVNode(_toDisplayString(diag.associatedDiagnosis.reference), 1)
                      ])), [
                        [_directive_tooltip, diag.associatedDiagnosis.description]
                      ])
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (currentStay.value.rums[selectedRumIdx.value].codedMedicalActs.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _cache[10] || (_cache[10] = _createElementVNode("h2", { class: "col-4" }, " Actes ", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentStay.value.rums[selectedRumIdx.value].codedMedicalActs, (act) => {
                      return _withDirectives((_openBlock(), _createElementBlock("div", {
                        key: act.id,
                        class: "m-1 badge text-wrap"
                      }, [
                        _createTextVNode(_toDisplayString(act.associatedAct.reference) + " (Act." + _toDisplayString(act.associatedAct.activity) + ") ", 1)
                      ])), [
                        [_directive_tooltip, act.associatedAct.description]
                      ])
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createVNode(DocumentSelector)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})