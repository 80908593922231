import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "large-container" }
const _hoisted_2 = { class: "row align-items-center no-gutters" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = {
  key: 0,
  class: "card card-blue card-rounded px-4 col-auto"
}
const _hoisted_5 = { class: "row align-items-center" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "col-auto" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "diagram-container" }
const _hoisted_10 = {
  key: 0,
  class: "loader loader-lg loader-center"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "diagram-header" }
const _hoisted_13 = { class: "row align-items-center p-2" }
const _hoisted_14 = { class: "col-auto pr-1" }
const _hoisted_15 = {
  key: 0,
  class: "col-auto px-1"
}
const _hoisted_16 = {
  key: 1,
  class: "input-group"
}
const _hoisted_17 = { class: "input-group-append" }
const _hoisted_18 = {
  id: "legend",
  class: "diagram-container p-3"
}
const _hoisted_19 = { class: "row px-3" }
const _hoisted_20 = { class: "col-auto px-1" }
const _hoisted_21 = ["value", "disabled"]
const _hoisted_22 = {
  key: 1,
  class: "px-1 row"
}
const _hoisted_23 = { class: "col-auto pl-1" }
const _hoisted_24 = ["disabled", "value"]
const _hoisted_25 = ["value"]
const _hoisted_26 = {
  key: 2,
  id: "inv-picker",
  class: "col-auto px-1"
}
const _hoisted_27 = {
  key: 3,
  class: "col-auto pl-1 ml-auto"
}
const _hoisted_28 = { class: "row align-items-center" }
const _hoisted_29 = { class: "col-auto px-1" }
const _hoisted_30 = { class: "col-auto pl-1" }
const _hoisted_31 = { key: 2 }
const _hoisted_32 = { class: "diagram-stats-container-left" }

import { SancareMultiselect, SancareOcticon, SancareToggle } from '@sancare/ui-frontend-commons'
import { MultiSelectItem } from '@sancare/ui-frontend-commons/src/components/types'
import _ from 'lodash'
import { computed, ComputedRef, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'

import { studyNodePermissions, studyPermissions, studyStepPermissions } from '@/misc/permissions/permissionsHelper'
import router from '@/router.js'
import { useStore } from '@/store'
import StudyGeneralInfo from '@/study/StudyGeneralInfo.vue'
// import StudyStepsPositionModal from '@/study/StudyStepsPositionModal.vue'
import StudyJsonImport from '@/study/StudyJsonImport.vue'
import StudyStatistics from '@/study/StudyStatistics.vue'

import DiagramPaper from '../diagram/DiagramPaper.vue'
import DiagramStep from '../diagram/DiagramStep.vue'
import DetailsStep from './DetailsStep.vue'
import DetailsStudyNode from './DetailsStudyNode.vue'
import { findStepFromStudyNode } from './utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyPage',
  setup(__props) {

const store = useStore()

// const showStepSortingModal = ref(false)
const showDiagramStat = ref(true)
const showLegend = ref(false)
const showNewStepInput = ref(false)
const newStepName = ref('Nouvelle étape')
const newStepNameInput = ref(null)

const currentUser = computed(() => store.state.settings.currentUser)
const currentStudy = computed(() => store.state.study.currentStudy)
const paperReady = computed(() => store.state.study.paperReady)
const currentStudyStats = computed(() => store.state.study.currentStudyStats)
const selectedStudyNode = computed(() => store.state.study.selectedStudyNode)
const selectedStudyNodeStep = computed(() => findStepFromStudyNode(currentStudy.value, selectedStudyNode.value.id))
const selectedStep = computed(() => store.state.study.selectedStep)
const studyId = computed(() => router.currentRoute.value.params.studyId)
const fetchStudyRequest = computed(() => store.state.study.request.fetchStudy)
const updateStudyRequest = computed(() => store.state.study.request.updateStudy)
const fetchInvestigatorsRequest = computed(() => store.state.study.requests.fetchInvestigators)
const investigators = computed(() => store.state.study.investigators.filter((inv) => inv.firstName !== null && inv.lastName !== null).map((inv) => ({
  label: `${inv.firstName} ${inv.lastName}`,
  value: inv.id,
}))) as ComputedRef<MultiSelectItem[]>
const selectedInvestigators = computed(() => {
  return !currentStudy.value.studyUsers ? [] : currentStudy.value.studyUsers.map((su) => su.id)
})
const foundations = computed(() => store.state.study.foundations)
const selectedFoundationDataId = computed(() => currentStudy.value.foundation?.dataGeneratedId)
const requestsSuccess = computed(() =>
  store.state.study.request.createStudy.ok
  || store.state.study.request.updateStudy.ok
  || store.state.study.request.addStudyStep.ok
  || store.state.study.request.updateStepAttributes.ok
  || store.state.study.request.addStudyNode.ok
  || store.state.study.request.updateStudyNodeAttributes.ok
)
const canAttachUser = computed(() => studyPermissions.canAttachUser(currentStudy.value, currentUser.value))
const canEditName = computed(() => studyPermissions.canEditName(currentStudy.value, currentUser.value))
const canEditPrivacy = computed(() => studyPermissions.canEditPrivacy(currentStudy.value, currentUser.value))
const canEditFoundation = computed(() => studyPermissions.canEditFoundation(currentStudy.value, currentUser.value))
const canEditSteps = computed(() => studyPermissions.canEditSteps(currentStudy.value, currentUser.value))
const canEditCoreStep = computed(() => studyPermissions.canEditCoreStep(currentStudy.value, currentUser.value))
const canValidate = computed(() => studyPermissions.canValidate(currentStudy.value, currentUser.value))
const canUnvalidate = computed(() => studyPermissions.canUnvalidate(currentStudy.value, currentUser.value))
const canShowPatients = computed(() => studyPermissions.canShowPatientList(currentStudy.value, currentUser.value))

function refreshStudyStats() {
  store.dispatch('fetchStudyStats', studyId.value)
  store.dispatch('fetchStudyPatientCount', studyId.value)
}

onMounted(async () => {
  store.commit('resetStudyUpdateRequests')

  if (studyId.value === 'new') {
    store.commit('resetStudy')
    store.commit('resetStudyStats')
  } else {
    await store.dispatch('fetchStudy', { studyId: studyId.value, forceFetch: true })
    refreshStudyStats()
  }
  store.commit('setStudyComponentReady', true)

  store.commit(
    'addBreadcrumbEntry',
    {
      name: `Étude ${store.state.study.currentStudy.name ?? ''}`,
      customKey: '/study/',
      routerLinkArg: location.pathname + location.search,
    }
  )

  if (canAttachUser.value) {
    store.dispatch('fetchInvestigators')
  }
  if (canEditFoundation.value) {
    store.dispatch('fetchFoundations')
  }
})
onUnmounted(() => {
  store.commit('setStudyComponentReady', false)
  store.commit('resetDiagram')
})

function resetShowStepNewNameInput() {
  newStepName.value = 'Nouvelle étape'
  showNewStepInput.value = false
}
function focusNewStepNameInput() {
  showNewStepInput.value = true
  nextTick(function() {
    newStepNameInput.value.focus()
    newStepNameInput.value.select()
  })
}

watch(() => currentStudy.value, (newValue, oldValue) => {
  if (oldValue.id === newValue.id && oldValue.updatedAt !== newValue.updatedAt) {
    refreshStudyStats()
  }
})
watch(requestsSuccess, (newVal) => {
  if (newVal) {
    store.dispatch('toast/setSuccess', 'Étude enregistrée.')
  }
})

async function createStudy() {
  await store.dispatch('createStudy')
  redirectToNewRoute()
}

async function redirectToNewRoute() {
  if (!currentStudy.value.id) {
    return
  }

  await router.push({ name: 'study', params: { studyId: currentStudy.value.id } })
  store.commit(
    'addBreadcrumbEntry',
    {
      name: `Étude ${currentStudy.value.name}`,
      customKey: '/study/',
      routerLinkArg: location.pathname + location.search,
    }
  )
}

async function addStep() {
  if (newStepName.value === '') {
    return
  }
  store.commit('addStep', { name: newStepName.value })
  resetShowStepNewNameInput()
  if (studyId.value === 'new') {
    await createStudy()
    return
  }
  await store.dispatch('addStudyStep', {
    studyStep: _.maxBy(currentStudy.value.steps, 'position'),
    studyId: currentStudy.value.id,
  })
  refreshStudyStats()
}

function validateStudy() {
  store.dispatch('validateStudy', currentStudy.value.id)
}

function unvalidateStudy() {
  store.dispatch('unvalidateStudy', currentStudy.value.id)
}

function setInvestigators(userIds: number[]) {
  store.dispatch('updateStudyUsers', {
    studyId: currentStudy.value.id,
    userIds,
  })
}

function setStudyName(value: string) {
  store.commit('updateStudyProperty', { prop: 'name', value: value })
  if (studyId.value === 'new') {
    createStudy()
    return
  }
  store.dispatch('updateStudyAttributes', currentStudy.value)
}
function setStudyPrivacy(value: boolean) {
  store.commit('updateStudyProperty', { prop: 'privacy', value: value ? 'public' : 'private' })
  store.dispatch('updateStudyAttributes', currentStudy.value)
}
function setStudyFoundation(dataGeneratedId: number) {
  const selectedFoundation = foundations.value.find((foundation) =>
    foundation.dataGeneratedId === dataGeneratedId
  ) ?? null
  store.commit('updateStudyProperty', { prop: 'foundation', value: selectedFoundation })
  store.dispatch('updateStudyAttributes', currentStudy.value)
}

/* function closeModal() {
  showStepSortingModal.value = false
  store.commit('resetSelected')
}*/
function unzoom() {
  store.commit('unzoomDiagram')
}
function zoom() {
  store.commit('zoomDiagram')
}
function toggleDiagramStat() {
  showDiagramStat.value = !showDiagramStat.value
}

return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (studyId.value === 'new')
            ? (_openBlock(), _createBlock(StudyJsonImport, {
                key: 0,
                onRedirectToNewRoute: redirectToNewRoute
              }))
            : _createCommentVNode("", true)
        ]),
        (canEditCoreStep.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_unref(SancareOcticon), { name: "light-bulb" })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  (currentStudy.value.validatedAt === null)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Merci de créer une ou plusieurs étape(s) socle(s) " + _toDisplayString(canAttachUser.value ? 'et de choisir les investigateurs de l\'étude pour pouvoir la valider' : 'pour permettre à l\'administrateur de choisir les investigateurs et la valider.'), 1))
                    : _createCommentVNode("", true),
                  _cache[5] || (_cache[5] = _createElementVNode("div", null, " Les étapes socles définissent le scope de l'étude, elles ne peuvent être modifiées que par le créateur de l'étude ou l'administrateur ", -1))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_9, [
        (!paperReady.value || fetchStudyRequest.value.fetching || updateStudyRequest.value.fetching)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10))
          : (currentStudyStats.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentStudy.value.steps, (step) => {
                  return (_openBlock(), _createBlock(DiagramStep, {
                    key: step.id,
                    step: step,
                    count: currentStudyStats.value[step.id] ? currentStudyStats.value[step.id].patientCount : 0,
                    "is-readonly": !_unref(studyStepPermissions).canEdit(step, currentUser.value, currentStudy.value)
                  }, null, 8, ["step", "count", "is-readonly"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
        _createVNode(DiagramPaper, {
          "is-readonly": !canEditSteps.value && !canEditCoreStep.value
        }, null, 8, ["is-readonly"]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "pr-2 va-sub" }, "Zoom", -1)),
              _createElementVNode("span", {
                class: "pr-1",
                onClick: unzoom
              }, [
                _createVNode(_unref(SancareOcticon), {
                  name: "diff-removed",
                  width: 20,
                  height: 20
                })
              ]),
              _createElementVNode("span", { onClick: zoom }, [
                _createVNode(_unref(SancareOcticon), {
                  name: "diff-added",
                  width: 20,
                  height: 20
                })
              ])
            ]),
            (canEditSteps.value || canEditCoreStep.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  (!showNewStepInput.value)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn btn-rounded",
                        onClick: _withModifiers(focusNewStepNameInput, ["prevent"])
                      }, " Ajouter une étape "))
                    : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _withDirectives(_createElementVNode("input", {
                          ref_key: "newStepNameInput",
                          ref: newStepNameInput,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((newStepName).value = $event)),
                          class: "form-control",
                          type: "text",
                          onKeyup: _withKeys(addStep, ["enter"])
                        }, null, 544), [
                          [_vModelText, newStepName.value]
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("button", {
                            class: "btn btn-primary",
                            type: "button",
                            onClick: _withModifiers(addStep, ["prevent"])
                          }, " Ok ")
                        ])
                      ]))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("span", {
              class: "px-1 col-auto",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (showLegend.value = true))
            }, [
              _createVNode(_component_v_dropdown, {
                open: showLegend.value,
                "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((showLegend).value = $event)),
                placement: "bottom",
                class: "d-inline"
              }, {
                popper: _withCtx(() => [
                  _createElementVNode("div", _hoisted_18, [
                    _cache[11] || (_cache[11] = _createElementVNode("h5", null, "Légende:", -1)),
                    _createElementVNode("div", _hoisted_19, [
                      _cache[7] || (_cache[7] = _createElementVNode("div", null, [
                        _createElementVNode("div", { class: "mx-1 rectangle search" }, "Critère")
                      ], -1)),
                      _cache[8] || (_cache[8] = _createElementVNode("div", null, [
                        _createElementVNode("div", { class: "mx-1 rectangle study-node" }, "Opérateur")
                      ], -1)),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["mx-1 rectangle step core", {'disabled': canEditCoreStep.value}])
                        }, "Étape socle", 2)
                      ]),
                      _cache[9] || (_cache[9] = _createElementVNode("div", null, [
                        _createElementVNode("div", { class: "mx-1 rectangle step" }, "Etape")
                      ], -1)),
                      _cache[10] || (_cache[10] = _createElementVNode("div", null, [
                        _createElementVNode("div", { class: "mx-1 rectangle step" }, "Dernière étape")
                      ], -1))
                    ])
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_unref(SancareOcticon), {
                    name: "question",
                    width: 20,
                    height: 20
                  }),
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              }, 8, ["open"])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("input", {
                id: "studyName",
                value: currentStudy.value.name,
                type: "text",
                class: "form-control",
                disabled: !canEditName.value,
                placeholder: "Nom de l'étude",
                onChange: _cache[3] || (_cache[3] = event => setStudyName(event.srcElement.value))
              }, null, 40, _hoisted_21)
            ]),
            (currentStudy.value.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _cache[13] || (_cache[13] = _createElementVNode("label", {
                    class: "col pr-0 col-form-label",
                    for: "foundation-picker"
                  }, "Pré-socle:", -1)),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("select", {
                      id: "foundation-picker",
                      disabled: !canEditFoundation.value,
                      class: "form-control",
                      value: selectedFoundationDataId.value,
                      onChange: _cache[4] || (_cache[4] = event => setStudyFoundation(Number(event.srcElement.value)))
                    }, [
                      _cache[12] || (_cache[12] = _createElementVNode("option", {
                        disabled: "disabled",
                        value: "0"
                      }, " --- ", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(foundations.value, (foundation) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: foundation.dataGeneratedId,
                          value: foundation.dataGeneratedId
                        }, _toDisplayString(foundation.dataGeneratedId) + " - " + _toDisplayString(foundation.label), 9, _hoisted_25))
                      }), 128))
                    ], 40, _hoisted_24)
                  ])
                ]))
              : _createCommentVNode("", true),
            (currentStudy.value.id && canAttachUser.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  _createVNode(_unref(SancareMultiselect), {
                    "is-ready": fetchInvestigatorsRequest.value.ok,
                    "display-list-limit": 3,
                    title: 'Investigateurs',
                    "selected-items": selectedInvestigators.value,
                    items: investigators.value,
                    onUpdateSelection: setInvestigators
                  }, null, 8, ["is-ready", "selected-items", "items"])
                ]))
              : _createCommentVNode("", true),
            (currentStudy.value.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "col-auto px-1" }, " Publique : ", -1)),
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_unref(SancareToggle), {
                        class: "align-items-center pt-2",
                        disabled: !canEditPrivacy.value,
                        value: currentStudy.value.privacy === 'public',
                        onToggleChange: setStudyPrivacy
                      }, null, 8, ["disabled", "value"])
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      (canValidate.value)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "btn btn-rounded",
                            onClick: _withModifiers(validateStudy, ["prevent"])
                          }, " Valider l'étude "))
                        : (canUnvalidate.value)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 1,
                              class: "btn btn-rounded",
                              onClick: _withModifiers(unvalidateStudy, ["prevent"])
                            }, " Dé-valider l'étude "))
                          : (_openBlock(), _createElementBlock("span", _hoisted_31, "Etude " + _toDisplayString(null === currentStudy.value.validatedAt ? 'non ' : '') + "validée", 1))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["diagram-details-container", {
            hidden: selectedStudyNode.value === null && selectedStep.value === null,
            'study-node': selectedStudyNode.value && !selectedStudyNode.value.name,
            search: selectedStudyNode.value && selectedStudyNode.value.name,
            step: selectedStep.value,
            'full-width': !showDiagramStat.value
          }])
        }, [
          (selectedStudyNode.value && selectedStudyNodeStep.value)
            ? (_openBlock(), _createBlock(DetailsStudyNode, {
                key: 0,
                "study-node": selectedStudyNode.value,
                "study-node-step": selectedStudyNodeStep.value,
                readonly: !_unref(studyNodePermissions).canEdit(selectedStudyNode.value, currentUser.value, selectedStudyNodeStep.value, currentStudy.value),
                "current-user": currentUser.value
              }, null, 8, ["study-node", "study-node-step", "readonly", "current-user"]))
            : _createCommentVNode("", true),
          (selectedStep.value)
            ? (_openBlock(), _createBlock(DetailsStep, {
                key: 1,
                step: selectedStep.value,
                "is-readonly": !_unref(studyStepPermissions).canEdit(selectedStep.value, currentUser.value, currentStudy.value),
                "can-show-patients": canShowPatients.value
              }, null, 8, ["step", "is-readonly", "can-show-patients"]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([{'full-width': showDiagramStat.value}, "diagram-stats-top-container"])
        }, [
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("button", {
              class: "btn btn-secondary",
              onClick: _withModifiers(toggleDiagramStat, ["prevent"])
            }, _toDisplayString(showDiagramStat.value ? '>' : '<'), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass([{ 'hidden': !showDiagramStat.value }, "diagram-stats-container"])
          }, [
            _createVNode(StudyGeneralInfo, {
              "current-study": currentStudy.value,
              "can-show-patients": canShowPatients.value
            }, null, 8, ["current-study", "can-show-patients"]),
            _cache[15] || (_cache[15] = _createElementVNode("hr", null, null, -1)),
            _createVNode(StudyStatistics, {
              "study-stats": currentStudyStats.value,
              "selected-step": selectedStep.value
            }, null, 8, ["study-stats", "selected-step"])
          ], 2)
        ], 2)
      ])
    ])
  ]))
}
}

})