import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, withKeys as _withKeys, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-warning mt-3"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "ml-2 cp" }
const _hoisted_5 = {
  key: 0,
  class: "col-12 my-2"
}
const _hoisted_6 = {
  key: 0,
  class: "row px-2 py-2 align-items-center"
}
const _hoisted_7 = { class: "col-auto px-2" }
const _hoisted_8 = { class: "col-auto px-2" }
const _hoisted_9 = {
  key: 1,
  class: "row no-gutters align-items-center"
}
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = {
  key: 1,
  class: "col-auto"
}
const _hoisted_13 = { class: "col-auto ml-auto" }

import { DatePicker, InputAutoCompletion, SancareOcticon } from '@sancare/ui-frontend-commons'
import {
  useAutoCompletionDebouncedWatcher
} from '@sancare/ui-frontend-commons/src/misc/composables/useAutoCompletionDebouncedWatcher'
import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'
import { computed, reactive, ref } from 'vue'
import VueSlider from 'vue-slider-component'

import { FilterData } from '@/filters/types'
import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'VariableFilter',
  props: {
    filterData: {},
    isFastCondition: { type: Boolean, default: false }
  },
  emits: ['add-filter'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const props = __props
const emit = __emit

const currentStudy = computed(() => store.state.study.currentStudy)
const variableList = computed(() => store.state.variables.searchVariableResults)
const searchVariableRequest = computed(() => store.state.variables.searchVariableRequest)
const isDateFormat = computed(() => ['stayDate'].includes(variableAggConditionType.value))

const inputSearch = ref('')
const inputMode = ref('slider')
const variableId = ref('')
const variableName = ref('')
const variableAggConditionType = ref('')
const numberRange = reactive({ min: 0, max: 100, list: _.range(0, 101, 1) })
const numberValues = ref([numberRange.min, numberRange.max])
const earlyLimit = ref(dayjs())
const lateLimit = ref(dayjs())

const showCompletion = ref(false)
function openCompletion() {
  showCompletion.value = true
}
function closeCompletion() {
  showCompletion.value = false
}
function selectVariable(completionItem) {
  variableId.value = completionItem.id
  variableName.value = completionItem.name
  variableAggConditionType.value = completionItem.aggregateCondition.type
  closeCompletion()
}
function setEarlyLimit(updatedDate) {
  earlyLimit.value = updatedDate
}
function setLateLimit(updatedDate) {
  lateLimit.value = updatedDate
}

function addFilter() {
  const name = props.filterData.name
  const type = props.filterData.type
  const value = isDateFormat.value
    ? `${variableId.value}_${earlyLimit.value.valueOf()}_${lateLimit.value.valueOf()}`
    : `${variableId.value}_${numberValues.value[0]}_${numberValues.value[1]}`

  if (variableId.value !== '') {
    emit('add-filter', { name, type, value })
  }
}

function toggleInputMode() {
  inputMode.value = inputMode.value === 'slider' ? 'manual' : 'slider'
}

function fetchCompletionItems(search: string, signal: AbortSignal) {
  store.dispatch('searchVariable', {
    search,
    studyId: currentStudy.value.id,
    signal,
  })
}

function clearCompletionItems() {
  store.commit('clearSearchVariableResults')
}

useAutoCompletionDebouncedWatcher(inputSearch, variableList, fetchCompletionItems, clearCompletionItems, 2)

return (_ctx: any,_cache: any) => {
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return (currentStudy.value.id === null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Ce type de condition n'est pas disponible en dehors d'une étude "))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives((_openBlock(), _createElementBlock("div", {
            class: _normalizeClass({'col-auto': !_ctx.isFastCondition, 'col-12': _ctx.isFastCondition}),
            onKeydown: _withKeys(closeCompletion, ["esc"])
          }, [
            (variableId.value !== '')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("strong", null, _toDisplayString(variableName.value), 1),
                  _createElementVNode("span", _hoisted_4, [
                    _createVNode(_unref(SancareOcticon), {
                      name: "pencil",
                      width: 15,
                      height: 15,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (variableId.value = ''))
                    })
                  ])
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((inputSearch).value = $event)),
                    class: "form-control",
                    onClick: openCompletion
                  }, null, 512), [
                    [_vModelText, inputSearch.value]
                  ]),
                  _withDirectives(_createVNode(_unref(InputAutoCompletion), {
                    "completion-list": variableList.value,
                    "is-loading": searchVariableRequest.value.fetching,
                    "title-prop": 'name',
                    "description-prop": 'name',
                    onSelectItem: selectVariable
                  }, null, 8, ["completion-list", "is-loading"]), [
                    [_vShow, showCompletion.value && inputSearch.value !== '']
                  ])
                ], 64))
          ], 34)), [
            [_directive_on_click_outside, closeCompletion]
          ]),
          (variableId.value !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (isDateFormat.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-auto px-2" }, " Période du ", -1)),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_unref(DatePicker), {
                          "model-value": earlyLimit.value,
                          "onUpdate:modelValue": setEarlyLimit
                        }, null, 8, ["model-value"])
                      ]),
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-auto px-1" }, " au ", -1)),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_unref(DatePicker), {
                          "model-value": lateLimit.value,
                          "onUpdate:modelValue": setLateLimit
                        }, null, 8, ["model-value"])
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (inputMode.value === 'slider')
                        ? (_openBlock(), _createBlock(_unref(VueSlider), {
                            key: 0,
                            modelValue: numberValues.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((numberValues).value = $event)),
                            "v-data": numberRange.list,
                            tooltip: 'always',
                            range: ""
                          }, null, 8, ["modelValue", "v-data"]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-2 text-center" }, " de ", -1)),
                            _createElementVNode("div", _hoisted_10, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((numberValues.value[0]) = $event)),
                                type: "text",
                                class: "form-control"
                              }, null, 512), [
                                [_vModelText, numberValues.value[0]]
                              ])
                            ]),
                            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "col-2 text-center" }, " à ", -1)),
                            _createElementVNode("div", _hoisted_11, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((numberValues.value[1]) = $event)),
                                type: "text",
                                class: "col form-control"
                              }, null, 512), [
                                [_vModelText, numberValues.value[1]]
                              ])
                            ])
                          ]))
                    ], 64))
              ]))
            : _createCommentVNode("", true),
          (variableId.value !== '' && !isDateFormat.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("span", {
                  class: "btn btn-link",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (toggleInputMode()))
                }, _toDisplayString(inputMode.value === 'slider' ? 'Mode manuel' : 'Mode normal'), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_13, [
            (variableId.value !== '')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "col-auto btn btn-primary",
                  type: "button",
                  onClick: addFilter
                }, " Ok "))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
}
}

})