import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'

import { FilterData } from '@/filters/types'

export function initializeSubFiltersValues(filterData: FilterData): Record<string, string> {
  const subFiltersValues: Record<string, string> = {}
  if (!filterData.subFilters) {
    return subFiltersValues
  }
  filterData.subFilters.forEach((filter) => {
    subFiltersValues[filter.name] = filter.default ?? filter.options[0] ?? ''
  })
  return subFiltersValues
}

export function generateType(rawType: string, subFiltersValues: Record<string, string>): string {
  for (const subFilterName of Object.keys(subFiltersValues)) {
    rawType = rawType.replace(`{${subFilterName}}`, capitalize(subFiltersValues[subFilterName]))
  }
  return rawType.charAt(0).toLowerCase() + rawType.slice(1)
}
