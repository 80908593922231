import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "large-container" }

import { computed, onMounted, toRaw } from 'vue'

import { hasPermission, RoleEnum, studyPermissions } from '@/misc/permissions/permissionsHelper'
import { aggregatedVariableListColumns, variableListColumns } from '@/patient-list/PatientListColumns'
import router from '@/router'
import SearchEditionLayout from '@/search/SearchEditionLayout.vue'
import { useStore } from '@/store'
import { SearchTypeEnum } from '@/store/modules/search/types'
import { Variable } from '@/variables/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'VariableEditorPage',
  setup(__props) {

const store = useStore()

const variableId = computed(() => router.currentRoute.value.params.variableId && Number(router.currentRoute.value.params.variableId))
const studyId = computed(() => router.currentRoute.value.query.studyId && Number(router.currentRoute.value.query.studyId))
const stepId = computed(() => router.currentRoute.value.query.stepId && Number(router.currentRoute.value.query.stepId))

const addVariableRequest = computed(() => store.state.variables.addVariableRequest)
const editVariableRequest = computed(() => store.state.variables.editVariableRequest)
const fetchVariableRequest = computed(() => store.state.variables.fetchVariableRequest)

const backgroundJobsEnabled = computed(() => store.state.settings.sancareSettings.boolSettings.enable_compute_study_jobs.value)
const currentUser = computed(() => store.state.settings.currentUser)
const currentStudy = computed(() => store.state.study.currentStudy)
const currentSearch = computed(() => store.state.search.currentSearch)
const canShowPatientList = computed(() => studyId.value ? studyPermissions.canShowPatientList(currentStudy.value, currentUser.value) : hasPermission(RoleEnum.ROLE_STAY_READER, currentUser.value.role))

onMounted(() => {
  store.commit(
    'addBreadcrumbEntry',
    {
      name: `${variableId.value ? 'Édition' : 'Création'} de variable${studyId.value ? ' d\'étude' : ''}`,
      customKey: '/variable/',
      routerLinkArg: location.pathname + location.search
    },
  )
  if (!variableId.value) {
    store.commit('removeAggregateCondition')
    store.commit('setPatientListColumns', variableListColumns)
    store.commit('setDisplayedColumns', store.getters.getUserVariableColumnsSettings(false))
  }

  if (!studyId.value) {
    store.commit('resetStudy')
  }
})

async function upsertVariable(variable: Variable) {
  if (variableId.value) {
    store.dispatch('editVariable', variable)
    return
  }

  // we're creating a variable in the study on the fly
  const rawVariable = toRaw(variable)
  if (studyId.value) {
    await store.dispatch('studyAddVariable', { studyId: studyId.value, variable: rawVariable })
    router.replace({ name: 'studyVariable', params: { studyId: studyId.value } })
    return
  }
  // else we're in variable library
  await store.dispatch('addVariable', rawVariable)
  await store.dispatch('fetchVariables', { force: true })
  router.push({ name: 'variable', params: { variableId: currentSearch.value.id } })
}

function refreshPatients() {
  if (backgroundJobsEnabled.value && !studyId.value) {
    return
  }
  if (canShowPatientList.value) {
    store.dispatch('distributePatients', { stepId: stepId.value, variable: currentSearch.value })
  }
}

// load selected variable, then refresh patient list
async function fetchVariable() {
  if (variableId.value && !(currentSearch.value && currentSearch.value.id === variableId.value)) {
    await store.dispatch('fetchVariable', variableId.value)
    store.commit('setPatientListColumns', currentSearch.value.aggregateCondition ? aggregatedVariableListColumns : variableListColumns)
    store.commit('setVariableDisplayedColumns', { columns: store.getters.getUserVariableColumnsSettings(currentSearch.value.aggregateCondition), isAggregated: currentSearch.value.aggregateCondition })
  }
  refreshPatients()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SearchEditionLayout, {
      "can-show-patient-list": canShowPatientList.value,
      "add-request": addVariableRequest.value,
      "edit-request": editVariableRequest.value,
      "fetch-request": fetchVariableRequest.value,
      "search-type": _unref(SearchTypeEnum).VARIABLE,
      onUpsertSearch: upsertVariable,
      onFetchSearch: fetchVariable,
      onRefreshPatients: refreshPatients
    }, null, 8, ["can-show-patient-list", "add-request", "edit-request", "fetch-request", "search-type"])
  ]))
}
}

})