import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card-container py-3" }
const _hoisted_2 = { class: "annotation-justifications d-flex flex-column" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex justify-content-between pb-3 pl-4 pr-3"
}
const _hoisted_4 = {
  key: 1,
  class: "text-right"
}
const _hoisted_5 = {
  key: 1,
  class: "d-flex justify-content-end pb-3 pl-4 pr-3"
}

import { SancareButton, SancareCard, SancareChip, SancareInput } from '@sancare/ui-frontend-commons'
import { ButtonShapeVariant, ButtonVariant } from '@sancare/ui-frontend-commons/src/components/SancareButton.vue'
import { CardVariant } from '@sancare/ui-frontend-commons/src/components/SancareCard.vue'
import { ChipVariant } from '@sancare/ui-frontend-commons/src/components/SancareChip.vue'
import { ComponentSize } from '@sancare/ui-frontend-commons/src/components/types'
import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'
import { computed, Ref, ref } from 'vue'

import { useStore } from '@/store'
import { AnnotatedEntity, DocumentEntityExplorerValueType, SourceType } from '@/store/modules/document-entity-explorer/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentEntityAnnotation',
  props: {
    entity: {}
  },
  setup(__props: any) {

const props = __props

const store = useStore()
const currentDocument = computed(() => store.state.documentEntityExplorer.currentDocument)
const currentEntityId = computed(() => store.state.documentEntityExplorer.currentEntityId)

const isAddingAnnotation: Ref<boolean> = ref(false)
const newInputValue: Ref<string> = ref('')

const setCurrentEntity = (entityId: string) => {
  store.commit('documentEntityExplorer/setCurrentEntity', entityId)
}

function getChipEndIcon(isValidated: boolean, isRemoved: boolean): string {
  if (isValidated) return ''
  return isRemoved ? 'history' : 'x'
}

const getJustificationsFromAnnotation = (annotationValue: string) => {
  const annotation = currentDocument.value.entities
    .find((entity) => entity.entityId === currentEntityId.value)
    .annotations.find((annotation) => annotation.value === annotationValue)

  return annotation.hints.map((hint) => ({
    justification: currentDocument.value.documentContent.substring(hint.startIdx, hint.endIdx),
    removed: hint.removed ?? false,
    source: hint.source,
  }))
}

const removeHint = (hintIdx: number, annotationValue: string) => {
  store.commit('documentEntityExplorer/removeValue', {
    valueIndex: hintIdx,
    annotationValue,
    valueType: DocumentEntityExplorerValueType.HINT,
  })
}

const removeAnnotation = (annotationValue: string) => {
  store.commit('documentEntityExplorer/removeValue', {
    annotationValue,
    valueType: DocumentEntityExplorerValueType.ANNOTATION,
  })
}

const removePostProcessingValues = (annotationValue: string) => {
  store.commit('documentEntityExplorer/removeValue', {
    annotationValue,
    valueType: DocumentEntityExplorerValueType.POST_PROCESSING,
  })
}

const restoreHint = (hintIdx: number, annotationValue: string) => {
  store.commit('documentEntityExplorer/restoreValue', {
    valueIndex: hintIdx,
    annotationValue,
    valueType: DocumentEntityExplorerValueType.HINT,
  })
}

const restoreAnnotation = (annotationValue: string) => {
  store.commit('documentEntityExplorer/restoreValue', {
    annotationValue,
    valueType: DocumentEntityExplorerValueType.ANNOTATION,
  })
}

const restorePostProcessingValues = (annotationValue: string) => {
  store.commit('documentEntityExplorer/restoreValue', {
    annotationValue,
    valueType: DocumentEntityExplorerValueType.POST_PROCESSING,
  })
}

const resetInputValue = () => {
  newInputValue.value = ''
  isAddingAnnotation.value = false
}

const addUserAnnotation = (value: string) => {
  if (value.trim().length !== 0) {
    store.commit('documentEntityExplorer/addAnnotation', value)
  }
  resetInputValue()
}

const promptNewValue = () => {
  isAddingAnnotation.value = true
}

const validateEntity = () => {
  store.dispatch('documentEntityExplorer/upsertDocumentEntity', {
    ...props.entity,
    documentId: currentDocument.value.documentId,
    validated: true,
  })
}

const devalidateEntity = () => {
  resetInputValue()
  store.dispatch('documentEntityExplorer/upsertDocumentEntity', {
    ...props.entity,
    documentId: currentDocument.value.documentId,
    validated: false,
  })
}

const updateInputValue = (value: string) => {
  newInputValue.value = value
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SancareCard), {
    class: "p-0 m-0",
    title: _unref(capitalize)(_ctx.entity.entityName),
    "is-expanded": currentEntityId.value === _ctx.entity.entityId,
    variant: _ctx.entity.validated ? _unref(CardVariant).DISABLED : _unref(CardVariant).DEFAULT,
    onClick: _cache[1] || (_cache[1] = ($event: any) => (setCurrentEntity(_ctx.entity.entityId)))
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entity.annotations, (annotation) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: annotation.value
          }, [
            _createVNode(_unref(SancareChip), {
              icon: annotation.source === _unref(SourceType).AI ? 'dependabot' : '',
              "icon-end": getChipEndIcon(_ctx.entity.validated, annotation.removed),
              "is-clickable": false,
              variant: _unref(ChipVariant).UNDERLINED,
              text: annotation.value,
              class: "annotation-response align-self-start pl-0",
              "is-disabled": annotation.removed,
              onClickOnIcon: ($event: any) => (annotation.removed
              ? restoreAnnotation(annotation.value)
              : removeAnnotation(annotation.value)
            )
            }, null, 8, ["icon", "icon-end", "variant", "text", "is-disabled", "onClickOnIcon"]),
            _createElementVNode("div", _hoisted_2, [
              (annotation.postProcessingValues)
                ? (_openBlock(), _createBlock(_unref(SancareChip), {
                    key: 0,
                    label: "Processing: ",
                    class: "post-processing-values pl-0",
                    variant: _unref(ChipVariant).GHOST,
                    text: annotation.postProcessingValues.postProcessedValue
                + ' - ' + annotation.postProcessingValues.normalizedValue
              ,
                    "icon-end": getChipEndIcon(_ctx.entity.validated || annotation.removed, annotation.postProcessingValues.isRemoved),
                    "is-disabled": annotation.removed || annotation.postProcessingValues.isRemoved,
                    "is-clickable": false,
                    onClickOnIcon: ($event: any) => (annotation.postProcessingValues.isRemoved
                ? restorePostProcessingValues(annotation.value)
                : removePostProcessingValues(annotation.value)
              )
                  }, null, 8, ["variant", "text", "icon-end", "is-disabled", "onClickOnIcon"]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getJustificationsFromAnnotation(annotation.value), (item, idx) => {
                return (_openBlock(), _createBlock(_unref(SancareChip), {
                  key: item.justification,
                  "is-clickable": false,
                  text: item.justification,
                  class: "justification",
                  "is-disabled": item.removed || annotation.removed,
                  icon: item.source === _unref(SourceType).AI ? 'dependabot' : '',
                  "icon-end": getChipEndIcon(_ctx.entity.validated || annotation.removed, item.removed),
                  "is-removable": !_ctx.entity.validated,
                  onClickOnIcon: ($event: any) => (item.removed
                ? restoreHint(idx, annotation.value)
                : removeHint(idx, annotation.value)
              )
                }, null, 8, ["text", "is-disabled", "icon", "icon-end", "is-removable", "onClickOnIcon"]))
              }), 128))
            ])
          ], 64))
        }), 128))
      ])
    ]),
    footer: _withCtx(() => [
      (!_ctx.entity.validated)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (!isAddingAnnotation.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_unref(SancareChip), {
                    "is-clickable": true,
                    text: "+ Ajouter une valeur",
                    variant: _unref(ChipVariant).UNDERLINED,
                    class: "add-annotation-value",
                    onClick: promptNewValue
                  }, null, 8, ["variant"]),
                  _createVNode(_unref(SancareButton), {
                    text: "Valider l'entité",
                    variant: _unref(ButtonVariant).OUTLINED,
                    size: _unref(ComponentSize).MEDIUM,
                    "shape-variant": _unref(ButtonShapeVariant).MEDIUM,
                    onClick: validateEntity
                  }, null, 8, ["variant", "size", "shape-variant"])
                ], 64))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_unref(SancareInput), {
                    "model-value": newInputValue.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => updateInputValue(value)),
                    onClose: resetInputValue,
                    onSubmit: addUserAnnotation
                  }, null, 8, ["model-value"])
                ]))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_unref(SancareButton), {
              text: "Modifier l'entité",
              variant: _unref(ButtonVariant).OUTLINED,
              "shape-variant": _unref(ButtonShapeVariant).MEDIUM,
              onClick: devalidateEntity
            }, null, 8, ["variant", "shape-variant"])
          ]))
    ]),
    _: 1
  }, 8, ["title", "is-expanded", "variant"]))
}
}

})