import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "settings-buttons row" }

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { computed } from 'vue'

import { hasPermission, ProfileEnum, RoleEnum } from '@/misc/permissions/permissionsHelper'
import { useRouter } from '@/router'
import { useStore } from '@/store'

import BugReport from './BugReport.vue'
import UserSettings from './UserSettings.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SancareSettings',
  props: {
    userRole: {}
  },
  setup(__props: any) {

const router = useRouter()
const store = useStore()


const backgroundJobsEnabled = computed(() => store.state.settings.sancareSettings.boolSettings.enable_compute_study_jobs.value)

async function logout() {
  await store.dispatch('logout')
  router.push({ path: '/login' })
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(BugReport),
      (backgroundJobsEnabled.value)
        ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: { name: 'jobs' },
            class: "px-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(SancareOcticon), {
                name: "browser",
                width: 25,
                height: 25
              })
            ]),
            _: 1
          })), [
            [_directive_tooltip, 'Liste des tâches']
          ])
        : _createCommentVNode("", true),
      (_unref(hasPermission)(_unref(RoleEnum).ROLE_USER_MANAGER, _ctx.userRole) || _unref(hasPermission)(_unref(RoleEnum).ROLE_ADMIN_MANAGER, _ctx.userRole) || _unref(hasPermission)(_unref(RoleEnum).ROLE_SETTING_UPDATER, _ctx.userRole))
        ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: "/admin",
            class: "px-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(SancareOcticon), {
                name: "tools",
                width: 25,
                height: 25
              })
            ]),
            _: 1
          })), [
            [_directive_tooltip, 'Zone admin']
          ])
        : _createCommentVNode("", true),
      _createVNode(UserSettings),
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "px-2",
        onClick: logout
      }, [
        _createVNode(_unref(SancareOcticon), {
          name: "sign-out",
          width: 25,
          height: 25
        })
      ])), [
        [_directive_tooltip, 'Déconnexion']
      ])
    ])
  ]))
}
}

})