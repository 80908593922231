import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref } from "vue"
import _imports_0 from '../assets/sancare_logo.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "stay-page-error-message mt-3 mx-auto text-center position-relative" }

import { SancareOcticon } from '@sancare/ui-frontend-commons'


export default /*@__PURE__*/_defineComponent({
  __name: 'NotFoundComponent',
  setup(__props) {

const images = ['paper-airplane', 'squirrel', 'globe', 'telescope']

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", {
      id: "title404",
      class: "text-center mt-5"
    }, [
      _createTextVNode(" Erreur 4"),
      _createElementVNode("img", {
        alt: "Logo Sancare",
        src: _imports_0
      }),
      _createTextVNode("4 ")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "pt-5" }, " La page est introuvable ", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, " La page que vous recherchez n'existe pas. ", -1)),
      _createVNode(_component_router_link, { to: {name: 'home'} }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Retour à l'accueil ")
        ])),
        _: 1
      }),
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(images, (img) => {
        return _createElementVNode("div", {
          key: img,
          class: "position-absolute"
        }, [
          _createVNode(_unref(SancareOcticon), {
            name: img,
            width: 60,
            height: 60
          }, null, 8, ["name"])
        ])
      }), 64))
    ])
  ]))
}
}

})