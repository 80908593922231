import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "home-list-part" }
const _hoisted_3 = { class: "row no-gutters" }
const _hoisted_4 = { class: "col home-list-part-title" }
const _hoisted_5 = { class: "row no-gutters search-inset" }

import { LocationQueryRaw } from 'vue-router'
import { useStore } from 'vuex'

import useRedirection from '../misc/composables/redirection'
import HomeThumbnail from './HomeThumbnail.vue'

interface SearchButton {
  label: string
  addPath: string
  bgClass: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchListManager',
  props: {
    title: {},
    buttonKey: {},
    buttons: {}
  },
  setup(__props: any) {



const store = useStore()
const { redirect } = useRedirection()

function handleRedirect(link: string, query: LocationQueryRaw) {
  if (link.includes('new')) {
    store.commit('resetNewSearch')
  }

  redirect(link, query)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, " Gestion des " + _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: _ctx.buttonKey+'_'+idx,
            class: "col-3 p-2 fixed-thumbnail"
          }, [
            _createVNode(HomeThumbnail, {
              label: button.label,
              class: _normalizeClass(button.bgClass),
              "header-item": "+",
              onClick: () => handleRedirect(button.addPath, {})
            }, null, 8, ["label", "class", "onClick"])
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})