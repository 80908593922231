import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "highlight-on-click" }
const _hoisted_2 = {
  key: 0,
  class: "report-content"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-data-content" }

import { SancareSelect } from '@sancare/ui-frontend-commons'
import { computed, Ref, ref } from 'vue'

import { useStore } from '@/store'
import { AnnotatedChunk, Hint, SourceType } from '@/store/modules/document-entity-explorer/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentVisualizer',
  props: {
    chunks: {}
  },
  setup(__props: any) {

const store = useStore()


const isFloatingSelectShowed = ref(false)
const floatingPosition = ref({ top: 0, left: 0 })
const selectedIndices: Ref<Hint[]> = ref([])

const currentDocument = computed(() => store.state.documentEntityExplorer.currentDocument)
const currentEntityId = computed(() => store.state.documentEntityExplorer.currentEntityId)
const currentEntity = computed(() =>
  currentDocument.value.entities.find((entity) => entity.entityId === currentEntityId.value)
)
const currentEntityAnnotationValues = computed(() =>
  currentEntity.value.annotations.filter((annotation) => !annotation.removed).map((annotation) => annotation.value)
)

const handleTextHighlighting = (event: MouseEvent) => {
  const selection = window.getSelection()
  const selectedText = selection.toString().replace(/\n+$/, '').trim()
  if (!selectedText || currentEntity.value.validated) {
    isFloatingSelectShowed.value = false
    return
  }

  floatingPosition.value = {
    top: event.pageY + 5,
    left: event.pageX
  }

  const documentContent = currentDocument.value.documentContent
  const indices: Hint[] = []
  let startIdx = 0
  while(startIdx < documentContent.length) {
    const hintStart = documentContent.indexOf(selectedText, startIdx)
    if (hintStart === -1) break
    const hintEnd = hintStart + selectedText.length
    indices.push({ id: null, startIdx: hintStart, endIdx: hintEnd, source: SourceType.USER, removed: false })
    startIdx = hintEnd
  }
  selectedIndices.value = indices
  isFloatingSelectShowed.value = true
}

const handleAnnotationSubmit = (selectedValue: string) => {
  store.commit('documentEntityExplorer/addHints', { annotationToEdit: selectedValue, newHints: selectedIndices.value })
  selectedIndices.value = []
  isFloatingSelectShowed.value = false
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "document-content",
      onMouseup: handleTextHighlighting
    }, [
      (_ctx.chunks)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chunks, (chunk) => {
              return (_openBlock(), _createElementBlock("span", {
                key: chunk.hash,
                class: _normalizeClass([{ 'chunk-justification': chunk.isHighlighted }, "text-data-content"])
              }, _toDisplayString(chunk.string), 3))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(currentDocument.value.documentContent), 1)
          ]))
    ], 32),
    (isFloatingSelectShowed.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "floating-select-container",
          style: _normalizeStyle({
        position: 'absolute',
        top: `${floatingPosition.value.top}px`,
        left: `${floatingPosition.value.left}px`,
        zIndex: 1000
      })
        }, [
          _createVNode(_unref(SancareSelect), {
            "is-floating": true,
            "has-validation-button": true,
            label: "Annotation associée",
            placeholder: "Choisir...",
            values: currentEntityAnnotationValues.value,
            onValidate: handleAnnotationSubmit
          }, null, 8, ["values"])
        ], 4))
      : _createCommentVNode("", true)
  ]))
}
}

})