import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "large-container row"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "ml-1"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 0,
  class: "col-auto ml-auto"
}
const _hoisted_6 = {
  key: 1,
  class: "d-inline-block"
}
const _hoisted_7 = {
  key: 2,
  class: "d-inline-block"
}
const _hoisted_8 = {
  key: 3,
  class: "m-2"
}
const _hoisted_9 = {
  key: 5,
  class: "d-inline-block"
}

import { computed } from 'vue'

import router from '@/router'
import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'SancareBreadcrumb',
  setup(__props) {

const store = useStore()
const defaultBreadcrumb = () => {
  return { show: false, list: [] }
}
const breadcrumbStore = computed(() => {
  return store.state.home.breadcrumb && store.state.home.breadcrumb.list ? store.state.home.breadcrumb : defaultBreadcrumb()
})
const readingList = computed(() => store.state.patientList.readingList)
const updateReadingListRequest = computed(() => store.state.patientList.updateReadingListRequest)
const getOnePatientRequest = computed(() => store.state.patient.requests.getOnePatient)

const isReadingListReady = computed(() => !updateReadingListRequest.value.fetching && !getOnePatientRequest.value.fetching)

async function updateReadingListIndex(newIndex: number) {
  if (isReadingListReady.value) {
    await store.dispatch('navigateReadingList', newIndex)
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (breadcrumbStore.value && breadcrumbStore.value.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadcrumbStore.value.list, (breadcrumb, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "col-auto px-0"
          }, [
            (breadcrumb.routerLinkArg)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (index > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, " > "))
                    : _createCommentVNode("", true),
                  (breadcrumbStore.value.currentKey !== breadcrumb.key)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        to: breadcrumb.routerLinkArg
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(breadcrumb.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(breadcrumb.name), 1))
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128)),
        (_unref(router).currentRoute.value.name === 'patient' && readingList.value.status)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (readingList.value.previous && isReadingListReady.value)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "d-inline-block",
                    to: { name: 'patient', params: {patientIpp: readingList.value.previous}, query: _unref(router).currentRoute.value.query },
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (updateReadingListIndex(readingList.value.currentIndex-1)))
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" ←  Précédent ")
                    ])),
                    _: 1
                  }, 8, ["to"]))
                : (readingList.value.previous)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, " ←  Précédent "))
                  : _createCommentVNode("", true),
              (!isReadingListReady.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[3] || (_cache[3] = [
                    _createElementVNode("div", { class: "loader loader-sm my-0 mx-4" }, null, -1)
                  ])))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, "Patient " + _toDisplayString(readingList.value.currentIndex+1) + " / " + _toDisplayString(readingList.value.readingListCount), 1)),
              (readingList.value.next && isReadingListReady.value)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 4,
                    class: "d-inline-block",
                    to: { name: 'patient', params: {patientIpp: readingList.value.next}, query: _unref(router).currentRoute.value.query },
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (updateReadingListIndex(readingList.value.currentIndex+1)))
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" Suivant  → ")
                    ])),
                    _: 1
                  }, 8, ["to"]))
                : (readingList.value.next)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Suivant  → "))
                  : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})