import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, createVNode as _createVNode, resolveDirective as _resolveDirective, withKeys as _withKeys, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "row gap-1" }
const _hoisted_2 = {
  key: 0,
  class: "input-group col-auto row no-gutters"
}
const _hoisted_3 = ["onUpdate:modelValue", "name"]
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 1,
  class: "input-group col-auto row no-gutters mt-2"
}
const _hoisted_6 = { class: "col-12 autocompletion-container" }
const _hoisted_7 = { class: "input-group col-auto row no-gutters" }
const _hoisted_8 = ["value"]
const _hoisted_9 = {
  key: 2,
  class: "input-group col-auto row no-gutters"
}
const _hoisted_10 = { class: "pl-5" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "form-check" }
const _hoisted_13 = { class: "form-check" }
const _hoisted_14 = { class: "form-check" }
const _hoisted_15 = { class: "form-check" }
const _hoisted_16 = { class: "form-check" }

import { InputAutoCompletion } from '@sancare/ui-frontend-commons'
import {
  useAutoCompletionDebouncedWatcher
} from '@sancare/ui-frontend-commons/src/misc/composables/useAutoCompletionDebouncedWatcher'
import _ from 'lodash'
import { computed, onMounted, reactive, ref, watch } from 'vue'

import { FilterData, FilterEvent } from '@/filters/types'
import { generateType, initializeSubFiltersValues } from '@/filters/utils'
import { documentCategories, textualHealthDataCategories } from '@/stay-displayer/health-data/documentLabels'
import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'AdvancedTextContentCriteria',
  props: {
    filterData: {},
    isGlobalCondition: { type: Boolean, default: false }
  },
  emits: ["add-filter"],
  setup(__props: any, { emit: __emit }) {

const store = useStore()

const emit = __emit

const props = __props

const presence = ref(props.isGlobalCondition ? 'absent' : 'present')
const extendedSearch = ref(false)
const includeAntecedents = ref(false)
const includeFamilyAntecedents = ref(false)
const includeNegations = ref(false)
const hardAbsentContent = ref(false)
const specifyWordDistance = ref(false)
const specifyDocumentCategory = ref(false)
const value = ref('')
const wordDistance = ref(0)
const documentCategory = ref(null)
const textualReportCategories = { ...documentCategories, ...textualHealthDataCategories }
const subFiltersValues = reactive(initializeSubFiltersValues(props.filterData))
const inputNamedEntity = ref('')
const showCompletion = ref(false)
const selectedNamedEntity = ref('')

const completionItems = computed(() => store.state.medicalUnitFilter.completionItems)
const completionRequest = computed(() => store.state.medicalUnitFilter.completionRequest)
const hasNamedEntitySubFilter = computed(() => props.filterData.completionSubFilter)
const showTextInput = computed(() => !hasNamedEntitySubFilter.value || (hasNamedEntitySubFilter.value && selectedNamedEntity.value !== ''))

watch(presence, () => {
  resetModifiers()
})

onMounted(() => {
  resetModifiers()
  useAutoCompletionDebouncedWatcher(
    inputNamedEntity,
    completionItems,
    (search, signal) => fetchCompletionItems(search, signal),
    () => clearCompletionItems()
  )
})

function resetModifiers() {
  includeAntecedents.value = false
  includeFamilyAntecedents.value = false
  includeNegations.value = false
  hardAbsentContent.value = !props.isGlobalCondition
  specifyWordDistance.value = false
  specifyDocumentCategory.value = false
  wordDistance.value = 0
  documentCategory.value = null
}
function addFilter() {
  let filterValue = value.value.trim()
  if (!filterValue) {
    return
  }
  hardAbsentContent.value = !props.isGlobalCondition
  const modifiers = []
  if (includeAntecedents.value && (presence.value === 'present' || hardAbsentContent.value)) {
    modifiers.push('a')
  }
  if (includeFamilyAntecedents.value && (presence.value === 'present' || hardAbsentContent.value)) {
    modifiers.push('f')
  }
  if (includeNegations.value && (presence.value === 'present' || hardAbsentContent.value)) {
    modifiers.push('n')
  }
  if (specifyWordDistance.value) {
    modifiers.push(`wd${wordDistance.value}`)
  }

  // We build filterValue. If there are modifiers, they are put before a "Unit separator" (0x1F) character
  if (modifiers.length && (presence.value === 'present' || hardAbsentContent.value)) {
    filterValue = `${modifiers.join('_')}\x1F${filterValue}`
  }
  if (hasNamedEntitySubFilter.value) {
    filterValue = `${selectedNamedEntity.value}\x1F${filterValue}`
  }

  if (props.isGlobalCondition) {
    subFiltersValues['presence'] = 'softAbsent'
  }
  let type = generateType(props.filterData.format, subFiltersValues)
  if (specifyDocumentCategory.value) {
    type += `__${documentCategory.value}`
  }

  emit('add-filter', {
    name: props.filterData.name,
    type: type,
    value: filterValue,
  })
}
function openCompletion() {
  showCompletion.value = true
}
function closeCompletion() {
  showCompletion.value = false
}
function selectNamedEntity(completionItem) {
  selectedNamedEntity.value = completionItem.named_entity
  inputNamedEntity.value = selectedNamedEntity.value
  closeCompletion()
}
function fetchCompletionItems(search, signal) {
  store.dispatch('medicalUnitFilter/getCompletionItems', {
    url: '/api/stay-criterion-condition/completion',
    urlParams: {
      type: props.filterData.name,
      search,
    },
    signal,
  })
}
function clearCompletionItems() {
  store.commit('medicalUnitFilter/clearCompletionItems')
  inputNamedEntity.value = ''
}

return (_ctx: any,_cache: any) => {
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.isGlobalCondition && _ctx.filterData.subFilters && _ctx.filterData.subFilters.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterData.subFilters, (filter) => {
              return _withDirectives((_openBlock(), _createElementBlock("select", {
                key: filter.name,
                "onUpdate:modelValue": ($event: any) => ((subFiltersValues[filter.name]) = $event),
                name: 'textContentCriteria_'+filter.name,
                class: "custom-select form-control"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.options, (opt) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: opt,
                    value: opt
                  }, _toDisplayString(filter.labels[opt] ?? opt), 9, _hoisted_4))
                }), 128))
              ], 8, _hoisted_3)), [
                [_vModelSelect, subFiltersValues[filter.name]]
              ])
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (hasNamedEntitySubFilter.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputNamedEntity).value = $event)),
                placeholder: "Entité nommée",
                class: "form-control",
                onClick: openCompletion
              }, null, 512), [
                [_vModelText, inputNamedEntity.value]
              ]),
              _withDirectives(_createVNode(_unref(InputAutoCompletion), {
                "completion-list": completionItems.value,
                "is-loading": completionRequest.value.fetching,
                "title-prop": 'named_entity',
                "description-prop": 'named_entity',
                onSelectItem: selectNamedEntity
              }, null, 8, ["completion-list", "is-loading"]), [
                [_vShow, showCompletion.value && inputNamedEntity.value !== '']
              ])
            ])), [
              [_directive_on_click_outside, closeCompletion]
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        (specifyDocumentCategory.value)
          ? _withDirectives((_openBlock(), _createElementBlock("select", {
              key: 0,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((documentCategory).value = $event)),
              class: "custom-select"
            }, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(textualReportCategories, (docCategory, idx) => {
                return _createElementVNode("option", {
                  key: idx,
                  value: idx
                }, _toDisplayString(docCategory), 9, _hoisted_8)
              }), 64))
            ], 512)), [
              [_vModelSelect, documentCategory.value]
            ])
          : _createCommentVNode("", true)
      ]),
      (showTextInput.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((value).value = $event)),
              name: "textContentCriteria_input",
              class: "form-control",
              onKeyup: _withKeys(addFilter, ["enter"])
            }, null, 544), [
              [_vModelText, value.value]
            ]),
            (specifyWordDistance.value)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((wordDistance).value = $event)),
                  placeholder: "Recherche textuelle",
                  class: "form-control col-1 text-center"
                }, null, 512)), [
                  [_vModelText, wordDistance.value]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("div", { class: "input-group-append" }, [
              _createElementVNode("button", {
                name: "textContentCriteria_ok",
                class: "col-auto btn btn-primary",
                type: "button",
                onClick: addFilter
              }, " Ok ")
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.filterData.markedIndicesOptionsEnabled && extendedSearch.value && (presence.value === 'present' || hardAbsentContent.value))
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _withDirectives(_createElementVNode("input", {
                  id: "include-antecedent-checkbox",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((includeAntecedents).value = $event)),
                  type: "checkbox",
                  class: "form-check-input"
                }, null, 512), [
                  [_vModelCheckbox, includeAntecedents.value]
                ]),
                _cache[10] || (_cache[10] = _createElementVNode("label", {
                  for: "include-antecedent-checkbox",
                  class: "form-check-label"
                }, " Inclure les antécédents ", -1))
              ]),
              _createElementVNode("div", _hoisted_13, [
                _withDirectives(_createElementVNode("input", {
                  id: "include-family-antecedent-checkbox",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((includeFamilyAntecedents).value = $event)),
                  type: "checkbox",
                  class: "form-check-input"
                }, null, 512), [
                  [_vModelCheckbox, includeFamilyAntecedents.value]
                ]),
                _cache[11] || (_cache[11] = _createElementVNode("label", {
                  for: "include-family-antecedent-checkbox",
                  class: "form-check-label"
                }, " Inclure les antécédents familiaux ", -1))
              ]),
              _createElementVNode("div", _hoisted_14, [
                _withDirectives(_createElementVNode("input", {
                  id: "include-negation-checkbox",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((includeNegations).value = $event)),
                  type: "checkbox",
                  class: "form-check-input"
                }, null, 512), [
                  [_vModelCheckbox, includeNegations.value]
                ]),
                _cache[12] || (_cache[12] = _createElementVNode("label", {
                  for: "include-negation-checkbox",
                  class: "form-check-label"
                }, " Inclure les négations ", -1))
              ]),
              _createElementVNode("div", _hoisted_15, [
                _withDirectives(_createElementVNode("input", {
                  id: "include-word-distance-checkbox",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((specifyWordDistance).value = $event)),
                  type: "checkbox",
                  class: "form-check-input"
                }, null, 512), [
                  [_vModelCheckbox, specifyWordDistance.value]
                ]),
                _cache[13] || (_cache[13] = _createElementVNode("label", {
                  for: "include-word-distance-checkbox",
                  class: "form-check-label"
                }, " Spécifier l'intervalle entre les mots ", -1))
              ]),
              _createElementVNode("div", _hoisted_16, [
                _withDirectives(_createElementVNode("input", {
                  id: "document-category-checkbox",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((specifyDocumentCategory).value = $event)),
                  type: "checkbox",
                  class: "form-check-input"
                }, null, 512), [
                  [_vModelCheckbox, specifyDocumentCategory.value]
                ]),
                _cache[14] || (_cache[14] = _createElementVNode("label", {
                  for: "document-category-checkbox",
                  class: "form-check-label"
                }, " Spécifier la catégorie de document ", -1))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.filterData.markedIndicesOptionsEnabled && extendedSearch.value === false && (presence.value === 'present' || hardAbsentContent.value))
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-link",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (extendedSearch.value = true))
            }, _cache[15] || (_cache[15] = [
              _createElementVNode("small", null, "Plus d'options…", -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})