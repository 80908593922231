<script>
import Slider from 'vue-slider-component'

import { generateType, initializeSubFiltersValues } from '@/filters/utils'

export default {
  components: {
    'vue-slider': Slider
  },
  props: {
    filterData: { type: Object, required: true },
  },
  emits: ['add-filter'],
  data() {
    const params = this.filterData.range
    const min = params.less ? `<${params.min}` : params.min
    const max = params.more ? `>${params.max}` : params.max

    return {
      value: [min, max],
      subFiltersValues: initializeSubFiltersValues(this.filterData),
    }
  },
  computed: {
    rangeList() {
      const params = this.filterData.range
      const list = []
      let value = params.min

      if (params.less) {
        list.push(`<${params.min}`)
      }
      while (value <= params.max) {
        list.push(value)
        value += params.interval
      }
      if (params.more) {
        list.push(`>${params.max}`)
      }

      return (list)
    },
    rangeFormat() {
      return this.filterData.range.unit ? `{value}${this.filterData.range.unit}` : '{value}'
    }
  },
  watch: {
    'filterData.name': function() {
      const params = this.filterData.range
      const min = params.less ? `<${params.min}` : params.min
      const max = params.more ? `>${params.max}` : params.max

      this.resetRange(min, max)
    },
  },
  methods: {
    setRange() {
      const name = this.filterData.name
      const params = this.filterData.range
      const min = params.less ? `<${params.min}` : params.min
      const max = params.more ? `>${params.max}` : params.max
      const type = generateType(this.filterData.format, this.subFiltersValues)

      let value = this.value
      if (value.length && (value[0] !== min || value[1] !== max)) {
        value = `${value[0]}_${value[1]}`
        this.$emit('add-filter', { name, type, value })
      }
    },
    resetRange(min, max) {
      this.value = [min, max]
    }
  },
}
</script>

<template>
  <div class="row align-items-center">
    <div class="col">
      <vue-slider
        :key="filterData.name"
        v-model="value"
        :v-data="rangeList"
        :formatter="rangeFormat"
        :tooltip="'always'"
        range
        class="ml-3"
      />
    </div>
    <div class="col-auto">
      <button
        class="btn btn-primary"
        type="button"
        @click="setRange"
      >
        Ok
      </button>
    </div>
  </div>
</template>
