import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card p-0 mt-3" }

import { SancareToggle } from '@sancare/ui-frontend-commons'
import { onMounted, Ref, ref, watch } from 'vue'

import AdvancedFilterBuilder from '@/filters/AdvancedFilterBuilder.vue'
import ConditionDisplayer from '@/filters/ConditionDisplayer.vue'
import { AggregateTypeEnum } from '@/filters/types'
import { Condition } from '@/store/modules/search/types'
import { AggregationModifiers } from '@/variables/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'AggregateCondition',
  props: {
    condition: { default: null },
    isFastCondition: { type: Boolean },
    modifiers: { default: null }
  },
  emits: ['add-condition'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit

const props = __props

const showAggregateCondition = ref(false)
const conditionBackup = ref(null) as Ref<Condition>
const modifiersBackup = ref(null) as Ref<AggregationModifiers>

function setShowAggregateCondition(value: boolean) {
  if (!value) {
    if (props.condition) {
      conditionBackup.value = { ...props.condition }
    }
    if (props.modifiers) {
      modifiersBackup.value = { ...props.modifiers }
    }
    removeAggregatedCondition()
  }
  if (value && conditionBackup.value) {
    setAggregateCondition({
      type: conditionBackup.value.type,
      value: conditionBackup.value.value,
      aggregation: conditionBackup.value.aggregation,
      aggregationModifiers: modifiersBackup.value,
    })
  }
  showAggregateCondition.value = value
}
function setAggregateCondition({ type, value, aggregation, aggregationModifiers }: { type: string, value: string, aggregation: AggregateTypeEnum, aggregationModifiers: AggregationModifiers }) {
  emit('add-condition', { id: null, type, value, aggregation }, aggregationModifiers)
}
function removeAggregatedCondition() {
  emit('add-condition', null, null)
}
function refreshShowAggregateCondition() {
  if (props.condition) {
    showAggregateCondition.value = true
  }
}

watch(() => props.condition, () => {
  refreshShowAggregateCondition()
})
onMounted(() => {
  refreshShowAggregateCondition()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(SancareToggle), {
        class: "card-body offset-3 col-6 p-2 mt-2",
        label: 'Activer la fonction d\'agrégat',
        value: showAggregateCondition.value,
        "label-position": 'right',
        onToggleChange: setShowAggregateCondition
      }, null, 8, ["value"]),
      (_ctx.condition)
        ? (_openBlock(), _createBlock(ConditionDisplayer, {
            key: 0,
            class: "card-body border-top p-3 mt-2",
            "can-remove-condition": true,
            condition: _ctx.condition,
            modifiers: _ctx.modifiers,
            onRemoveCondition: removeAggregatedCondition
          }, null, 8, ["condition", "modifiers"]))
        : _createCommentVNode("", true),
      (showAggregateCondition.value)
        ? (_openBlock(), _createBlock(AdvancedFilterBuilder, {
            key: 1,
            class: "card-footer p-3",
            "with-aggregation": true,
            onAddCondition: setAggregateCondition
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})