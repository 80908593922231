import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'

import { DocumentEntityExplorerState } from './types'

const state = new DocumentEntityExplorerState()
const baseUrl = '/api/llm-annotation'

state.requests = {
  fetchDocuments: new ApiRequest('fetchDocuments', 'GET', `${baseUrl}/`),
  upsertDocumentEntity: new ApiRequest('upsertDocumentEntity', 'POST', `${baseUrl}/`),
}

state.currentDocument = null
state.nbDocuments = 10
state.currentEntityId = '1' // will be inferred from the first entity

export { state }
