import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["onClick", "onMouseup"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]

import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'
import { computed, onMounted, ref } from 'vue'

import filtersData from '@/filters/FiltersData'
import { HTMLElementEvent } from '@/misc/types'
import router from '@/router'
import { useStore } from '@/store'
import { Patient, PatientListColumn, StudyPatientFeedbackEnum } from '@/store/modules/patient/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientListLine',
  props: {
    patient: {},
    columns: {},
    selected: { type: Boolean }
  },
  emits: ['toggle-selected-patient', 'goto-patient-page'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const emit = __emit
const props = __props

const feedback = ref(null)
const studyId = computed(() => router.currentRoute.value.query.studyId)
const currentUser = computed(() => store.state.settings.currentUser)

onMounted(() => {
  const studyUserFeedback = _.find(props.patient.feedbacks, {  user: { id: currentUser.value.id }, study: { id: _.toNumber(studyId.value) } })
  feedback.value = studyUserFeedback ? studyUserFeedback.feedback : null
})

function viewPatient(event: HTMLElementEvent<HTMLElement>, forceNewWindow?: boolean) {
  const patientListParams = router.currentRoute.value.params
  if (router.currentRoute.value.name === 'criterionNew') {
    patientListParams.criterionId = 'new'
  }

  if (router.currentRoute.value.name === 'variableNew') {
    patientListParams.variableId = 'new'
  }

  if (router.currentRoute.value.name === 'studyNodeNew') {
    patientListParams.studyNodeId = 'new'
  }
  store.commit('patient/setPatientListParams', router.currentRoute.value.params)
  const newRoute = {
    name : 'patient',
    params: { patientIpp: props.patient.ipp },
    query: router.currentRoute.value.query,
  }

  emit('goto-patient-page',
    forceNewWindow || event.ctrlKey || event.metaKey || event.shiftKey,
    newRoute
  )
}

function viewColumn(columnName: string): string {
  if (columnName === 'genderLabel') {
    return filtersData.gender.labels[props.patient['gender']] ?? filtersData.gender.labels['3']
  }
  if (columnName === 'deceaseDate' && props.patient['deceaseDate']) {
    return dayjs(props.patient['deceaseDate']).format('DD/MM/YYYY')
  }
  if (typeof props.patient[columnName] === 'boolean') {
    return props.patient[columnName] === true ? 'OUI' : 'NON'
  }

  return props.patient[columnName]
}

function setPatientStudyFeedback() {
  store.dispatch('setPatientStudyFeedback', { patientIpp: props.patient.ipp, studyId: studyId.value, feedback: feedback.value })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", {
    name: "patientList_line",
    class: "patient-list-line text-center align-items-center",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (viewPatient($event))),
    onMouseup: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (viewPatient($event, true)), ["middle"]))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
      return (_openBlock(), _createElementBlock("td", {
        key: 'patientCol_'+column.name+'_'+_ctx.patient.ipp,
        onClick: (e) => { if(column.name === 'feedback') e.stopPropagation() },
        onMouseup: _withModifiers((e) => { if(column.name === 'feedback') e.stopPropagation() }, ["middle"])
      }, [
        (column.name === 'feedback'
          && typeof studyId.value !== 'undefined'
          && studyId.value !== null)
          ? _withDirectives((_openBlock(), _createElementBlock("select", {
              key: 0,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((feedback).value = $event)),
              class: "custom-select",
              onChange: setPatientStudyFeedback
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("option", { value: null }, " À définir ", -1)),
              _createElementVNode("option", {
                value: _unref(StudyPatientFeedbackEnum).RELEVANT
              }, " Pertinent dans l'étude ", 8, _hoisted_2),
              _createElementVNode("option", {
                value: _unref(StudyPatientFeedbackEnum).IRRELEVANT
              }, " Non pertinent dans l'étude ", 8, _hoisted_3),
              _createElementVNode("option", {
                value: _unref(StudyPatientFeedbackEnum).UNDECIDED
              }, " Ne se prononce pas ", 8, _hoisted_4)
            ], 544)), [
              [_vModelSelect, feedback.value]
            ])
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(viewColumn(column.name)), 1)
            ], 64))
      ], 40, _hoisted_1))
    }), 128))
  ], 32))
}
}

})