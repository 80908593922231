import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "admin-page" }
const _hoisted_2 = { class: "large-container" }
const _hoisted_3 = { class: "row no-gutters nav nav-tabs" }
const _hoisted_4 = {
  key: 0,
  class: "col nav-item text-center home-list-tab"
}
const _hoisted_5 = {
  key: 1,
  class: "col nav-item text-center home-list-tab"
}
const _hoisted_6 = {
  key: 2,
  class: "col nav-item text-center home-list-tab"
}
const _hoisted_7 = { class: "home-list" }

import { computed, onMounted, ref } from 'vue'

import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import { useStore } from '@/store'

import AppSettingsManager from './AppSettingsManager.vue'
import UserManager from './UserManager.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminPage',
  setup(__props) {

const store = useStore()
const tab = ref('userManager')

const canManageUsers = computed(() => hasPermission(RoleEnum.ROLE_USER_MANAGER, store.state.login.userRole) || hasPermission(RoleEnum.ROLE_ADMIN_MANAGER, store.state.login.userRole))
const canManageSettings = computed(() => hasPermission(RoleEnum.ROLE_SETTING_UPDATER, store.state.login.userRole))
const canManageSancareSettings = computed(() => hasPermission(RoleEnum.ROLE_SANCARE_SETTING_UPDATER, store.state.login.userRole))

onMounted(() => {
  if (canManageUsers.value) {
    tab.value = 'userManager'
  } else if (canManageSettings.value) {
    tab.value = 'settingManager'
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (canManageUsers.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createElementVNode("span", {
                class: _normalizeClass([{ 'active': tab.value === 'userManager' }, "nav-link"]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (tab.value = 'userManager'))
              }, " Utilisateurs ", 2)
            ]))
          : _createCommentVNode("", true),
        (canManageSettings.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createElementVNode("span", {
                class: _normalizeClass([{ 'active': tab.value === 'settingManager' }, "nav-link"]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (tab.value = 'settingManager'))
              }, " Paramètres ", 2)
            ]))
          : _createCommentVNode("", true),
        (canManageSancareSettings.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
              _createElementVNode("span", {
                class: _normalizeClass([{ 'active': tab.value === 'sancareSettingManager' }, "nav-link"]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (tab.value = 'sancareSettingManager'))
              }, " Paramètres Administrateur ", 2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        (canManageUsers.value)
          ? _withDirectives((_openBlock(), _createBlock(UserManager, { key: 0 }, null, 512)), [
              [_vShow, tab.value === 'userManager']
            ])
          : _createCommentVNode("", true),
        (canManageSettings.value)
          ? _withDirectives((_openBlock(), _createBlock(AppSettingsManager, {
              key: 1,
              "sancare-only": false
            }, null, 512)), [
              [_vShow, tab.value === 'settingManager']
            ])
          : _createCommentVNode("", true),
        (canManageSancareSettings.value)
          ? _withDirectives((_openBlock(), _createBlock(AppSettingsManager, {
              key: 2,
              "sancare-only": true
            }, null, 512)), [
              [_vShow, tab.value === 'sancareSettingManager']
            ])
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})