import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { computed } from 'vue'

import router from '@/router'
import { useStore } from '@/store'
import { ConditionGroup, SearchTypeEnum } from '@/store/modules/search/types'

import AdvancedFilterBuilder from './AdvancedFilterBuilder.vue'
import ConditionGroupDisplayer from './ConditionGroupDisplayer.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AdvancedConditionGroup',
  props: {
    internalOperator: {},
    group: {},
    groupIsGlobal: { type: Boolean, default: false },
    searchType: {}
  },
  emits: ['clear-message', 'delete-condition-group', 'refresh-patients'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const emit = __emit
const props = __props

const stepId = computed(() => router.currentRoute.value.query.stepId && Number(router.currentRoute.value.query.stepId))

function addCondition({ type, value }) {
  emit('clear-message')
  const currentConditionCount = props.group.conditionList.length
  if (props.groupIsGlobal) {
    store.commit('addGlobalCondition', { type, value })
  } else {
    store.commit('addCondition', { groupId: props.group.id, type, value })
  }
  if (props.group.conditionList.length !== currentConditionCount) {
    emit('refresh-patients', stepId.value)
  }
}
function removeCondition({ groupId, condition }) {
  emit('clear-message')
  if (props.groupIsGlobal) {
    store.commit('removeGlobalCondition', { condition })
  } else {
    store.commit('removeCondition', { groupId, condition })
  }
  emit('refresh-patients', stepId.value)
}
function deleteConditionGroup(groupId: number | string) {
  if (!props.groupIsGlobal) {
    emit('clear-message')
    emit('delete-condition-group', { groupId })
  }
  emit('refresh-patients', stepId.value)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, " Liste de conditions" + _toDisplayString(_ctx.groupIsGlobal ? ' globales' : '') + " : ", 1),
          (!_ctx.groupIsGlobal)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "col-auto",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (deleteConditionGroup(_ctx.group.id)))
              }, [
                _createVNode(_unref(SancareOcticon), {
                  name: "x",
                  width: 17,
                  height: 20
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(ConditionGroupDisplayer, {
          "can-remove-condition": true,
          "internal-operator": _ctx.internalOperator,
          "condition-group": _ctx.group,
          "display-grouped-condition": _ctx.groupIsGlobal,
          onRemoveCondition: removeCondition
        }, null, 8, ["internal-operator", "condition-group", "display-grouped-condition"]),
        _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)),
        _createVNode(AdvancedFilterBuilder, {
          "group-is-global": _ctx.groupIsGlobal,
          onAddCondition: addCondition
        }, null, 8, ["group-is-global"])
      ])
    ])
  ]))
}
}

})