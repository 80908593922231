import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "large-container" }
const _hoisted_2 = { class: "home-list" }
const _hoisted_3 = { class: "home-list-part-title px-2" }
const _hoisted_4 = { class: "home-list-part" }
const _hoisted_5 = { class: "row no-gutters" }
const _hoisted_6 = { class: "row no-gutters mt-5 align-items-center" }
const _hoisted_7 = { class: "col col-xl-8 px-5" }
const _hoisted_8 = { class: "col-3 p-5 text-center border-left" }
const _hoisted_9 = { class: "home-list-part" }
const _hoisted_10 = {
  key: 0,
  class: "loader loader-lg loader-center"
}
const _hoisted_11 = {
  key: 1,
  class: "row"
}
const _hoisted_12 = { class: "col-9" }
const _hoisted_13 = { class: "col-3 stats-canvas" }

import { PillButton } from '@sancare/ui-frontend-commons'
import Chart from 'chart.js/auto'
import _ from 'lodash'
import { computed, onMounted, Ref, ref, watch } from 'vue'

import router from '@/router'
import FastConditionCreator from '@/search/FastConditionCreator.vue'
import SearchDisplayer from '@/search/SearchDisplayer.vue'
import { useStore } from '@/store'
import { Condition, SearchOperatorEnum, SearchTypeEnum } from '@/store/modules/search/types'
import { AggregationModifiers, Variable } from '@/variables/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyVariableManager',
  setup(__props) {

const store = useStore()
let variableChart = null

const variableCanvas = ref(null)
const clickedVariable = ref(null) as Ref<Variable>

const studyId = computed(() => router.currentRoute.value.params.studyId)
const currentStudy = computed(() => store.state.study.currentStudy)
const lastStepId = computed(() => store.getters.lastStep.id ?? null)
const studyVariables = computed(() => _.sortBy(store.state.study.currentStudy.variables, [(variable) => variable.name.toLowerCase()]))
const variables = computed(() => _.sortBy(store.state.variables.variables, [(variable) => variable.name.toLowerCase()]))
const requestsOngoing = computed(() =>
  store.state.study.request.studyAddVariable.fetching
    || store.state.study.request.fetchStudyVariablesStats.fetching
    || store.state.variables.addVariableToStudyRequest.fetching
    || store.state.variables.removeVariableFromStudyRequest.fetching
)
const requestsSuccess = computed(() =>
  store.state.study.request.studyAddVariable.ok
    || store.state.variables.addVariableToStudyRequest.ok
    || store.state.variables.removeVariableFromStudyRequest.ok
)
const studyVariablesHashes = computed(() => {
  return  studyVariables.value.map((sv) => sv.hash)
})
const availableVariables = computed(() => {
  return variables.value.filter((v) => studyVariablesHashes.value.indexOf(v.hash) === -1)
})

async function refresh() {
  store.dispatch('fetchVariables', { withConditions: true })
  await store.dispatch('fetchStudy', { studyId: studyId.value, forceFetch: false })
}

async function getDataSets(variable) {
  // const colorSet = ["#E331A9", "#0E3C6A"];
  const colorSet = ['#E331A9', '#0E3C6A', '#318BE3', '#31E1E3', '#FD6565']
  const dataSets = []

  // fetch stats if needed
  if (!currentStudy.value.variablesStats[variable.id]) {
    await store.dispatch('fetchStudyVariablesStats', { studyId: studyId.value, variableIds: [variable.id] })
  }
  const data = currentStudy.value.variablesStats[variable.id]

  dataSets.push({
    data: data,
    fill: true,
    borderColor: colorSet,
    backgroundColor: colorSet,
    borderWidth: 2,
    borderSkipped: false,
  })

  return dataSets
}

function getOptions(titleText: string): object {
  return {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: titleText
      }
    },
  }
}

async function showDiagram(variable: Variable) {
  // this condition prevents the user from flooding the backend with
  // unnecessary requests
  if (clickedVariable.value === variable) {
    return
  }
  clickedVariable.value = variable
  if (variableChart !== null) {
    variableChart.destroy()
  }
  const datasets = await getDataSets(variable)
  const canvas = variableCanvas.value.getContext('2d')

  variableChart = new Chart(canvas,
    {
      type: variable.aggregateCondition ? 'bar' : 'pie',
      data: {
        labels: variable.aggregateCondition ? [] : ['NON', 'OUI'],
        datasets,
      },
      options: getOptions(`Proportion de patients qui répondent aux conditions de la variable ${variable.name}`)
    }
  )
}

onMounted(async () => {
  await refresh()
  store.commit(
    'addBreadcrumbEntry',
    {
      name: `Variables de ${store.state.study.currentStudy.name ?? ''}`,
      customKey: '/study-variable/',
      routerLinkArg: location.pathname + location.search
    },
  )
})

function addVariableToStudy(variable: Variable) {
  store.dispatch('addVariableToStudy', { studyId: studyId.value, variableId: variable.id })
}
function removeVariableFromStudy(variable: Variable) {
  store.dispatch('removeVariableFromStudy', { studyId: studyId.value, variableId: variable.id })
}

function resetCurrentNode() {
  store.commit('resetNewSearch')
}

async function addFastVariable(condition: Condition, name: string, aggregateCondition: Condition, aggregationModifiers: AggregationModifiers) {
  if (name === '') {
    return
  }
  const conditionList = condition ? [condition] : []

  const variable = {
    id: null,
    name: name,
    globalConditionGroup: { id: null, conditionList: [] },
    searchOperators: SearchOperatorEnum.AND_OR,
    conditionGroups: [{
      id: null,
      conditionList,
    }],
    aggregateCondition: aggregateCondition,
    modifiers: aggregationModifiers,
  }

  await store.dispatch('studyAddVariable', { studyId: studyId.value, variable })
}

watch(requestsSuccess, (newVal) => {
  if (newVal) {
    store.dispatch('toast/setSuccess', 'Étude enregistrée.')
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, " Gestion des variables d'intérêt de " + _toDisplayString(currentStudy.value.name ?? ''), 1),
      _createElementVNode("div", _hoisted_4, [
        _cache[1] || (_cache[1] = _createElementVNode("h4", null, "Variables de la bibliothèque", -1)),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableVariables.value, (variable) => {
            return (_openBlock(), _createBlock(_unref(PillButton), {
              key: 'var_'+variable.id,
              item: variable,
              "octicon-name": 'plus',
              "icon-bg-color": 'bg-green',
              onClickPill: showDiagram,
              onClickIcon: addVariableToStudy
            }, null, 8, ["item"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(FastConditionCreator, {
              "initial-condition-name": "Créer une variable rapide",
              "search-type": _unref(SearchTypeEnum).VARIABLE,
              onAddCondition: addFastVariable
            }, null, 8, ["search-type"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (currentStudy.value.id)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: {name: 'variableNew', query: {studyId: currentStudy.value.id, stepId: lastStepId.value}},
                  onClick: resetCurrentNode
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Créer une nouvelle variable ")
                  ])),
                  _: 1
                }, 8, ["to"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[3] || (_cache[3] = _createElementVNode("h4", null, "Variables de l'étude", -1)),
        (requestsOngoing.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(studyVariables.value, (selectedVariable) => {
                  return (_openBlock(), _createBlock(_unref(PillButton), {
                    key: 'selectedVar_'+selectedVariable.id,
                    item: selectedVariable,
                    "octicon-name": 'x',
                    "icon-bg-color": 'bg-red',
                    onClickPill: showDiagram,
                    onClickIcon: removeVariableFromStudy
                  }, null, 8, ["item"]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("canvas", {
                  ref_key: "variableCanvas",
                  ref: variableCanvas
                }, null, 512)
              ])
            ])),
        (clickedVariable.value)
          ? (_openBlock(), _createBlock(SearchDisplayer, {
              key: 2,
              class: "card",
              search: clickedVariable.value,
              "read-only": true
            }, null, 8, ["search"]))
          : _createCommentVNode("", true),
        _createVNode(_component_router_link, {
          to: {name: 'patientList', query: {studyId: studyId.value}},
          class: "btn btn-rounded"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Voir la liste des patients ")
          ])),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}
}

})