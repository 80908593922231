export default {
  presentGlobalDiagnosis              : 'Diagnostics présents',
  presentPrincipalDiagnosis           : 'DP présents',
  presentRelatedDiagnosis             : 'DR présents',
  presentAssociatedDiagnosis          : 'DAS présents',
  presentSancareGlobalDiagnosis       : 'Diagnostics prédits présents',
  presentSancarePrincipalDiagnosis    : 'DP prédits présents',
  presentSancareRelatedDiagnosis      : 'DR prédits présents',
  presentSancareAssociatedDiagnosis   : 'DAS prédits présents',
  presentBothGlobalDiagnosis          : 'Diagnostics présents',
  presentBothPrincipalDiagnosis       : 'DP présents',
  presentBothRelatedDiagnosis         : 'DR présents',
  presentBothAssociatedDiagnosis      : 'DAS présents',
  absentGlobalDiagnosis               : 'Diagnostics absents',
  absentPrincipalDiagnosis            : 'DP absents',
  absentRelatedDiagnosis              : 'DR absents',
  absentAssociatedDiagnosis           : 'DAS absents',
  absentSancareGlobalDiagnosis        : 'Diagnostics prédits absents',
  absentSancarePrincipalDiagnosis     : 'DP prédits absents',
  absentSancareRelatedDiagnosis       : 'DR prédits absents',
  absentSancareAssociatedDiagnosis    : 'DAS prédits absents',
  absentBothGlobalDiagnosis           : 'Diagnostics absents',
  absentBothPrincipalDiagnosis        : 'DP absents',
  absentBothRelatedDiagnosis          : 'DR absents',
  absentBothAssociatedDiagnosis       : 'DAS absents',

  presentGhm                          : 'GHM présents',
  absentGhm                           : 'GHM absents',

  presentGhmType                      : 'Types de racine GHM présents',
  absentGhmType                       : 'Types de racine GHM absents',

  presentGhmLevel                     : 'Sévérités présentes',
  absentGhmLevel                      : 'Sévérités prédites absentes',

  presentMedicalAct                   : 'Actes CCAM présents',
  absentMedicalAct                    : 'Actes CCAM absents',
  medicalActActivity                  : 'Activité d\'acte CCAM',

  fromStayDate                        : 'Date de venue',
  toStayDate                          : 'Date de venue',

  fromDeceaseDate                     : 'Date de décès',
  toDeceaseDate                       : 'Date de décès',

  presentContent                      : 'Mots-clés présents',
  softAbsentContent                   : 'Passages exclus des mots-clés',
  absentContent                       : 'Mots-clés absents',
  namedEntity                         : 'Mots-clés dans entité nommée',

  presentTitle                        : 'Mots-clés dans le titre',

  documentCategory                    : 'Document présent',

  presentDrug                         : 'Médicaments présents',
  absentDrug                          : 'Médicaments absents',

  presentMedicalUnit                  : 'Unités Médicales présentes',
  absentMedicalUnit                   : 'Unités Médicales absentes',
  medicalUnitDuration                 : 'Durée dans l\'unité médicale',

  presentDivision                     : 'Divisions présentes',
  absentDivision                      : 'Divisions absentes',

  presentFacility                     : 'Etablissements présents',
  absentFacility                      : 'Etablissements absents',

  healthConstant                      : 'Constantes',

  biologyResult                       : 'Résultat de laboratoire',

  compareDiagnosis                    : 'Comparaison des DP',
  admissionMode                       : 'Mode d\'admission',
  releaseMode                         : 'Mode de sortie',
  stayDuration                        : 'Durée du séjour',
  rumCount                            : 'Nombre de rum',
  patientAge                          : 'Âge du patient',
  gender                              : 'Sexe',
  origin                              : 'Provenance du patient',
  destination                         : 'Destination du patient',

  hasSession                          : 'Présence de séances',
  isDeceased                          : 'Patient décédé',

  exposition                          : 'Recherche temporelle',
  variableResults                     : 'Variable',
}
