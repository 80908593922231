import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "document-entity-annotations-list d-flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "entities non-validated-entities d-flex flex-column"
}
const _hoisted_3 = {
  key: 1,
  class: "entities validated-entities d-flex flex-column"
}

import { computed } from 'vue'

import DocumentEntityAnnotation from '@/document-entity-explorer/DocumentEntityAnnotation.vue'
import { AnnotatedEntity } from '@/store/modules/document-entity-explorer/types'
import { AnnotatedChunk } from '@/store/modules/document-entity-explorer/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentEntityAnnotationsList',
  props: {
    entities: {},
    chunks: {}
  },
  setup(__props: any) {

const props = __props

const validatedEntities = computed(() => props.entities.filter((entity) => entity.validated))
const nonValidatedEntities = computed(() => props.entities.filter((entity) => !entity.validated))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (nonValidatedEntities.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nonValidatedEntities.value, (entity) => {
            return (_openBlock(), _createElementBlock("div", {
              key: entity.entityName,
              class: "d-flex flex-column"
            }, [
              _createVNode(DocumentEntityAnnotation, { entity: entity }, null, 8, ["entity"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (validatedEntities.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "validated-entities-title pl-4 font-weight-bold" }, " Entités validées ", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(validatedEntities.value, (entity) => {
            return (_openBlock(), _createBlock(DocumentEntityAnnotation, {
              key: entity.entityName,
              entity: entity
            }, null, 8, ["entity"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})