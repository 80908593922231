import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "large-container" }
const _hoisted_2 = { class: "row justify-content-between align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "col text-right"
}
const _hoisted_4 = {
  key: 0,
  class: "loader loader-lg loader-center"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "text-center"
}
const _hoisted_7 = { class: "patient-list-table" }
const _hoisted_8 = { key: 1 }

import { computed, onMounted, watch } from 'vue'

import { useStore } from '@/store'
import { Job, jobStatusLabels, jobTypeLabels } from '@/store/modules/job/types'
import { showJobDuration } from '@/store/modules/job/utils'

const dateFormat = 'DD/MM/YYYY HH:mm:ss'


export default /*@__PURE__*/_defineComponent({
  __name: 'JobsPage',
  props: {
    studyId: { default: null }
  },
  setup(__props: any) {

const store = useStore()

const props = __props

const fetchJobsRequest = computed(() => store.state.job.requests.fetchJobs)
const jobs = computed(() => store.state.job.jobs)

const isFilteredByStudy = computed(() => props.studyId !== null)

function showCreatedBy(job: Job) {
  if (!job.createdBy || !job.createdBy.firstName || !job.createdBy.lastName) {
    return 'System'
  }
  return `${job.createdBy.firstName} ${job.createdBy.lastName}`
}

function refresh() {
  store.dispatch('job/fetchJobs', props.studyId)
  store.commit('setBreadcrumbList', { breadcrumbList: [] })
  if (props.studyId) {
    store.commit(
      'addBreadcrumbEntry',
      {
        name: `Étude ${store.state.study.currentStudy.name ?? ''}`,
        customKey: '/study/',
        routerLinkArg: `/study/${props.studyId}`,
      }
    )
  }
  store.commit(
    'addBreadcrumbEntry',
    {
      name:'Liste des tâches',
      customKey: '/jobs/',
      routerLinkArg: location.pathname + location.search
    },
  )
}

watch(() => props.studyId, () => {
  refresh()
})

onMounted(() => {
  refresh()

})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (isFilteredByStudy.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_router_link, { to: { name: 'jobs' } }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Voir la liste des tâches de toutes les études → ")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (fetchJobsRequest.value.fetching)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : (jobs.value.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Aucune tâche créée. "))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("table", _hoisted_7, [
              _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
                _createElementVNode("tr", { class: "align-items-center" }, [
                  _createElementVNode("th", null, "Type"),
                  _createElementVNode("th", null, "Etude"),
                  _createElementVNode("th", null, "Statut"),
                  _createElementVNode("th", null, "Créée par"),
                  _createElementVNode("th", null, "Créée le"),
                  _createElementVNode("th", null, "Commencée le"),
                  _createElementVNode("th", null, "Terminée le")
                ])
              ], -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(jobs.value, (job) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: job.id
                }, [
                  _createElementVNode("td", null, _toDisplayString(_unref(jobTypeLabels)[job.type]), 1),
                  _createElementVNode("td", null, [
                    (job.study.id)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: {name: 'study', params: {studyId: job.study.id}}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(job.study.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_8, "-"))
                  ]),
                  _createElementVNode("td", null, _toDisplayString(_unref(jobStatusLabels)[job.status]) + " " + _toDisplayString(null !== job.startedAt ? `(${_unref(showJobDuration)(job)})` : ''), 1),
                  _createElementVNode("td", null, _toDisplayString(showCreatedBy(job)), 1),
                  _createElementVNode("td", null, _toDisplayString(job.createdAt.format(dateFormat)), 1),
                  _createElementVNode("td", null, _toDisplayString(job.startedAt?.format(dateFormat) ?? '-'), 1),
                  _createElementVNode("td", null, _toDisplayString(job.endedAt?.format(dateFormat) ?? '-'), 1)
                ]))
              }), 128))
            ])
          ]))
  ]))
}
}

})