import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center" }
const _hoisted_2 = { class: "col-auto" }

import { onMounted, onUnmounted, ref, watch } from 'vue'

import { useStore } from '@/store'
import { StudyStep } from '@/store/modules/study/types'

import DiagramStudyNode from './DiagramStudyNode.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DiagramStep',
  props: {
    step: {},
    count: {},
    isReadonly: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const store = useStore()
const elementRef = ref(null)

onMounted(() => {
  store.commit('renderStep', {
    element: elementRef.value,
    step: props.step,
  })
  store.commit('renderStepLinkWithPrevious', props.step)
})
onUnmounted(() => {
  store.commit('unrenderStep', props.step)
})
watch(props.step, (oldVal: StudyStep, newVal: StudyStep) => {
  if (oldVal.id !== newVal.id) {
    store.commit('setStepId', { oldId: oldVal.id, newId: newVal.id })
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref_key: "elementRef",
      ref: elementRef,
      class: _normalizeClass(["rectangle step", {disabled: _ctx.isReadonly, core: _ctx.step.isCore}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.step.name) + " [" + _toDisplayString(_ctx.count === -1 ? '< 10' : _ctx.count) + "]", 1)
        ])
      ])
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.step.rootNode.children, (node) => {
      return (_openBlock(), _createBlock(DiagramStudyNode, {
        key: node.id,
        "study-node": node,
        "is-readonly": _ctx.isReadonly
      }, null, 8, ["study-node", "is-readonly"]))
    }), 128))
  ]))
}
}

})