import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center" }
const _hoisted_2 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }

import { onMounted, onUnmounted, ref, watch } from 'vue'

import { useStore } from '@/store'
import { operatorLabels } from '@/store/modules/search/types'
import { StudyNode } from '@/store/modules/study/types'


// setup syntax does not provide name option
const __default__ = {
  name: 'DiagramStudyNode'
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    studyNode: {},
    isReadonly: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const store = useStore()
const elementRef = ref(null)

onMounted(() => {
  store.commit('renderStudyNode', {
    element: elementRef.value,
    studyNode: props.studyNode,
  })
})
onUnmounted(() => {
  store.commit('unrenderStudyNode', props.studyNode)
})
watch(props.studyNode, (oldVal: StudyNode, newVal: StudyNode) => {
  if (oldVal.id !== newVal.id) {
    store.commit('setNodeId', { oldId: oldVal.id, newId: newVal.id })
  }
})


return (_ctx: any,_cache: any) => {
  const _component_diagram_study_node = _resolveComponent("diagram-study-node", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref_key: "elementRef",
      ref: elementRef,
      class: _normalizeClass(["rectangle", {
        'study-node': !_ctx.studyNode.name,
        search: _ctx.studyNode.name,
        'disabled': _ctx.isReadonly

      }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.studyNode.name)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(operatorLabels)[_ctx.studyNode.operator]), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.studyNode.name), 1))
      ])
    ], 2),
    (!_ctx.studyNode.name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.studyNode.children, (child) => {
            return (_openBlock(), _createBlock(_component_diagram_study_node, {
              key: child.id,
              "is-readonly": _ctx.isReadonly,
              "study-node": child
            }, null, 8, ["is-readonly", "study-node"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})