import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row no-gutters" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "col" }
const _hoisted_5 = {
  key: 1,
  class: "row no-gutters align-items-center gap-1"
}
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "row col-12 ml-2" }

import { computed, reactive, Ref, ref, watch } from 'vue'
import VueSlider from 'vue-slider-component'

import { FilterData } from '@/filters/types'
import { useStore } from '@/store'

const OFFSET_CONSTANT_VALUE_RANGE = 10

interface Range {
  min: string | number
  max: string | number
  list: number[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ConstantFilter',
  props: {
    filterData: {},
    isFastCondition: { type: Boolean, default: false }
  },
  emits: ['add-filter'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const props = __props
const emit = __emit

const constantId = ref(null)
const inputMode = ref('slider')
const values = ref([0, 100]) as Ref<(number | string)[]>
const range = reactive({ min: 0, max: 100, list: [0, 100] }) as Range

const list = computed(() => store.state.medicalUnitFilter.remoteLists['constants'])
watch(constantId, () => {
  if (!constantId.value) {
    range.min = 0
    range.max = 100
    range.list = [0, 100]
  }

  const constant = store.state.medicalUnitFilter.remoteLists['constants'].find((elem) => elem.id === constantId.value)

  const rawMin = parseFloat(constant.min)
  const rawMax = parseFloat(constant.max)

  let min = (rawMin - OFFSET_CONSTANT_VALUE_RANGE * rawMin / 100) + 0.5
  let max = (rawMax + OFFSET_CONSTANT_VALUE_RANGE * rawMax / 100) + 0.5

  min -= min % 10
  max += 10 - (max % 10)

  if (min < 0 && rawMin >= 0) {
    min = 0
  }

  const diff = max - min
  let step = 1

  if (diff > 1000) {
    step = 50
  } else if (diff > 100) {
    step = 5
  } else if (diff < 1 && diff > 0.1) {
    step = 0.05
  } else if (diff < 1 && diff > 0.01) {
    step = 0.005
  }

  let i = min
  const list = []

  if (min !== 0 && rawMin >= 0) {
    list.push(`<${min}`)
  }
  while (i < max) {
    list.push(i)
    i += step
  }
  list.push(max)
  list.push(`>${max}`)

  if (min !== 0 && rawMin >= 0) {
    values.value = [`<${min}`, `>${max}`]

    range.min = `<${min}`
    range.max = `>${max}`
    range.list = list
  } else {
    values.value = [min, `>${max}`]

    range.min = min
    range.max = `>${max}`
    range.list = list
  }
})

function addFilter() {
  const name = props.filterData.name
  const type = 'healthConstant'
  const value = `${constantId.value}\x1F${values.value[0]}\x1F${values.value[1]}`

  if (values.value[0] !== range.min || values.value[1] !== range.max) {
    emit('add-filter', { name, type, value })
  }
}
function toggleInputMode() {
  inputMode.value = inputMode.value === 'slider' ? 'manual' : 'slider'
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((constantId).value = $event)),
          class: "form-control"
        }, [
          _cache[5] || (_cache[5] = _createElementVNode("option", {
            value: "",
            disabled: "",
            selected: ""
          }, " --- ", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (constant) => {
            return (_openBlock(), _createElementBlock("option", {
              key: 'constant_'+constant.id,
              value: constant.id
            }, _toDisplayString(constant.description), 9, _hoisted_3))
          }), 128))
        ], 512), [
          [_vModelSelect, constantId.value]
        ])
      ])
    ]),
    (constantId.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["row no-gutters align-items-center gap-2", inputMode.value === 'slider' ? 'mt-5' : 'mt-3'])
        }, [
          _createElementVNode("div", _hoisted_4, [
            (inputMode.value === 'slider')
              ? (_openBlock(), _createBlock(_unref(VueSlider), {
                  key: 0,
                  modelValue: values.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((values).value = $event)),
                  "v-data": range.list,
                  tooltip: 'always',
                  range: "",
                  class: "mx-3"
                }, null, 8, ["modelValue", "v-data"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-1 text-center" }, " de ", -1)),
                  _createElementVNode("div", _hoisted_6, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((values.value[0]) = $event)),
                      type: "text",
                      class: "form-control"
                    }, null, 512), [
                      [_vModelText, values.value[0]]
                    ])
                  ]),
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-1 text-center" }, " à ", -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((values.value[1]) = $event)),
                      type: "text",
                      class: "col form-control"
                    }, null, 512), [
                      [_vModelText, values.value[1]]
                    ])
                  ])
                ]))
          ]),
          _createElementVNode("div", { class: "col-auto" }, [
            _createElementVNode("button", {
              class: "btn btn-primary",
              type: "button",
              onClick: addFilter
            }, " Ok ")
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", {
              class: "btn btn-link",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (toggleInputMode()))
            }, _toDisplayString(inputMode.value === 'slider' ? 'Mode manuel' : 'Mode normal'), 1)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})