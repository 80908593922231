import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "study-node footer" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "col-auto"
}

import { computed, ref } from 'vue'

import ConditionGroupDisplayer from '@/filters/ConditionGroupDisplayer.vue'
import { getDisplayableOperator } from '@/search/utils'
import { SearchInterface } from '@/store/modules/search/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'CriterionDisplayer',
  props: {
    criterion: {},
    softAbsentContents: { default: () => [] }
  },
  setup(__props: any) {

const props = __props

const criterionIsSelected = ref(false)

const softAbsentContents = computed(() => {
  const localSoftAbsentContents = props.criterion.globalConditionGroup?.conditionList?.map((c) => c.value) ?? []
  return props.softAbsentContents.concat(localSoftAbsentContents)
})

function selectNode() {
  criterionIsSelected.value = !criterionIsSelected.value
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([{'selected': criterionIsSelected.value}, "study-node text-center header"]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (selectNode()))
    }, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.criterion.name || 'Nouveau critère'), 1)
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      (_ctx.criterion.globalConditionGroup && _ctx.criterion.globalConditionGroup.conditionList.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(ConditionGroupDisplayer, {
              "can-remove-condition": false,
              "internal-operator": 'ET',
              "condition-group": _ctx.criterion.globalConditionGroup,
              "display-grouped-condition": true
            }, null, 8, ["condition-group"]),
            _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1))
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.criterion.conditionGroups, (conditionGroup, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          key: conditionGroup.id
        }, [
          (idx > 0 && conditionGroup.conditionList.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(getDisplayableOperator)(_ctx.criterion.searchOperators)), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.criterion.conditionGroups.length > 1 ? 'card m-2' : 'm-2')
          }, [
            _createVNode(ConditionGroupDisplayer, {
              "can-remove-condition": false,
              "internal-operator": _unref(getDisplayableOperator)(_ctx.criterion.searchOperators, true),
              "condition-group": conditionGroup,
              "display-grouped-condition": false,
              "show-justifications": true,
              "soft-absent-contents": softAbsentContents.value
            }, null, 8, ["internal-operator", "condition-group", "soft-absent-contents"])
          ], 2)
        ]))
      }), 128))
    ], 512), [
      [_vShow, criterionIsSelected.value]
    ])
  ]))
}
}

})