import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

import { SancareOcticon } from '@sancare/ui-frontend-commons'

import ConditionJustifications from '@/filters/ConditionJustifications.vue'
import { ConditionModifiers } from '@/study/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleValueFilter',
  props: {
    filter: {},
    canEdit: { type: Boolean, default: false },
    showJustifications: { type: Boolean, default: false },
    softAbsentContents: { default: () => [] }
  },
  emits: ['remove-condition'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

function removeCondition() {
  emit('remove-condition', props.filter.condition)
}

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.filter.modifiers && _ctx.filter.modifiers.includeAntecedents)
      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_unref(SancareOcticon), {
            name: "eye",
            width: 14,
            height: 11
          })
        ])), [
          [_directive_tooltip, {content: 'Recherche également dans les antécédents', offset: [5,5], placement: 'bottom'}]
        ])
      : _createCommentVNode("", true),
    (_ctx.filter.modifiers && _ctx.filter.modifiers.includeFamilyAntecedents)
      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_unref(SancareOcticon), {
            name: "organization",
            width: 14,
            height: 11
          })
        ])), [
          [_directive_tooltip, {content: 'Recherche également dans les antécédents familiaux', offset: [5,5], placement: 'bottom'}]
        ])
      : _createCommentVNode("", true),
    (_ctx.filter.modifiers && _ctx.filter.modifiers.includeNegations)
      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createVNode(_unref(SancareOcticon), {
            name: "circle-slash",
            width: 14,
            height: 11
          })
        ])), [
          [_directive_tooltip, {content: 'Recherche également dans les négations', offset: [5,5], placement: 'bottom'}]
        ])
      : _createCommentVNode("", true),
    (_ctx.filter.modifiers && _ctx.filter.modifiers.wordDistance && _ctx.filter.modifiers.wordDistance > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, " [Intervalle: " + _toDisplayString(_ctx.filter.modifiers.wordDistance) + "] ", 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, _toDisplayString(_ctx.filter.content), 1),
    (_ctx.canEdit)
      ? (_openBlock(), _createElementBlock("span", {
          key: 4,
          onClick: removeCondition
        }, [
          _createVNode(_unref(SancareOcticon), {
            name: "x",
            width: 14,
            height: 11
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.showJustifications)
      ? (_openBlock(), _createBlock(ConditionJustifications, {
          key: 5,
          condition: _ctx.filter.condition,
          "soft-absent-contents": _ctx.softAbsentContents
        }, null, 8, ["condition", "soft-absent-contents"]))
      : _createCommentVNode("", true)
  ]))
}
}

})