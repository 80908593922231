import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "large-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "card patient-list-condition" }
const _hoisted_4 = { class: "card-body row col-auto" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "align-content-center"
}

import { SancarePagination } from '@sancare/ui-frontend-commons'
import { ExportButton } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { RouteLocationNormalizedLoaded } from 'vue-router'

import ListParameters from '@/criteria/ListParameters.vue'
import { studyPermissions } from '@/misc/permissions/permissionsHelper'
import { criterionListColumns, studyListColumns } from '@/patient-list/PatientListColumns'
import PatientListCount from '@/patient-list/PatientListCount.vue'
import PatientListTable from '@/patient-list/PatientListTable.vue'
import { ReadingListQuery } from '@/patient-list/types'
import router from '@/router'
import { useStore } from '@/store'
import { PatientListColumn, PatientListColumnEnum } from '@/store/modules/patient/types'
import { SearchOperatorEnum } from '@/store/modules/search/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientListPage',
  setup(__props) {

const store = useStore()

const showExportWarning = ref(false)

const studyId = computed(() => router.currentRoute.value.query.studyId)
const stepId = computed(() => router.currentRoute.value.query.stepId)

const currentUser = computed(() => store.state.settings.currentUser)
const patientCount = computed(() => store.state.patientList.patientCount)
const patientList = computed(() => store.state.patientList.patientList)
const displayedColumns = computed(() => store.getters.getUserColumnsSettings(studyId.value))
const pageCount = computed(() => store.state.patientList.pageCount)
const currentPage = computed(() => store.state.patientList.patientListParams.currentPage)
const params = computed(() => store.state.patientList.patientListParams)
const fetchPatientListRequest = computed(() => store.state.patientList.fetchPatientListRequest)
const currentStudy = computed(() => store.state.study.currentStudy)
const canShowPatients = computed(() => studyPermissions.canShowPatientList(currentStudy.value, currentUser.value))
const canValidate = computed(() => studyPermissions.canValidate(currentStudy.value, currentUser.value))
const canUnvalidate = computed(() => studyPermissions.canUnvalidate(currentStudy.value, currentUser.value))

async function refreshPatientList(resetPatientSelection: boolean, refreshUrl: boolean) {
  const urlParams = {
    ...store.getters['shortHandPatientListParams'],
  }
  if (stepId.value) {
    store.dispatch('fetchStepPatientList', stepId.value)
    urlParams.stepId = stepId.value
  } else if (studyId.value) {
    urlParams.studyId = studyId.value
    await store.dispatch(
      'fetchStudy',
      { studyId: studyId.value }
    )
    store.dispatch('fetchStudyPatientList', {
      study: currentStudy.value,
      columns: displayedColumns.value
    })
  } else {
    store.commit('resetStudy')
    store.commit('resetNewSearch')
    store.dispatch('fetchPatientList', resetPatientSelection)
  }
  if (refreshUrl) {
    router.replace({ path: store.state.route.path, query: urlParams })
  }
}
function setColumns(columns: { [key: string]: PatientListColumn }) {
  store.commit('setDisplayedColumns', { columns, studyId: studyId.value })
}
function setParameter(type: string, value: string | number) {
  store.commit('setPatientListParam', { type: type, value: value })
  refreshPatientList(type !== 'pageSize', true)
}
function setPage(value: number) {
  setParameter('currentPage', value)
}
function validateStudy() {
  store.dispatch('validateStudy', currentStudy.value.id)
}

function unvalidateStudy() {
  store.dispatch('unvalidateStudy', currentStudy.value.id)
}

function exportPatients() {
  const url = new URL(`/api/export/patient-csv/${studyId.value}`, window.location.href)
  Object.keys(displayedColumns.value).forEach((column) => url.searchParams.append(`${displayedColumns.value[column].type}[]`, displayedColumns.value[column].id ?? displayedColumns.value[column].name))

  window.open(url.toString(), '_blank')
  showExportWarning.value = false
}

async function gotoPatientPage(forceNewWindow: boolean, newRoute: RouteLocationNormalizedLoaded) {
  const query = {} as ReadingListQuery
  if (stepId.value) {
    query.stepId = Number(stepId.value)
  } else if (studyId.value) {
    query.studyId = Number(studyId.value)
  } else {
    query.search = {
      id: null,
      name: null,
      conditionGroups: [],
      searchOperators: SearchOperatorEnum.AND_OR,
      globalConditionGroup: {
        conditionList: [],
        id: 'global'
      }
    }
  }
  const initIndex = (currentPage.value - 1) * params.value.pageSize + patientList.value.findIndex((p) => p.ipp === newRoute.params.patientIpp)
  await store.dispatch('createReadingList', { query, initIndex })
  if (forceNewWindow) {
    window.open(router.resolve(newRoute).href, '_blank')
  } else {
    router.push(newRoute)
  }
}

onMounted(async () => {
  store.commit(
    'addBreadcrumbEntry',
    {
      name: 'Liste des patients',
      customKey: '/patient-list/',
      routerLinkArg: location.pathname + location.search
    },
  )
  await refreshPatientList(true, false)
  const columns = currentStudy.value ? _.clone(studyListColumns) : _.clone(criterionListColumns)
  if (currentStudy.value) {
    currentStudy.value.variables.forEach((variable) => {
      columns[variable.name] = {
        type: PatientListColumnEnum.VARIABLE,
        id: variable.id,
        name: variable.name,
        label: variable.name,
        isDefault: false,
        invertedOrder: false
      }
    })
  }
  store.commit('setPatientListColumns', columns)
  if (currentStudy.value) {
    // remove invalid variables
    setColumns(displayedColumns.value)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PatientListCount, {
      "patient-count": patientCount.value,
      "study-name": currentStudy.value.id ? currentStudy.value.name : null
    }, null, 8, ["patient-count", "study-name"]),
    (currentStudy.value.id === null || canShowPatients.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(ListParameters, {
                class: "col",
                "show-datepickers": false,
                onSetListParam: setParameter,
                onRefreshList: refreshPatientList
              }),
              _createElementVNode("div", null, [
                (canValidate.value && currentStudy.value.id)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-rounded",
                      onClick: _withModifiers(validateStudy, ["prevent"])
                    }, " Valider l'étude "))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, [
                (canUnvalidate.value && currentStudy.value.id)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-rounded",
                      onClick: _withModifiers(unvalidateStudy, ["prevent"])
                    }, " Dé-valider l'étude "))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          (canShowPatients.value && !stepId.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_unref(ExportButton), {
                  onConfirm: _cache[0] || (_cache[0] = ($event: any) => (exportPatients()))
                })
              ]))
            : _createCommentVNode("", true),
          (canShowPatients.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(PatientListTable, {
                  "displayed-columns": displayedColumns.value,
                  "patient-list": patientList.value,
                  order: params.value.order,
                  "reverse-order": params.value.reverseOrder,
                  "fetch-patient-list-request": fetchPatientListRequest.value,
                  onRefreshList: refreshPatientList,
                  onGotoPatientPage: gotoPatientPage,
                  onSetColumns: setColumns
                }, null, 8, ["displayed-columns", "patient-list", "order", "reverse-order", "fetch-patient-list-request"]),
                _createVNode(_unref(SancarePagination), {
                  "page-count": pageCount.value,
                  "current-page": currentPage.value,
                  onSetPage: setPage
                }, null, 8, ["page-count", "current-page"])
              ], 64))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [
          _createElementVNode("span", null, "Vous n'avez pas accès à la liste des patients", -1)
        ])))
  ]))
}
}

})