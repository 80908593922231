import _ from 'lodash'

import { PatientListColumn, PatientListColumnEnum } from '@/store/modules/patient/types'

function compareColumns(columnA: PatientListColumn, columnB: PatientListColumn): number {
  if (columnA.type === PatientListColumnEnum.SYSTEM && columnB.type !== PatientListColumnEnum.SYSTEM) {
    return -1
  }
  if (columnA.type !== PatientListColumnEnum.SYSTEM && columnB.type === PatientListColumnEnum.SYSTEM) {
    return 1
  }
  if (columnA.type === PatientListColumnEnum.SYSTEM && columnB.type === PatientListColumnEnum.SYSTEM) {
    return columnA.position - columnB.position
  }
  return columnA.label.toLowerCase().localeCompare(columnB.label.toLowerCase())
}

export function sortColumnList(patientListColumns: { [key: string]: PatientListColumn }): { [key: string]: PatientListColumn } {
  return Object.fromEntries(
    Object.entries(patientListColumns)
      .sort(([, a], [, b]) => compareColumns(a, b))
  )
}

export const patientIdColumnName = 'ipp'

export const patientIdColumn = {
  position: 0,
  type: PatientListColumnEnum.SYSTEM,
  name: patientIdColumnName,
  label: 'IPP',
  isDefault: true,
  invertedOrder: false,
}

export const criterionListColumns = {
  [patientIdColumnName]: patientIdColumn,
  birthYear: {
    position: 1,
    type: PatientListColumnEnum.SYSTEM,
    name: 'birthYear',
    label: 'Année de naissance',
    isDefault: true,
    invertedOrder: false,
  },
  gender: {
    position: 2,
    type: PatientListColumnEnum.SYSTEM,
    name: 'genderLabel',
    label: 'Sexe',
    isDefault: true,
    invertedOrder: false,
  },
  deceaseDate: {
    position: 3,
    type: PatientListColumnEnum.SYSTEM,
    name: 'deceaseDate',
    label: 'Date de décès',
    isDefault: true,
    invertedOrder: false,
  },
} as { [key: string]: PatientListColumn }

export const studyListColumns = {
  [patientIdColumnName]: patientIdColumn,
  birthYear: {
    position: 1,
    type: PatientListColumnEnum.SYSTEM,
    name: 'birthYear',
    label: 'Année de naissance',
    isDefault: true,
    invertedOrder: false,
  },
  gender: {
    position: 2,
    type: PatientListColumnEnum.SYSTEM,
    name: 'genderLabel',
    label: 'Sexe',
    isDefault: true,
    invertedOrder: false,
  },
  deceaseDate: {
    position: 3,
    type: PatientListColumnEnum.SYSTEM,
    name: 'deceaseDate',
    label: 'Date de décès',
    isDefault: true,
    invertedOrder: false,
  },
  feedback: {
    position: 4,
    type: PatientListColumnEnum.SYSTEM,
    name: 'feedback',
    label: 'Feedback',
    isDefault: true,
    invertedOrder: false,
    sortDisabled: true,
  },
} as { [key: string]: PatientListColumn }

export const variableListColumns = {
  [patientIdColumnName]: patientIdColumn,
  distribution: {
    position: 1,
    type: PatientListColumnEnum.SYSTEM,
    name: 'matchConditions',
    label: 'Répond aux conditions',
    isDefault: true,
    invertedOrder: false,
    sortDisabled: true,
  },
} as { [key: string]: PatientListColumn }

export const aggregatedVariableListColumns = {
  [patientIdColumnName]: patientIdColumn,
  funcResult: {
    position: 2,
    type: PatientListColumnEnum.SYSTEM,
    name: 'aggregatedConditionResult',
    label: 'Résultat de fonction',
    isDefault: true,
    invertedOrder: false,
    sortDisabled: true,
  }
} as { [key: string]: PatientListColumn }

export type UserColumnsSettings = {
  defaultCriterion: { [key: string]: PatientListColumn }
  defaultStudy: { [key: string]: PatientListColumn }
  variable: { [key: string]: PatientListColumn }
  aggregatedVariable: { [key: string]: PatientListColumn }
  studies: { [key: string]: { [key: string]: PatientListColumn } }
}

export const emptyStoredUserColumnsSettings = () => ({
  defaultCriterion: _.pickBy(criterionListColumns, (column) => column.isDefault),
  defaultStudy: _.pickBy(studyListColumns, (column) => column.isDefault),
  variable: _.pickBy(variableListColumns, (column) => column.isDefault),
  aggregatedVariable: _.pickBy(aggregatedVariableListColumns, (column) => column.isDefault),
  studies: {},
})
