import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "settings-table" }
const _hoisted_2 = { class: "settings-table__header-row row" }
const _hoisted_3 = { class: "col-auto settings-table row mx-3" }
const _hoisted_4 = { class: "col-7 settings-table__data-cell" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "col settings-table__data-cell text-right" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "col-auto toggle-switch" }
const _hoisted_11 = ["onUpdate:modelValue", "name", "onChange"]
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { class: "settings-table__data-row row align-items-center" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_16 = {
  key: 1,
  class: "text-success"
}
const _hoisted_17 = { class: "col-auto" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = { class: "col-auto text-right" }
const _hoisted_20 = ["disabled"]

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'
import _ from 'lodash'
import { computed, ComputedRef, onMounted, reactive, ref, watch } from 'vue'
import VueSlider from 'vue-slider-component'

import { VueSliderMark } from '@/misc/types'
import { useStore } from '@/store'
import { AppSettings } from '@/store/modules/settings/types'

import SancareSettingData from '../settings/sancareSettingsData'
import SettingData from '../settings/settingData'
import AppFinessInput from './AppFinessInput.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSettingsManager',
  props: {
    sancareOnly: { type: Boolean }
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const setAppSettingsAction = props.sancareOnly ? 'setSancareSettings' : 'setAppSettings'
const settingData = reactive(props.sancareOnly ? SancareSettingData : SettingData)
const boolSettings = ref({})
const sliderSettings = ref({})
const finessSettings = ref({})
const hasEdition = ref(false)
const error = ref(null)
const success = ref(false)
const showWarning = ref(false)
const warningMessage = ref('')

const appSettings = computed(() => props.sancareOnly ? store.state.settings.sancareSettings : store.state.settings.appSettings) as ComputedRef<AppSettings>
const setAppSettingsRequest = computed(() => props.sancareOnly ? store.state.settings.setSancareSettingsRequest : store.state.settings.setAppSettingsRequest) as ComputedRef<ApiRequest>

function updateLocalSettings(appSettings: AppSettings) {
  _.forEach(settingData, (category) => {
    _.forEach(category.settings, (setting) => {
      const settingName = setting.name
      switch (setting.type) {
        case 'bool':
          boolSettings.value[settingName] = appSettings.boolSettings[settingName] && appSettings.boolSettings[settingName].value !== null ? appSettings.boolSettings[settingName].value : appSettings.boolSettings[settingName].default
          break
        case 'slider':
          sliderSettings.value[settingName] = appSettings.sliderSettings[settingName] && appSettings.sliderSettings[settingName].index !== null ? appSettings.sliderSettings[settingName].index : appSettings.sliderSettings[settingName].default
          break
        case 'finess':
          finessSettings.value[settingName] = [...appSettings.arraySettings[settingName]]
          break
      }
    })
  })
}
function getMarks(settingName: string, value: number): VueSliderMark {  
  if(settingName === 'minimum_patient_validation') {
    const options = ['1', '2', '3']
    return { label: options[value] }
  } else {
    if (value === 0) {
      return { label: 'Quantité' }
    } else if (value === 1) {
      return { label: 'Équilibré' }
    } else {
      return { label: 'Qualité' }
    }
  }
}
function onEdit() {
  hasEdition.value = true
  error.value = null
  success.value = false
}
function clearEdition() {
  updateLocalSettings(appSettings.value)
  hasEdition.value = false
}
function saveEdition() {
  const editedSettings = {
    sliderSettings: [],
    boolSettings: [],
    arraySettings: [],
  }

  _.forEach(appSettings.value.sliderSettings, (setting, name) => {
    const settingValue = setting.index === null ? setting.default : setting.index
    if (sliderSettings.value[name] !== settingValue) {
      editedSettings.sliderSettings.push({ name: name, index: sliderSettings.value[name] })
    }
  })

  _.forEach(appSettings.value.boolSettings, (setting, name) => {
    const settingValue = setting.value === null ? setting.default : setting.value
    if (boolSettings.value[name] !== settingValue) {
      editedSettings.boolSettings.push({ name: name, value: boolSettings.value[name] })
    }
  })

  error.value = null
  warningMessage.value = ''
  showWarning.value = false
  store.dispatch(setAppSettingsAction, editedSettings).then(() => {
    updateLocalSettings(appSettings.value)
  })
}
function removeAppFiness(finess, settingName) {
  finessSettings.value[settingName] = finessSettings.value[settingName].filter((elem) => elem.finess !== finess)
  onEdit(settingName)
}
function addAppFiness({ finess, shortDescription, settingName }) {
  if (!finessSettings.value[settingName].find((elem) => elem.finess === finess)) {
    finessSettings.value[settingName].push({ finess, shortDescription })
    onEdit(settingName)
  }
}

onMounted(() => {
  updateLocalSettings(appSettings.value)
})

watch(() => setAppSettingsRequest, (req) => {
  if (req.value.ok) {
    hasEdition.value = false
    success.value = true
    error.value = null
    showWarning.value = false
    warningMessage.value = ''
  } else if (req.value.error) {
    error.value = req.value.error
  }
}, { deep:true })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(settingData, (modeSetting, mode) => {
        return (_openBlock(), _createElementBlock("div", { key: mode }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h2", null, _toDisplayString(modeSetting.label), 1)
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(modeSetting.settings, (setting) => {
            return (_openBlock(), _createElementBlock("div", {
              key: setting.name,
              class: "settings-table__data-row row"
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(setting.label) + " ", 1),
                (setting.type === 'finess')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(finessSettings.value[setting.name], (finess) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: finess['finess'],
                          class: "badge-primary badge col-auto mx-1 mb-1 pl-2 pr-2"
                        }, [
                          _createElementVNode("span", null, _toDisplayString(finess['shortDescription']) + " (" + _toDisplayString(finess['finess']) + ")", 1),
                          _createElementVNode("span", {
                            onClick: ($event: any) => (removeAppFiness(finess['finess'], setting.name))
                          }, [
                            _createVNode(_unref(SancareOcticon), {
                              name: "x",
                              width: 14,
                              height: 11
                            })
                          ], 8, _hoisted_6)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                (setting.type === 'slider' && appSettings.value.sliderSettings[setting.name])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_unref(VueSlider), {
                        modelValue: sliderSettings.value[setting.name],
                        "onUpdate:modelValue": ($event: any) => ((sliderSettings.value[setting.name]) = $event),
                        name: setting.name,
                        process: false,
                        "v-data": Array.from(appSettings.value.sliderSettings[setting.name].values.keys()),
                        marks: (value) => getMarks(setting.name, value),
                        tooltip: 'none',
                        class: "mr-3 mb-3",
                        onChange: ($event: any) => (onEdit(setting.name))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "v-data", "marks", "onChange"])
                    ]))
                  : (setting.type === 'bool')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("label", _hoisted_10, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": ($event: any) => ((boolSettings.value[setting.name]) = $event),
                            type: "checkbox",
                            name: setting.name,
                            onChange: ($event: any) => (onEdit(setting.name))
                          }, null, 40, _hoisted_11), [
                            [_vModelCheckbox, boolSettings.value[setting.name]]
                          ]),
                          _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1))
                        ])
                      ]))
                    : (setting.type === 'finess' && finessSettings.value[setting.name])
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createVNode(AppFinessInput, {
                            "setting-name": setting.name,
                            onSelectFiness: addAppFiness
                          }, null, 8, ["setting-name"])
                        ]))
                      : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (error.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(error.value), 1))
            : (success.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, " Les modifications ont bien été enregistrées "))
              : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("button", {
            disabled: !hasEdition.value || setAppSettingsRequest.value.fetching,
            name: "clearForm",
            class: "btn btn-push btn-primary btn-padded",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (clearEdition()))
          }, " Annuler les modifications ", 8, _hoisted_18)
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("button", {
            disabled: !hasEdition.value || setAppSettingsRequest.value.fetching,
            name: "submitForm",
            class: "btn btn-push btn-primary btn-padded",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (saveEdition()))
          }, " Enregistrer les modifications ", 8, _hoisted_20)
        ])
      ])
    ])
  ]))
}
}

})