import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "large-container" }
const _hoisted_2 = { class: "card patient-list-condition px-2" }
const _hoisted_3 = {
  key: 0,
  class: "py-2"
}
const _hoisted_4 = {
  key: 1,
  class: "loader loader-lg loader-center mb-4"
}
const _hoisted_5 = { class: "row col-12" }
const _hoisted_6 = { class: "col" }

import { computed, onMounted, onUnmounted, watch } from 'vue'

import router from '@/router'
import StayDetails from '@/stay-displayer/StayDetails.vue'
import { useStore } from '@/store'

import DocumentViewer from './DocumentViewer.vue'
import PatientTimeline from './PatientTimeline.vue'
import SideMenu from './SideMenu.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientPage',
  setup(__props) {

const store = useStore()

const patientIpp = computed(() => router.currentRoute.value.params.patientIpp)
const patient = computed(() => store.state.patient.currentPatient)
const patientRequest = computed(() => store.state.patient.requests.getOnePatient)
const currentStudy = computed(() => store.state.study.currentStudy)
const currentStudyStep = computed(() => store.state.study.currentStudyStep)
const currentStudyNode = computed(() => store.state.study.currentStudyNode)
const currentSearch = computed(() => store.state.search.currentSearch)
const patientListParams = computed(() => store.state.patient.patientListParams)
const showSideMenu = computed(() => (currentStudy.value && currentStudy.value.steps.length) || (currentSearch.value && currentSearch.value.conditionGroups.length))
const matchingStayIds = computed(() => store.state.patient.matchingStayIds)

async function refresh(first: boolean) {
  await store.dispatch('patient/getOnePatient', patientIpp.value)
  if (patientRequest.value.error !== null) {
    await router.push({ path: '/' })
    store.commit(
      'toast/setError',
      patientRequest.value.error.message || 'Une erreur est survenue pendant l\'accès au patient'
    )

    return
  }
  if (first) {
    store.dispatch('getCurrentReadingList')
  }
  store.commit(
    'addBreadcrumbEntry',
    {
      name: `Patient ${patient.value.ipp}`,
      customKey: '/patient/',
      routerLinkArg: location.pathname + location.search }
  )
  if (showSideMenu.value) {
    store.dispatch('patient/launchTextWorker', {
      patient: patient.value,
      study: currentStudyStep.value || currentStudyNode.value || patientListParams.value.studyNodeId === 'new' ? null : currentStudy.value,
      studyStep: currentStudyStep.value,
      studyNode: currentStudyNode.value,
      search: currentSearch.value.conditionGroups.length ? currentSearch.value : null,
      matchingStayIds: matchingStayIds.value,
      variables: patientListParams.value.variableId && currentSearch.value.conditionGroups.length ? [currentSearch.value] : (currentStudy.value.variables ?? [])
    })
  }
}

onMounted(async () => {
  const query = store.state.route.query

  if (query.studyId) {
    const studyId = query.studyId
    await store.dispatch('fetchStudy', { studyId })
    if (query.stepId) {
      store.commit('setCurrentStudyStep', Number(query.stepId))
    }
    if (patientListParams.value.studyNodeId) {
      store.commit('setCurrentStudyNode', Number(patientListParams.value.studyNodeId))
    }
  }

  refresh(true)

  // do not reload search/studyNode if edition ongoing
  if (Number(patientListParams.value.criterionId) && !(currentSearch.value && currentSearch.value.id === Number(patientListParams.value.criterionId))) {
    await store.dispatch('fetchCriterion', patientListParams.value.criterionId)
  } else if (Number(patientListParams.value.studyNodeId) && !(currentStudyNode.value && currentStudyNode.value.id === Number(patientListParams.value.studyNodeId))) {
    await store.dispatch('fetchStudyNode', patientListParams.value.studyNodeId)
  }
})

onUnmounted(() => {
  store.dispatch('patient/terminateTextWorker')
  store.commit('patient/resetPatientState')
})

watch(patientIpp, () => {
  if (patientIpp.value) {
    refresh(false)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (patient.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h2", null, "Patient " + _toDisplayString(patient.value.ipp), 1),
            _createVNode(PatientTimeline),
            _createVNode(StayDetails)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (showSideMenu.value)
        ? (_openBlock(), _createBlock(SideMenu, {
            key: 0,
            class: "col-2 px-2 card",
            criterion: currentSearch.value,
            study: currentStudy.value
          }, null, 8, ["criterion", "study"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(DocumentViewer)
      ])
    ])
  ]))
}
}

})