import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'
import { DrugEventPrestationType, HealthDataType } from '@sancare/ui-frontend-commons/src/types/health-data'

import { HealthData } from '@/store/modules/health-data/types'
import { Stay } from '@/store/modules/stay/types'
import { StudyNodeMatches } from '@/text-processing/types'

export enum StudyPatientFeedbackEnum {
  RELEVANT = 'relevant',
  IRRELEVANT = 'irrelevant',
  UNDECIDED = 'undecided',
}

export interface Patient {
  ipp: string
  birthYear: number
  gender: string
  stays: Stay[]
  feedback: StudyPatientFeedbackEnum
  isDeceased: boolean | null
  deceaseDate: string | null
}

export interface PatientListParams {
  criterionId?: number | string
  studyNodeId?: number | string
  variableId?: number | string
}

export enum PatientListColumnEnum {
  SYSTEM = 'system',
  VARIABLE = 'variable',
}

export interface PatientListColumn {
  position?: number
  type: PatientListColumnEnum
  id?: number
  name: string
  label: string
  isDefault: boolean
  invertedOrder: boolean
  icon?: string
  sortDisabled?: boolean
}

const storageData = sessionStorage.getItem('patientListParams')
export class PatientState extends AbstractState {
  currentPatient: Patient = null
  currentStay: Stay = null
  currentDocument: HealthData = null
  currentDrugEventPrestationType: DrugEventPrestationType = null
  currentDocumentType: HealthDataType = null
  matchingStayIds: number[] = []
  studyNodeMatches: StudyNodeMatches = { drugs: [], keywords: [] }
  variableMatches: StudyNodeMatches = { drugs: [], keywords: [] }
  patientListParams: PatientListParams = storageData ? JSON.parse(storageData) : {}
  textWorkerReady = false
}
