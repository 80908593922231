import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row mx-2" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "col" }

import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import { HealthDataType } from '@sancare/ui-frontend-commons/src/types/health-data'
import Chart from 'chart.js/auto'
import { Dayjs } from 'dayjs'
import _ from 'lodash'
import { computed, onUnmounted, ref, watch } from 'vue'

import { useStore } from '@/store'
import { Stay } from '@/store/modules/stay/types'

import { getStayLabel } from './StayLabel'


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientTimeline',
  setup(__props) {

const store = useStore()

const patient = computed(() => store.state.patient.currentPatient)
const stays = computed(() => store.state.patient.currentPatient.stays)

let chart = null
const datesInterval = ref(null)
const currentStay = computed(() => store.state.patient.currentStay)
const statsCanvas = ref(null)

function setCurrentStay(stay: Stay) {
  store.commit('patient/setCurrentStay', stay)
  store.commit('patient/setCurrentDocument', { document: stay.reports[0] ?? null, documentType: stay.reports[0] ? HealthDataType.REPORT : null })
}

function handleChartClick(evt) {
  const points = chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true)
  if (points.length) {
    const firstPoint = points[0]
    const contextStay = stays.value[firstPoint.datasetIndex]
    setCurrentStay(contextStay)
  }
}

function getOptions(): object {
  return {
    maintainAspectRatio: false,
    responsive: true,
    hover: { mode: 'nearest', intersect: true },
    plugins: {
      tooltip: {
        yAlign: 'bottom',
        callbacks: {
          label: (context) => {
            const contextStay = stays.value[context.datasetIndex]
            return getStayLabel(contextStay)
          }
        }
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Séjours du patient'
      }
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return `${value} j`
          },
          stepSize: 1,
        },
      },
    },
    onClick: handleChartClick
  }
}

function getDataSets() {
  const colorSet = ['#E331A9', '#0E3C6A', '#318BE3', '#31E1E3', '#FD6565', '#ED9821']
  const dataSets = []

  _.forEach(stays.value, (stay) => {
    const data = []
    _.forEach(datesInterval.value, (date, idx) => {
      data.push([])
      if (date.format('M YYYY') === dayjs(stay.stayEnd).format('M YYYY')) {
        data[idx] = stay.stayDuration > 0 ? stay.stayDuration : 1
      }
    })

    dataSets.push({
      label: stay.rssId,
      data: data,
      fill: true,
      borderColor: colorSet[dataSets.length % colorSet.length],
      backgroundColor: colorSet[dataSets.length % colorSet.length],
      borderWidth: 2,
      borderSkipped: false,
    })
  })

  return dataSets
}


function refreshChart() {
  if (chart !== null) {
    chart.destroy()
  }

  const canvas = statsCanvas.value.getContext('2d')

  chart = new Chart(canvas,
    {
      type: 'bar',
      data: {
        labels: _.map(datesInterval.value, (month) => month.format('MMMM YYYY')),
        datasets: getDataSets()
      },
      options: getOptions()
    }
  )
}

function getDates(): Dayjs[] {
  if (stays.value.length === 0) {
    return []
  }
  const dates = []
  if (!stays.value.length) {
    return dates
  }
  const startDate = dayjs(stays.value[0].stayStart)
  const endDate = dayjs(_.last(stays.value).stayEnd)

  let currentDate = dayjs(startDate).startOf('month')
  while (currentDate <= endDate) {
    dates.push(currentDate)
    currentDate = currentDate.add(1, 'month')
  }

  // we want a minimum of 6 months in order to have a timeline effect when only one stay
  const minMonthCount = 6
  let nbMonthToBeCreated = minMonthCount - dates.length
  while (nbMonthToBeCreated > 0) {
    dates.unshift(dayjs(dates[0]).add(-1, 'month'))
    dates.push(dayjs(dates[dates.length - 1]).add(1, 'month'))
    nbMonthToBeCreated -= 2
  }

  return dates
}

// immediate flag launch the watch handler before the dom being ready
let domReady = false
let patientChanged = false

watch(() => patient.value, (newValue, oldValue) => {
  if (!oldValue || newValue.ipp !== oldValue.ipp) {
    patientChanged = true
    if (domReady) {
      datesInterval.value = getDates()
      refreshChart()
    }
  }
}, {
  immediate: true,
})
watch(() => statsCanvas.value, () => {
  domReady = true
  if (patientChanged) {
    datesInterval.value = getDates()
    refreshChart()
  }
})
onUnmounted(() => {
  if (chart !== null) {
    chart.destroy()
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stays.value, (stay) => {
        return (_openBlock(), _createElementBlock("button", {
          key: stay.id,
          class: _normalizeClass([{ active: currentStay.value.id === stay.id }, "m-1 btn btn-sm btn-report-select"]),
          onClick: ($event: any) => (setCurrentStay(stay))
        }, _toDisplayString(_unref(getStayLabel)(stay)), 11, _hoisted_2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("canvas", {
        id: "patient-timeline",
        ref_key: "statsCanvas",
        ref: statsCanvas
      }, null, 512)
    ])
  ]))
}
}

})