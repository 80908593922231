import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-2 report-selector" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "row" }
const _hoisted_5 = ["onClick"]

import { getDrugEventPrestationTypeLabel, getDrugEventPrestationTypes } from '@sancare/ui-frontend-commons/src/misc/DrugEventHelper'
import { HealthDataType } from '@sancare/ui-frontend-commons/src/types/health-data'
import { computed } from 'vue'

import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentSelector',
  setup(__props) {

const store = useStore()

const currentStay = computed(() => store.state.patient.currentStay)
const currentDocument = computed(() => store.state.patient.currentDocument)
const currentDocumentType = computed(() => store.state.patient.currentDocumentType)
const currentDrugEventPrestationType = computed(() => store.state.patient.currentDrugEventPrestationType)
const drugEventPrestationTypes = computed(() => getDrugEventPrestationTypes(store.state.patient.currentStay.drugEvents))
function setCurrentDocument(document) {
  store.commit('patient/setCurrentDocument', document)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentStay.value.reports, (report) => {
        return (_openBlock(), _createElementBlock("button", {
          key: `report-${report.id}-selector`,
          class: _normalizeClass([{ active: currentDocument.value === report }, "btn btn-sm btn-report-select"]),
          onClick: ($event: any) => (setCurrentDocument({ document: report, documentType: _unref(HealthDataType).REPORT }))
        }, _toDisplayString(report.documentTitle), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (currentStay.value.labResults.length > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass([{ active: currentDocumentType.value === _unref(HealthDataType).LAB_RESULT }, "btn btn-sm btn-report-select"]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (setCurrentDocument({ document: null, documentType: _unref(HealthDataType).LAB_RESULT })))
          }, " Résultats de biologie ", 2))
        : _createCommentVNode("", true),
      (currentStay.value.healthConstants.length > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: _normalizeClass([{ active: currentDocumentType.value === _unref(HealthDataType).CONSTANT }, "btn btn-sm btn-report-select"]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (setCurrentDocument({ document: null, documentType: _unref(HealthDataType).CONSTANT })))
          }, " Constantes ", 2))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(drugEventPrestationTypes.value, (type) => {
        return (_openBlock(), _createElementBlock("button", {
          key: type,
          class: _normalizeClass([{ active: currentDocumentType.value === _unref(HealthDataType).DRUG_EVENT && currentDrugEventPrestationType.value === type }, "btn btn-sm btn-report-select"]),
          onClick: ($event: any) => (setCurrentDocument({ document: null, documentType: _unref(HealthDataType).DRUG_EVENT, drugEventPrestationType: type }))
        }, _toDisplayString(_unref(getDrugEventPrestationTypeLabel)(type)), 11, _hoisted_5))
      }), 128)),
      (currentStay.value.textualHealthEntries.length > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: _normalizeClass([{ active: currentDocumentType.value === _unref(HealthDataType).TEXTUAL_HEALTH_ENTRY }, "btn btn-sm btn-report-select"]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (setCurrentDocument({ document: null, documentType: _unref(HealthDataType).TEXTUAL_HEALTH_ENTRY })))
          }, " Rapports infirmiers ", 2))
        : _createCommentVNode("", true),
      (currentStay.value.namedEntities.length > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            class: _normalizeClass([{ active: currentDocumentType.value === _unref(HealthDataType).NAMED_ENTITY }, "btn btn-sm btn-report-select"]),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (setCurrentDocument({ document: null, documentType: _unref(HealthDataType).NAMED_ENTITY })))
          }, " Entités nommées ", 2))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})