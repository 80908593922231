import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-auto px-2 text-center home-thumbnail-header-item" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "row no-gutters align-items-center justify-content-between home-thumbnail-footer" }
const _hoisted_4 = { class: "col-2 mt-2 ml-1" }
const _hoisted_5 = {
  key: 0,
  class: "col"
}

import { SancareCheckbox } from '@sancare/ui-frontend-commons'
import { computed, ComputedRef, Ref, ref } from 'vue'

import { ThumbnailHeaderColor } from '@/home/types'
import { getAbbreviatedNumber } from '@/misc/NumberHelper'

const HOME_THUMBNAIL_MAX_LABEL_LENGTH = 64

// FIXME: Use HomeThumbnailProps as type when Vue version >= 3.3

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeThumbnail',
  props: {
    canClick: { type: Boolean, default: true },
    canSelect: { type: Boolean, default: false },
    emptyValue: { default: null },
    footer: { default: '' },
    headerItem: { default: null },
    isDisabled: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false },
    label: { default: '' }
  },
  emits: ['select', 'click'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const isSelected: Ref<boolean> = ref(props.isSelected)

const headerColor: ComputedRef<ThumbnailHeaderColor> = computed(() => {
  if (props.isDisabled) {
    return ThumbnailHeaderColor.DISABLED
  }
  if (isSelected.value) {
    return ThumbnailHeaderColor.SELECTED
  }
  return ThumbnailHeaderColor.UNIFORM
})

const displayedHeaderItem: ComputedRef<string | null> = computed(() => {
  if(typeof props.headerItem === 'number') {
    return getAbbreviatedNumber(props.headerItem)
  }
  return props.headerItem ?? props.emptyValue
})

const displayedLabel: ComputedRef<string>  = computed(() =>
  props.label.length > HOME_THUMBNAIL_MAX_LABEL_LENGTH
    ? `${props.label.substring(0, HOME_THUMBNAIL_MAX_LABEL_LENGTH)}...`
    : props.label,
)

function select() {
  if (props.canSelect && !props.isDisabled) {
    emit('select')
    isSelected.value = !isSelected.value
  }
}

function handleClick() {
  if (props.canClick && !props.isDisabled) {
    emit('click')
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`home-thumbnail home-thumbnail-color-${headerColor.value} m-1`)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["row no-gutters align-items-center home-thumbnail-header", { 'home-thumbnail-header-not-clickable': !_ctx.canClick }]),
      onClick: handleClick
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(displayedHeaderItem.value), 1),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            title: _ctx.label,
            class: "col px-2 text-right home-thumbnail-label"
          }, _toDisplayString(displayedLabel.value), 9, _hoisted_2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "info")
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.canSelect)
          ? (_openBlock(), _createBlock(_unref(SancareCheckbox), {
              key: 0,
              checked: isSelected.value,
              onChange: select
            }, null, 8, ["checked"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.footer), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "footer")
    ])
  ], 2))
}
}

})