import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "large-container" }

import { computed, onMounted } from 'vue'

import { studyPermissions } from '@/misc/permissions/permissionsHelper'
import { criterionListColumns } from '@/patient-list/PatientListColumns'
import { useRouter } from '@/router'
import SearchEditionLayout from '@/search/SearchEditionLayout.vue'
import { useStore } from '@/store'
import { Criterion, SearchTypeEnum } from '@/store/modules/search/types'


import { ComponentPublicInstance, defineComponent } from 'vue'
import { Router } from 'vue-router'
import { Store } from 'vuex'

import { studyNodePermissions, studyStepPermissions } from '@/misc/permissions/permissionsHelper'
import { User } from '@/settings/types'
import { Study, StudyNode, StudyStep } from '@/store/modules/study/types'
import { RootState } from '@/store/types'

interface IInstance extends ComponentPublicInstance {
  router: Router
  currentStudy: Study
  currentStudyStep: StudyStep
  currentStudyNode: StudyNode
  studyNodeId: number | null
  currentUser: User
  store: Store<RootState>
  fetchStudy: () => Promise<void>
  fetchStudyRequest: {
    fetching: boolean
    error: string | null
    ok: boolean
  }
  fetchStudyNode: () => Promise<void>
  fetchStudyNodeRequest: {
    fetching: boolean
    error: string | null
    ok: boolean
  }
}

const __default__ = defineComponent({
  beforeRouteEnter(to, from, next) {
    next(async (vm: IInstance) => {
      const isEdition = vm.studyNodeId !== null

      await vm.fetchStudy()
      if (vm.fetchStudyRequest.error) {
        return
      }

      if (isEdition) {
        await vm.fetchStudyNode()
        if (vm.fetchStudyNodeRequest.error) {
          return
        }
      }

      // TODO: Check if param step exists in study
      // TODO: Check if edited study node exists in param step

      const { currentStudyNode, currentUser, currentStudyStep, currentStudy } = vm

      if (isEdition && !studyNodePermissions.canEdit(currentStudyNode, currentUser, currentStudyStep, currentStudy)) {
        await vm.router.push({ path: '/' })
        vm.store.commit('toast/setError', 'Vous ne pouvez pas modifier ce critère d\'étude')
      } else if (!studyStepPermissions.canEdit(currentStudyStep, currentUser, currentStudy)) {
        await vm.router.push({ path: '/' })
        vm.store.commit('toast/setError', 'Vous ne pouvez pas créer de critère pour cette étude')
      }
    })
  },
})

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'StudyNodeEditorPage',
  setup(__props, { expose: __expose }) {

const router = useRouter()
const store = useStore()

const studyNodeId = computed(() => router.currentRoute.value.params.studyNodeId && Number(router.currentRoute.value.params.studyNodeId))
const parentId = computed(() => router.currentRoute.value.query.parentId && Number(router.currentRoute.value.query.parentId)) // parent of new studyNode
const studyId = computed(() => router.currentRoute.value.query.studyId && Number(router.currentRoute.value.query.studyId))
const stepId = computed(() => router.currentRoute.value.query.stepId && Number(router.currentRoute.value.query.stepId))

const fetchStudyRequest = computed(() => store.state.study.request.fetchStudy)
const addStudyNodeRequest = computed(() => store.state.study.request.addStudyNode)
const editStudyNodeRequest = computed(() => store.state.criteria.editCriterionRequest)
const fetchStudyNodeRequest = computed(() => store.state.study.request.fetchStudyNode)

const backgroundJobsEnabled = computed(() => store.state.settings.sancareSettings.boolSettings.enable_compute_study_jobs.value)
const currentUser = computed(() => store.state.settings.currentUser)
const currentStudyNode = computed(() => store.state.study.currentStudyNode)
const currentStudy = computed(() => store.state.study.currentStudy)
const currentStudyStep = computed(() => store.state.study.currentStudyStep)

const canShowPatientList = computed(() => studyPermissions.canShowPatientList(currentStudy.value, currentUser.value))

onMounted(() => {
  store.commit(
    'addBreadcrumbEntry',
    {
      name: 'Édition de critère d\'étude',
      customKey: '/study-node/',
      routerLinkArg: location.pathname + location.search
    },
  )
  store.commit('setPatientListColumns', criterionListColumns)
})

async function upsertStudyNode(studyNode: Criterion) {
  if (studyNodeId.value) {
    store.commit('updateStudyCriterion', { studyNodeId: studyNodeId.value, criterion: studyNode })
  } else {
    store.commit('addStudyCriterion', { parentId: parentId.value, criterion: studyNode })
  }
  await store.dispatch('updateStudy')
  router.replace({ name: 'study', params: { studyId: studyId.value } })
}

function refreshPatients() {
  if (backgroundJobsEnabled.value) {
    return
  }
  if (canShowPatientList.value) {
    store.dispatch('searchPatients', stepId.value)
  } else {
    store.dispatch('countPatientSearch', stepId.value)
  }
}

async function fetchSearch() {
  await fetchStudyNode()
  refreshPatients()
}

async function fetchStudyNode() {
  if (studyNodeId.value && !(currentStudyNode.value && currentStudyNode.value.id === studyNodeId.value)) {
    await store.dispatch('fetchStudyNode', studyNodeId.value)
    store.commit('setCurrentSearchFromStudyNode', currentStudyNode.value)
  }
}

async function fetchStudy() {
  await store.dispatch('fetchStudy', { studyId: studyId.value })
  store.commit('setCurrentStudyStep', stepId.value)
}

__expose({ router, currentStudy, currentStudyStep, studyNodeId, currentStudyNode, currentUser, store, fetchStudy, fetchStudyRequest, fetchStudyNode, fetchStudyNodeRequest })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SearchEditionLayout, {
      "search-id": studyNodeId.value,
      "parent-id": parentId.value,
      "study-id": studyId.value,
      "can-show-patient-list": canShowPatientList.value,
      "add-request": addStudyNodeRequest.value,
      "edit-request": editStudyNodeRequest.value,
      "fetch-request": fetchStudyNodeRequest.value,
      "search-type": _unref(SearchTypeEnum).CRITERION,
      onUpsertSearch: upsertStudyNode,
      onFetchSearch: fetchSearch,
      onRefreshPatients: refreshPatients
    }, null, 8, ["search-id", "parent-id", "study-id", "can-show-patient-list", "add-request", "edit-request", "fetch-request", "search-type"])
  ]))
}
}

})