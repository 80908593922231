import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "report-content"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "text-data-content" }

import { computed } from 'vue'

import { getChunkClass } from '@/misc/JustificationHelper'
import { useStore } from '@/store'
import { Report } from '@/store/modules/health-data/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportViewer',
  props: {
    report: {},
    isDocumentSource: { type: Boolean }
  },
  setup(__props: any) {

const store = useStore()



const textWorkerReady = computed(() => store.state.patient.textWorkerReady)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'highlight-on-click' : _ctx.isDocumentSource })
  }, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.report.documentTitle), 1),
    (_ctx.report.chunks && textWorkerReady.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.report.chunks, (chunk) => {
            return (_openBlock(), _createElementBlock("span", {
              key: chunk.hash,
              class: _normalizeClass([_unref(getChunkClass)(chunk), "text-data-content"])
            }, _toDisplayString(chunk.string), 3))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.report.content), 1)
        ]))
  ], 2))
}
}

})