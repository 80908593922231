import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "large-container" }
const _hoisted_2 = { class: "card patient-list-condition" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { key: 0 }

import { SancarePagination } from '@sancare/ui-frontend-commons'
import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'
import { computed, onMounted } from 'vue'
import { RouteLocationNormalizedLoaded } from 'vue-router'

import ListParameters from '@/criteria/ListParameters.vue'
import PatientListCount from '@/patient-list/PatientListCount.vue'
import PatientListTable from '@/patient-list/PatientListTable.vue'
import { ReadingListQuery } from '@/patient-list/types'
import router from '@/router'
import SearchEditor from '@/search/SearchEditor.vue'
import { useStore } from '@/store'
import { PatientListColumn } from '@/store/modules/patient/types'
import { Criterion, SearchTypeEnum } from '@/store/modules/search/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchEditionLayout',
  props: {
    parentId: { default: null },
    studyId: { default: null },
    canShowPatientList: { type: Boolean },
    addRequest: {},
    editRequest: {},
    fetchRequest: {},
    searchType: {}
  },
  emits: ['refresh-patients', 'upsert-search', 'fetch-search'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const emit = __emit

const props = __props

const stepId = computed(() => router.currentRoute.value.query.stepId && Number(router.currentRoute.value.query.stepId))

const patientList = computed(() => props.canShowPatientList ? store.state.patientList.patientList : [])
const patientCount = computed(() => store.state.patientList.patientCount)
const pageCount = computed(() => store.state.patientList.pageCount)
const currentPage = computed(() => store.state.patientList.patientListParams.currentPage)
const params = computed(() => store.state.patientList.patientListParams)
const fetchPatientListRequest = computed(() => store.state.patientList.fetchPatientListRequest)
const currentSearch = computed(() => store.state.search.currentSearch)
const displayedColumns = computed(() => props.searchType === SearchTypeEnum.VARIABLE ? store.getters.getUserVariableColumnsSettings(currentSearch.value.aggregateCondition) : store.getters.getUserColumnsSettings(props.studyId))

function refreshPatients() {
  emit('refresh-patients')
}

function refreshPatientList(resetPatientSelection: boolean, refreshUrl: boolean) {
  refreshPatients()
  if (refreshUrl) {
    const urlParams = {
      ...store.getters['shortHandPatientListParams'],
    }
    if (props.parentId) {
      urlParams.parentId = props.parentId
    }
    if (stepId.value) {
      urlParams.stepId = stepId.value
    }
    if (props.studyId) {
      urlParams.studyId = props.studyId
    }
    router.replace({ path: store.state.route.path, query: urlParams })
  }
}
function setColumns(columns: { [key: string]: PatientListColumn }) {
  if (props.searchType === SearchTypeEnum.VARIABLE) {
    store.commit('setVariableDisplayedColumns', { columns, isAggregated: currentSearch.value.aggregateCondition })
  } else {
    store.commit('setDisplayedColumns', { columns, studyId: props.studyId })
  }
}
function setParameter(type: string, value: string | number) {
  store.commit('setPatientListParam', { type: type, value: value })
  refreshPatientList(type !== 'pageSize', true)
}
function setPage(value: number) {
  setParameter('currentPage', value)
}
async function gotoPatientPage(forceNewWindow: boolean, newRoute: RouteLocationNormalizedLoaded) {
  const query = {
    search: currentSearch.value
  } as ReadingListQuery
  if (stepId.value) {
    query.stepId = stepId.value
  }
  const initIndex = (currentPage.value - 1) * params.value.pageSize + patientList.value.findIndex((p) => p.ipp === newRoute.params.patientIpp)
  await store.dispatch('createReadingList', { query, initIndex })
  if (forceNewWindow) {
    window.open(router.resolve(newRoute).href, '_blank')
  } else {
    router.push(newRoute)
  }
}

function upsertSearch(search: Criterion) {
  emit('upsert-search', search)
}

onMounted(() => {
  emit('fetch-search')
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PatientListCount, { "patient-count": patientCount.value }, null, 8, ["patient-count"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.canShowPatientList)
          ? (_openBlock(), _createBlock(ListParameters, {
              key: 0,
              "show-datepickers": false,
              onSetListParam: setParameter,
              onRefreshList: refreshPatientList
            }))
          : _createCommentVNode("", true),
        _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
        _createVNode(SearchEditor, {
          "parent-id": _ctx.parentId,
          "study-id": _ctx.studyId,
          "add-request": _ctx.addRequest,
          "edit-request": _ctx.editRequest,
          "fetch-request": _ctx.fetchRequest,
          "search-type": _ctx.searchType,
          onUpsertSearch: upsertSearch,
          onRefreshPatients: refreshPatients
        }, null, 8, ["parent-id", "study-id", "add-request", "edit-request", "fetch-request", "search-type"])
      ])
    ]),
    (_ctx.canShowPatientList)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(PatientListTable, {
            "displayed-columns": displayedColumns.value,
            "patient-list": patientList.value,
            order: params.value.order,
            "reverse-order": params.value.reverseOrder,
            "fetch-patient-list-request": fetchPatientListRequest.value,
            onRefreshList: refreshPatientList,
            onGotoPatientPage: gotoPatientPage,
            onSetColumns: setColumns
          }, null, 8, ["displayed-columns", "patient-list", "order", "reverse-order", "fetch-patient-list-request"]),
          _createVNode(_unref(SancarePagination), {
            "page-count": pageCount.value,
            "current-page": currentPage.value,
            onSetPage: setPage
          }, null, 8, ["page-count", "current-page"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})