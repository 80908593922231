import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "m-1" }
const _hoisted_2 = { class: "row align-items-center" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = {
  key: 0,
  class: "col-auto mr-1 text-right align-self-start"
}
const _hoisted_5 = { class: "mt-1" }
const _hoisted_6 = {
  key: 0,
  class: "row m-1"
}
const _hoisted_7 = { class: "col-auto" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = { class: "input-group input-group-rounded" }
const _hoisted_11 = ["value"]

import { ActionButton } from '@sancare/ui-frontend-commons'
import { ButtonState } from '@sancare/ui-frontend-commons/src/components/types'
import _ from 'lodash'
import { computed, Ref, ref } from 'vue'

import PatientListCount from '@/patient-list/PatientListCount.vue'
import router from '@/router.js'
import { useStore } from '@/store'
import { Study } from '@/store/modules/study/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyGeneralInfo',
  props: {
    canShowPatients: { type: Boolean },
    currentStudy: {}
  },
  setup(__props: any) {

const props = __props

const store = useStore()

const samplePatientsNb = ref(-1)

const backgroundJobsEnabled = computed(() => store.state.settings.sancareSettings.boolSettings.enable_compute_study_jobs.value)
const readingList = computed(() => store.state.patientList.readingList)
const studyId = computed(() => props.currentStudy.id)
const currentStudyStats = computed(() => store.state.study.currentStudyStats)
const patientCount = computed(() => store.state.study.patientCount)
const createStudyComputeJobRequest = computed(() => store.state.job.requests.createStudyComputeJob)
const samplePatientsOptions = computed(() => {
  const arr = [5, 10, 20, 30, 40, 50, 100]
  return arr.filter((option) => option < patientCount.value)
})

const createComputeStudyBtnStatus = ref(null) as Ref<ButtonState | null>

async function createComputeStudyJob() {
  createComputeStudyBtnStatus.value = ButtonState.LOADING
  try {
    await store.dispatch('job/createStudyComputeJob', studyId.value)
    createComputeStudyBtnStatus.value = ButtonState.SUCCESSFUL
  } catch (err) {
    createComputeStudyBtnStatus.value = ButtonState.FAILED
  }
}

function getLastStepPatientCount() {
  if (currentStudyStats.value === null) {
    return patientCount.value
  }
  const stats = currentStudyStats.value
  const lastKey = _.findLastKey(stats)
  if (lastKey && stats[lastKey]) {
    return stats[lastKey].patientCount
  }
  return patientCount.value
}

async function gotoSamplePatients() {
  store.commit('setCurrentStudyStep', null)
  if (samplePatientsNb.value === -1) {
    router.push({ name: 'patientList', query: { studyId: props.currentStudy.id } })
    return
  }
  const query = { studyId: props.currentStudy.id }
  await store.dispatch('createReadingList', {
    query,
    initIndex: 0,
    sample: { limit: samplePatientsNb.value, seed: Math.random() }
  })
  const newRoute = {
    name : 'patient',
    params: { patientIpp: readingList.value.current },
    query,
  }
  router.push(newRoute)
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-uppercase" }, "Nombre de patients de l'étude ", -1)),
        _createVNode(PatientListCount, {
          class: "m-1",
          "patient-count": getLastStepPatientCount()
        }, null, 8, ["patient-count"])
      ]),
      (backgroundJobsEnabled.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_unref(ActionButton), {
              label: "Recalculer",
              title: "Lancer le calcul de l'étude",
              disabled: createStudyComputeJobRequest.value.fetching,
              status: createComputeStudyBtnStatus.value,
              onClick: createComputeStudyJob
            }, null, 8, ["disabled", "status"]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_router_link, {
                to: {name: 'jobs', params: {studyId: studyId.value}}
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Voir les tâches ")
                ])),
                _: 1
              }, 8, ["to"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.canShowPatients && patientCount.value > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createElementVNode("select", {
                    id: "samplePatient",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((samplePatientsNb).value = $event)),
                    class: "form-control p-0"
                  }, [
                    _cache[3] || (_cache[3] = _createElementVNode("option", { value: -1 }, " Tous ", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(samplePatientsOptions.value, (option) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: option,
                        value: option
                      }, _toDisplayString(option), 9, _hoisted_11))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, samplePatientsNb.value]
                  ]),
                  _createElementVNode("button", {
                    class: "btn p-1",
                    onClick: gotoSamplePatients
                  }, _toDisplayString(samplePatientsNb.value === -1 ? 'Voir la liste des patients' : 'Voir l\'échantillon aléatoire'), 1)
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})