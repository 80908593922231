import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row text-left" }
const _hoisted_2 = { class: "text-left px-1 mt-2 py-1" }
const _hoisted_3 = { class: "row mx-1" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["checked", "disabled"]
const _hoisted_6 = { class: "col my-0 patient-list-settings-label" }
const _hoisted_7 = { class: "row mx-1 py-1 text-right" }
const _hoisted_8 = { class: "col-auto" }

import { SancareModal, SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { ref } from 'vue'

import { patientIdColumnName } from '@/patient-list/PatientListColumns'
import { PatientListColumn, PatientListColumnEnum } from '@/store/modules/patient/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientListSettings',
  props: {
    userColumns: {},
    displayedColumns: {}
  },
  emits: ['set-columns'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit
const props = __props

const showModal = ref(false)
const enabledColumns = ref({})

function closeModal() {
  showModal.value = false
}
function openModal() {
  showModal.value = true
  enabledColumns.value = { ...props.displayedColumns }
}
function isChecked(columnName: string): boolean {
  return _.isObject(enabledColumns.value[columnName])
}
function toggleColumn(columnName: string, column: PatientListColumn) {
  if (isChecked(columnName)) {
    enabledColumns.value = _.pickBy(enabledColumns.value, function(enabledColumn) {
      return column.type === PatientListColumnEnum.SYSTEM ? enabledColumn.name !== column.name : enabledColumn.id !== column.id
    })
  } else {
    enabledColumns.value[columnName] = column
  }
}
function setColumns() {
  emit('set-columns', enabledColumns.value)
  closeModal()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", { onClick: openModal }, [
      _createVNode(_unref(SancareOcticon), {
        name: "gear",
        width: 15,
        height: 15
      })
    ]),
    _createVNode(_unref(SancareModal), {
      open: showModal.value,
      "no-padding": true,
      onCloseModal: closeModal
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_1, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sancare-modal-title col" }, "Paramètres de la liste de patients", -1)),
            _createElementVNode("span", {
              class: "sancare-modal-close col-auto",
              onClick: closeModal
            }, [
              _createVNode(_unref(SancareOcticon), {
                name: "x",
                width: 20,
                height: 20
              })
            ])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "row sancare-modal-subtitle mx-1 mb-4" }, " Colonnes affichées ", -1)),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userColumns, (columnInfo, columnName) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'enable_'+columnInfo.name+'_column',
                class: "row col-6 mx-0 px-0 my-1 align-items-center patient-list-settings-line",
                onClick: ($event: any) => (toggleColumn(columnName, columnInfo))
              }, [
                _createElementVNode("input", {
                  checked: _unref(patientIdColumnName) === columnName || isChecked(columnName),
                  disabled: _unref(patientIdColumnName) === columnName,
                  type: "checkbox",
                  class: "col-auto mx-1 my-0"
                }, null, 8, _hoisted_5),
                _createElementVNode("label", _hoisted_6, _toDisplayString(columnInfo.label), 1)
              ], 8, _hoisted_4))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "col" }, null, -1)),
            _createElementVNode("span", _hoisted_8, [
              _createElementVNode("button", {
                class: "btn btn-push btn-primary btn-padded",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (setColumns()))
              }, " Valider ")
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["open"])
  ]))
}
}

})