import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'
import { BaseChunk } from '@sancare/ui-frontend-commons/src/types/health-data'

export interface AnnotatedChunk extends Omit<BaseChunk, 'isDoubt' | 'isNegative'> {
  annotationValues: string[]
}

export enum SourceType {
  AI = 'ai',
  USER = 'user',
}

export enum DocumentEntityExplorerValueType {
  ANNOTATION = 'annotation',
  HINT = 'hint',
  POST_PROCESSING = 'postProcessing',
}

export interface Hint {
  id: string
  startIdx: number
  endIdx: number
  source: SourceType
  removed: boolean
}

export interface AnnotatedHint extends Hint {
  // Not used for now
  annotationValues: string[]
}

export interface PostProcessingValues {
  postProcessedValue: string
  normalizedValue: string
  isRemoved: boolean
}

// TODO : Change interface properties name when the backend is ready (https://app.clickup.com/t/2628858/WEB-3595)
export interface Annotation {
  id?: string
  value: string
  hints: Hint[]
  source: SourceType
  removed: boolean
  postProcessingValues?: PostProcessingValues
}

export interface AnnotatedEntity {
  entityId: string
  entityName: string
  annotations: Annotation[]
  validated: boolean
}

export interface AnnotatedDocument {
  documentContent: string
  documentTitle: string
  documentId: number
  entities: AnnotatedEntity[]
}

export class DocumentEntityExplorerState extends AbstractState {
  documents: AnnotatedDocument[]
  currentDocument: AnnotatedDocument | null
  nbDocuments: number
  currentEntityId: string | null
}
