<script>
import {
  useAutoCompletionDebouncedWatcher
} from '@sancare/ui-frontend-commons/src/misc/composables/useAutoCompletionDebouncedWatcher'
import { ref, watchEffect } from 'vue'
import { mapState } from 'vuex'

import { generateType, initializeSubFiltersValues } from '@/filters/utils'

export default {
  props: {
    filterData: { type: Object, required: true },
  },
  emits: ['add-filter'],
  data() {
    return {
      showCompletion: false,
      subFiltersValues: initializeSubFiltersValues(this.filterData),
      typedInput: '',
    }
  },
  computed: mapState({
    completionItems: (state) => state.medicalUnitFilter.completionItems,
  }),
  watch: {
    filterType() {
      this.$store.commit('medicalUnitFilter/clearCompletionItems')
      this.typedInput = ''
    },
  },
  mounted() {
    const typedInputRef = ref(this.typedInput)
    const completionItemsRef = ref(this.completionItems)

    useAutoCompletionDebouncedWatcher(
      typedInputRef,
      completionItemsRef,
      (search, signal) => this.fetchCompletionItems(search, signal),
      () => this.clearCompletionItems()
    )

    watchEffect(() => {
      typedInputRef.value = this.typedInput
    })
  },
  methods: {
    selectItem(completionItem) {
      const name = this.filterData.name
      const value = this.filterData.name !== 'drug' ? completionItem.reference : completionItem.atc
      const type = generateType(this.filterData.format, this.subFiltersValues)

      this.closeCompletion()
      this.$emit('add-filter', { name, type, value })
      this.typedInput = ''
    },
    addFilter() {
      const value = this.typedInput.trim()
      this.typedInput = ''
      if (!value.length) {
        return
      }

      const name = this.filterData.name
      const type = generateType(this.filterData.format, this.subFiltersValues)
      this.closeCompletion()
      this.$emit('add-filter', { name, type, value })
    },
    openCompletion() {
      this.showCompletion = true
    },
    closeCompletion() {
      this.showCompletion = false
    },
    fetchCompletionItems(search, signal) {
      this.$store.dispatch('medicalUnitFilter/getCompletionItems', {
        url: '/api/stay-criterion-condition/completion',
        urlParams: {
          type: this.filterData.completionName,
          search,
        },
        signal,
      })
    },
    clearCompletionItems() {
      this.$store.commit('medicalUnitFilter/clearCompletionItems')
    },
  },
}
</script>

<template>
  <div>
    <div class="row">
      <div
        v-if="filterData && filterData.subFilters && filterData.subFilters.length"
        class="col-6 input-group"
      >
        <select
          v-for="filter in filterData.subFilters"
          :key="filter.name"
          v-model="subFiltersValues[filter.name]"
          class="custom-select form-control"
        >
          <option
            v-for="opt in filter.options"
            :key="opt"
            :value="opt"
          >
            {{ filter.labels[opt] ? filter.labels[opt] : opt }}
          </option>
        </select>
      </div>
      <div class="col-6 input-group">
        <input
          v-model="typedInput"
          v-on-click-outside="closeCompletion"
          class="form-control"
          @click="openCompletion"
          @keydown.esc="closeCompletion"
        >
        <div class="input-group-append">
          <button
            class="btn btn-primary"
            type="button"
            @click="addFilter"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
    <div class="row autocompletion-container">
      <div
        v-if="showCompletion && completionItems.length"
        class="autocompletion autocompletion--padded"
      >
        <div
          v-for="completionItem in completionItems"
          :key="completionItem.reference"
          class="autocompletion__item"
          @click="selectItem(completionItem)"
        >
          <span v-if="filterData.name === 'drug'">
            <div class="autocompletion__item-title">{{ completionItem.atc }}</div>
            <div class="autocompletion__item-subtitle">{{ completionItem.atcLabel }}</div>
          </span>
          <span v-else-if="filterData.name === 'biology'">
            <div class="autocompletion__item-title">{{ completionItem.code }}</div>
            <div class="autocompletion__item-subtitle">{{ completionItem.title }}</div>
          </span>
          <span v-else>
            <div class="autocompletion__item-title">{{ completionItem.reference }}</div>
            <div class="autocompletion__item-subtitle">{{ completionItem.description }}</div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
