import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row align-items-end h1" }
const _hoisted_2 = { class: "col-auto text-left" }
const _hoisted_3 = { name: "patientList_count" }
const _hoisted_4 = {
  key: 0,
  class: "col text-right"
}
const _hoisted_5 = { class: "undertitle" }


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientListCount',
  props: {
    patientCount: {},
    studyName: {}
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.patientCount === -1 ? '< 10' : _ctx.patientCount), 1),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "undertitle" }, " patients ", -1))
    ]),
    (_ctx.studyName)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.studyName), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})