import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  id: "side-menu",
  class: "pt-0"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "text-center my-3 text-uppercase d-flex align-items-center justify-content-center gap-2 flex-wrap" }
const _hoisted_4 = {
  key: 0,
  class: "loader loader-sm mt-0"
}
const _hoisted_5 = {
  key: 1,
  class: "mt-3 border-top"
}
const _hoisted_6 = { class: "text-center my-3 text-uppercase" }

import _ from 'lodash'
import { computed, onMounted, Ref, ref } from 'vue'

import { useStore } from '@/store'
import { Criterion, SearchInterface } from '@/store/modules/search/types'
import { Study, StudyNode } from '@/store/modules/study/types'
import { StudyNodeMatches } from '@/text-processing/types'

import CriterionDisplayer from '../criteria/CriterionDisplayer.vue'
import { convertStudyNodeToSearch } from '../study/utils'

type ConvertedSearch = Criterion & {
  studyNodeIds?: number[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SideMenu',
  props: {
    criterion: {},
    study: {}
  },
  setup(__props: any) {

const store = useStore()
const props = __props

function extractJustifyingNodeIds(matchingNodes: StudyNodeMatches): number[] {
  let ids = []
  if (!matchingNodes) {
    return ids
  }
  matchingNodes.keywords.forEach((matchingNode) => {
    if (matchingNode.condition.matching) {
      ids = ids.concat(matchingNode.condition.studyNodeIds)
    }
  })
  matchingNodes.drugs.forEach((matchingNode) => {
    if (matchingNode.condition.matching) {
      ids = ids.concat(matchingNode.condition.studyNodeIds)
    }
  })

  return _.uniq(ids)
}

const textWorkerReady = computed(() => store.state.patient.textWorkerReady)
const patientListParams = computed(() => store.state.patient.patientListParams)
const studyNodeMatches = computed(() => store.state.patient.studyNodeMatches)
const variableMatches = computed(() => store.state.patient.variableMatches)
const justifyingStudyNodeIds = computed(() => extractJustifyingNodeIds(studyNodeMatches.value))
const justifyingVariableIds = computed(() => extractJustifyingNodeIds(variableMatches.value))
const isStudyScope = computed(() => Boolean(store.state.route.query.studyId))
const softAbsentContents = computed(() => props.criterion?.globalConditionGroup?.conditionList?.map((c) => c.value) ?? [])

const nodes: Ref<ConvertedSearch[]> = ref([])
const variables: Ref<SearchInterface[]> = ref([])

function extractStudyNodes(children: StudyNode[]) {
  _.forEach(children, (child) => {
    if (child.name) {
      const convertedSearch = convertStudyNodeToSearch(child)
      convertedSearch.studyNodeIds = child.children.map((c) => c.id)
      nodes.value.push(convertedSearch)
    } else {
      extractStudyNodes(child.children)
    }
  })
}

onMounted(() => {
  if (props.criterion && props.criterion.conditionGroups.length) {
    if (patientListParams.value.criterionId || patientListParams.value.studyNodeId) {
      nodes.value.push(props.criterion)
      return
    } else if (patientListParams.value.variableId) {
      variables.value.push(props.criterion)
      return
    }
  }

  let children = [] as StudyNode[]

  // If patient list is from a step, we limit the nodes to selected step
  if (store.state.route.query.stepId) {
    const step = _.find(props.study.steps, { id: _.toNumber(store.state.route.query.stepId) })
    children = step.rootNode.children
  } else if (isStudyScope.value) {
    const rootNodes = _.map(props.study.steps, 'rootNode')
    children = _.flatMap(rootNodes, 'children')
  }
  extractStudyNodes(children)
  variables.value = props.study.variables
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (nodes.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("span", null, "Critères recherchés", -1)),
            (!textWorkerReady.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nodes.value, (node, nodeIdx) => {
            return (_openBlock(), _createElementBlock("div", { key: nodeIdx }, [
              _createElementVNode("div", {
                class: _normalizeClass(["my-2 menu-node", (!_ctx.criterion || _ctx.criterion.conditionGroups.length === 0)
            && justifyingStudyNodeIds.value.filter(justifNodeId => node.studyNodeIds.includes(justifNodeId)).length ? 'highlighted' : ''])
              }, [
                _createVNode(CriterionDisplayer, {
                  criterion: node,
                  "soft-absent-contents": softAbsentContents.value
                }, null, 8, ["criterion", "soft-absent-contents"])
              ], 2)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (variables.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, " Variable" + _toDisplayString(isStudyScope.value ? 's de l\'étude' : ''), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(variables.value, (variable, variableIdx) => {
            return (_openBlock(), _createElementBlock("div", { key: variableIdx }, [
              _createElementVNode("div", {
                class: _normalizeClass(["my-2 menu-node", textWorkerReady.value && justifyingVariableIds.value.includes(variable.id) ? 'highlighted' : ''])
              }, [
                _createVNode(CriterionDisplayer, {
                  criterion: variable,
                  "soft-absent-contents": softAbsentContents.value
                }, null, 8, ["criterion", "soft-absent-contents"])
              ], 2)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})