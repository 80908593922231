import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { name: "ConditionGroupDisplayer" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "col-5 px-2 condition-label" }
const _hoisted_4 = { class: "col row no-gutters px-0 py-1" }
const _hoisted_5 = { class: "col-auto mx-1 mb-1 pl-2 pr-2 badge badge-primary text-wrap" }

import { computed } from 'vue'

import { useStore } from '@/store'
import { Condition } from '@/store/modules/search/types'
import { ConditionModifiers } from '@/study/types'
import { AggregationModifiers } from '@/variables/types'

import { getAggregationLabel, getConditionLabel, getCriteriaLabel, getModifiers, renderCriteria } from './FilterUtils'
import MultipleValueFilter from './MultipleValueFilter.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ConditionDisplayer',
  props: {
    canRemoveCondition: { type: Boolean },
    condition: { default: null },
    maxConditions: { default: 0 },
    modifiers: { default: null }
  },
  emits: ['remove-condition'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const emit = __emit
const props = __props

const healthConstantsList = computed(() => store.state.medicalUnitFilter.remoteLists['constants'])
const namedEntitiesList = computed(() => store.state.medicalUnitFilter.remoteLists['namedEntities'])
const currentStudy = computed(() => store.state.study.currentStudy)
const variablesList = computed(() => currentStudy.value.variables ?? [])
const stepNames = computed(() => {
  return store.getters.stepNames
})
const conditionMod = computed((): ConditionModifiers => {
  return {
    condition: props.condition,
    type: props.condition.type,
    label: getCriteriaLabel(props.condition.type),
    aggregation: getAggregationLabel(props.condition.aggregation ?? null, props.modifiers),
    content: renderCriteria(props.condition.type, props.condition.value, stepNames.value, healthConstantsList.value, namedEntitiesList.value, variablesList.value),
    modifiers: getModifiers(props.condition.value),
  }
})

function removeCondition(condition: Condition) {
  // FIXME: this emit should trigger a different removeCondition mutation, which takes a condition instead of a conditionGroup
  emit('remove-condition', { condition, groupId: props.condition.id })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.condition)
        ? (_openBlock(), _createElementBlock("div", {
            key: `condition_list_${_ctx.condition.type}`,
            class: "row no-gutters align-items-start"
          }, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(getConditionLabel)(conditionMod.value)), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(MultipleValueFilter, {
                    filter: conditionMod.value,
                    "can-edit": _ctx.canRemoveCondition,
                    onRemoveCondition: removeCondition
                  }, null, 8, ["filter", "can-edit"])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})