import _ from 'lodash'

import { actions } from '@/store/modules/study/actions'
import { mutations } from '@/store/modules/study/mutations'
import { state } from '@/store/modules/study/state'
import { emptyStudy, emptyStudyNode } from '@/store/modules/study/utils'
import { uniqueId } from '@/study/utils'

import Diagram from '../diagram/diagram'
import Api from '../misc/api'
import {
  computeNewStepPosition,
  conditionIdPrefix,
  convertCriterionToStudyNode,
  findParentInStudy,
  findStudyNodeInStudy,
  resetStudyNodeIds,
  stepIdPrefix,
  translateStepY,
  updateAllDiagramPositions,
  updateStepsPositions,
} from './utils'

let diagram = null

const DEFAULT_TYPE = 'patient'

export default {
  state: {
    currentStudy: emptyStudy(),
    patientList: [],
    patientCount: 0,  // patients count for the current study's last step
    currentStudyStats: [], // stats about patients in the selected study, grouped by steps
    currentStudyStep: null, // current in patient list/page
    currentStudyNode: null, // current in patient list/page
    selectedStudyNode: null, // selected in diagram
    selectedStep: null, // selected in diagram
    studyComponentReady: false,
    paperReady: false,
    studies: [],

    request: {
      createStudy:               { fetching: false, error: null, ok: false },
      updateStudy:               { fetching: false, error: null, ok: false },
      getStudies:                { fetching: false, error: null, ok: false },
      removeStudy:               { fetching: false, error: null, ok: false },
      enableStudy:               { fetching: false, error: null, ok: false },
      disableStudy:              { fetching: false, error: null, ok: false },
      validateStudy:             { fetching: false, error: null, ok: false },
      unvalidateStudy:           { fetching: false, error: null, ok: false },
      fetchStudy:                { fetching: false, error: null, ok: false },
      updateStudyAttributes:     { fetching: false, error: null, ok: false },
      addStudyStep:              { fetching: false, error: null, ok: false },
      updateStepAttributes:      { fetching: false, error: null, ok: false },
      addStudyNode:              { fetching: false, error: null, ok: false },
      fetchStudyNode:            { fetching: false, error: null, ok: false },
      updateStudyNodeAttributes: { fetching: false, error: null, ok: false },
      fetchPatientCount:         { fetching: false, error: null, ok: false },
      fetchStudyStats:           { fetching: false, error: null, ok: false },
      updateStudyUsers:          { fetching: false, error: null, ok: false },
      studyAddVariable:          { fetching: false, error: null, ok: false },
      fetchStudyVariablesStats:  { fetching: false, error: null, ok: false },
      createStudyFromFile:       { fetching: false, error: null, ok: false },
    },
    ...state,
  },
  getters: {
    stepNames(state) {
      return state.currentStudy.steps.map((s) => ({ id: s.id, name: s.name, position: s.position }))
    },
    lastStep(state) {
      if (state.currentStudy.steps.length === 0) {
        return null
      }
      return _.maxBy(state.currentStudy.steps, 'position')
    }
  },
  mutations: {
    createStudyStart(state) {
      state.request.createStudy = { fetching: true, error: null, ok: false }
    },
    createStudySuccess(state, study) {
      state.request.createStudy.fetching = false
      state.request.createStudy.ok = true
      state.currentStudy = study
    },
    createStudyError(state, res) {
      state.request.createStudy.fetching = false
      state.request.createStudy.error = res
    },
    fetchStudyStart(state) {
      state.request.fetchStudy = { fetching: true, error: null, ok: false }
    },
    fetchStudySuccess(state, study) {
      state.request.fetchStudy.fetching = false
      state.request.fetchStudy.ok = true
      state.currentStudy = study
    },
    fetchStudyError(state, res) {
      state.request.fetchStudy.fetching = false
      state.request.fetchStudy.error = res.message
    },
    updateStudyStart(state) {
      state.request.updateStudy = { fetching: true, error: null, ok: false }
    },
    updateStudySuccess(state, study) {
      state.request.updateStudy.fetching = false
      state.request.updateStudy.ok = true
      state.currentStudy = study
    },
    updateStudyError(state, res) {
      state.request.updateStudy.fetching = false
      state.request.updateStudy.error = res
    },
    updateStudyAttributesStart(state) {
      state.request.updateStudyAttributes = { fetching: true, error: null, ok: false }
    },
    updateStudyAttributesSuccess(state, resStudy) {
      state.request.updateStudyAttributes.fetching = false
      state.request.updateStudyAttributes.ok = true
      state.currentStudy.name = resStudy.name
      state.currentStudy.updatedAt = resStudy.updatedAt
    },
    updateStudyAttributesError(state, res) {
      state.request.updateStudyAttributes.fetching = false
      state.request.updateStudyAttributes.error = res
    },
    addStudyStepStart(state) {
      state.request.addStudyStep.fetching = true
    },
    addStudyStepSuccess(state, resStudyStep) {
      state.request.addStudyStep.fetching = false
      state.request.addStudyStep.ok = true
      const i = state.currentStudy.steps.findIndex((s) => s.position === resStudyStep.position)
      state.currentStudy.steps.splice(i, 1, resStudyStep)
    },
    addStudyStepError(state, res) {
      state.request.addStudyStep.fetching = false
      state.request.addStudyStep.error = res
    },
    updateStudyStepAttributesStart(state) {
      state.request.updateStudyStepAttributes = { fetching: true, error: null, ok: false }
    },
    updateStudyStepAttributesSuccess(state, resStudyStep) {
      state.request.updateStudyStepAttributes.fetching = false
      state.request.updateStudyStepAttributes.ok = true
      const studyStep = state.currentStudy.steps.find((s) => s.id === resStudyStep.id)
      studyStep.name = resStudyStep.name
      studyStep.isCore = resStudyStep.isCore
    },
    updateStudyStepAttributesError(state, res) {
      state.request.updateStudyStepAttributes.fetching = false
      state.request.updateStudyStepAttributes.error = res
    },
    updateStudyUsersStart(state) {
      state.request.updateStudyUsers = { fetching: true, error: null, ok: false }
    },
    updateStudyUsersSuccess(state, res) {
      state.request.updateStudyUsers.fetching = false
      state.request.updateStudyUsers.or = true
      state.currentStudy.studyUsers = res.studyUsers
    },
    updateStudyUsersError(state, res) {
      state.request.updateStudyUsers.fetching = false
      state.request.updateStudyUsers.error = res
    },
    studyAddVariableStart(state) {
      state.request.studyAddVariable = { fetching: true, error: null, ok: false }
    },
    studyAddVariableSuccess(state, res) {
      state.request.studyAddVariable = { fetching: false, ok: true, error: null }
      state.currentStudy.variables = res.variables
    },
    studyAddVariableError(state, res) {
      state.request.studyAddVariable = { fetching: false, ok: false, error: res }
    },
    fetchStudyVariablesStatsStart(state) {
      state.request.fetchStudyVariablesStats = { fetching: true, error: null, ok: false }
    },
    fetchStudyVariablesStatsSuccess(state, res) {
      state.request.fetchStudyVariablesStats = { fetching: false, ok: true, error: null }
      if (!state.currentStudy.variablesStats) {
        state.currentStudy.variablesStats = {}
      }
      state.currentStudy.variablesStats = { ...state.currentStudy.variablesStats, ...res }
    },
    fetchStudyVariablesStatsError(state, res) {
      state.request.fetchStudyVariablesStats = { fetching: false, ok: false, error: res }
    },
    addStudyNodeStart(state) {
      state.request.addStudyNode.fetching = true
    },
    addStudyNodeSuccess(state, resStudyNode) {
      state.request.addStudyNode.fetching = false
      state.request.addStudyNode.ok = true
      const parent = findStudyNodeInStudy(state.currentStudy, resStudyNode.parentId)
      // we want to find the last created node, => the only node with a non numeric id
      const i = parent.children.findIndex((s) => isNaN(s.id))
      parent.children.splice(i, 1, resStudyNode)
    },
    addStudyNodeError(state, res) {
      state.request.addStudyNode.fetching = false
      state.request.addStudyNode.error = res
    },
    updateStudyNodeAttributesStart(state) {
      state.request.updateStudyNodeAttributes = { fetching: true, error: null, ok: false }
    },
    updateStudyNodeAttributesSuccess(state, resStudyNode) {
      state.request.updateStudyNodeAttributes.fetching = false
      state.request.updateStudyNodeAttributes.ok = true
      const studyNode = findStudyNodeInStudy(state.currentStudy, resStudyNode.id)
      studyNode.name = resStudyNode.name
      studyNode.diagramProperties = resStudyNode.diagramProperties
    },
    updateStudyNodeAttributesError(state, res) {
      state.request.updateStudyNodeAttributes.fetching = false
      state.request.updateStudyNodeAttributes.error = res
    },
    getStudiesStart(state) {
      state.request.getStudies = { fetching: true, error: null, ok: false }
    },
    getStudiesSuccess(state, response) {
      state.request.getStudies = { fetching: false, error: null, ok: true }
      state.studies = _.map(response.studies, (study) => {
        let stats = _.find(response.stats, (stat) => stat.id === study.id)
        if (!stats) {
          stats = { countPatient: 0 }
        }
        return { ...study, ...stats }
      })
    },
    getStudiesError(state, res) {
      state.request.getStudies = { fetching: false, error: res.message, ok: false }
      state.studies = []
    },
    enableStudyStart(state) {
      state.request.enableStudy = { fetching: true, error: null, ok: false }
    },
    enableStudySuccess(state, search) {
      state.request.enableStudy = { fetching: false, error: false, ok: true }
      state.studies = _.map(state.studies, (s) => s.id === search.id ? { ...s, ...search } : s)
    },
    enableStudyError(state, res) {
      state.request.enableStudy = { fetching: false, error: res.message, ok: false }
    },

    disableStudyStart(state) {
      state.request.disableStudy = { fetching: true, error: null, ok: false }
    },
    disableStudySuccess(state, search) {
      state.request.disableStudy = { fetching: false, error: false, ok: true }
      state.studies = _.map(state.studies, (s) => s.id === search.id ? { ...s, ...search } : s)
    },
    disableStudyError(state, res) {
      state.request.disableStudy = { fetching: false, error: res.message, ok: false }
    },

    validateStudyStart(state) {
      state.request.validateStudy = { fetching: true, error: null, ok: false }
    },
    validateStudySuccess(state, study) {
      state.request.validateStudy = { fetching: false, error: false, ok: true }
      state.currentStudy =  { ...state.currentStudy, ...study }
      state.studies = _.map(state.studies, (s) => s.id === study.id ? { ...s, ...study } : s)
    },
    validateStudyError(state, res) {
      state.request.validateStudy = { fetching: false, error: res.message, ok: false }
    },
    unvalidateStudyStart(state) {
      state.request.unvalidateStudy = { fetching: true, error: null, ok: false }
    },
    unvalidateStudySuccess(state, study) {
      state.request.unvalidateStudy = { fetching: false, error: false, ok: true }
      state.currentStudy =  { ...state.currentStudy, ...study }
      state.studies = _.map(state.studies, (s) => s.id === study.id ? { ...s, ...study } : s)
    },
    unvalidateStudyError(state, res) {
      state.request.unvalidateStudy = { fetching: false, error: res.message, ok: false }
    },

    removeStudyStart(state) {
      state.request.removeStudy = { fetching: true, error: null, ok: false }
    },
    removeStudySuccess(state, studyId) {
      state.request.removeStudy = { fetching: false, error: false, ok: true }
      state.studies = _.filter(state.studies, (s) => s.id !== studyId)
    },
    removeStudyError(state, res) {
      state.request.removeStudy = { fetching: false, error: res.message, ok: false }
    },
    fetchStudyNodeStart(state) {
      state.request.fetchStudyNode = { fetching: true, error: null, ok: false }
    },
    fetchStudyNodeSuccess(state, response) {
      state.currentStudyNode = response
      state.request.fetchStudyNode = { fetching: false, error: null, ok: true }
    },
    fetchStudyNodeError(state, res) {
      state.request.fetchStudyNode = { fetching: false, error: res.message, ok: false }
    },

    setCurrentStudyStep(state, stepId) {
      state.currentStudyStep = state.currentStudy.steps.find((s) => s.id === stepId) ?? null
    },
    setCurrentStudyNode(state, studyNodeId) {
      state.currentStudyNode = findStudyNodeInStudy(state.currentStudy, studyNodeId)
    },
    resetNewStudyNode(state) {
      state.currentStudyNode = null
    },

    resetStudyUpdateRequests(state) {
      state.updateStudyRequest = { fetching: false, error: null, ok: false }
      state.createStudyRequest = { fetching: false, error: null, ok: false }
      _.forEach(state.request, (request, idx) => {
        state.request[idx] = { fetching: false, error: null, ok: false }
      })
    },

    addStudyCondition(state, { studyNodeId, criterion }) {
      const parent = findStudyNodeInStudy(state.currentStudy, studyNodeId)
      if (!parent.conditionList.find((c) => c.type === criterion.type && c.value === criterion.value)) {
        const newId = uniqueId(conditionIdPrefix)
        parent.conditionList.push({
          id: newId,
          ...criterion
        })
      }
    },

    addStep(state, { copyFrom, name }) {
      const newId = uniqueId(stepIdPrefix)
      const lastStep = _.maxBy(state.currentStudy.steps, 'position')
      const newPosition = lastStep ? lastStep.position + 1 : 0

      let rootNode = emptyStudyNode(true)
      if (copyFrom) {
        rootNode = _.cloneDeep(copyFrom.rootNode)
        resetStudyNodeIds(rootNode)
      }

      state.currentStudy.steps.push({
        id: newId,
        name: copyFrom ? (`Copie de ${copyFrom.name}`) : name,
        type: copyFrom ? copyFrom.type : DEFAULT_TYPE,
        position: newPosition,
        isCore: newPosition === 0 || (lastStep.isCore && state.currentStudy.validatedAt === null),
        copyFromY: copyFrom ? copyFrom.rootNode.diagramProperties.y : null,
        rootNode: {
          ...rootNode,
        }
      })
    },
    addStudyCriterion(state, { parentId, criterion }) {
      const parent = findStudyNodeInStudy(state.currentStudy, parentId)
      const newNodePosition = { x: parent.diagramProperties.x + 75, y: parent.diagramProperties.y + 75 }
      const newNode = convertCriterionToStudyNode(criterion, parent.level + 1, newNodePosition)
      parent.children.push(newNode)
    },
    addStudyNode(state, { parent }) {
      const parentNode = findStudyNodeInStudy(state.currentStudy, parent.id)
      if (!parentNode) {
        return
      }
      parentNode.children.push({
        ...emptyStudyNode(),
        level: parentNode.level + 1,
        diagramProperties: { x: parentNode.diagramProperties.x + 75, y: parentNode.diagramProperties.y + 75 }
      })
    },
    addStudyNodeFromCondition(state, { parent, condition, name }) {
      const parentNode = findStudyNodeInStudy(state.currentStudy, parent.id)
      if (!parentNode) {
        return
      }
      const newStudyNode = {
        ...emptyStudyNode(),
        name: name,
        operator: 'AND',
        level: parentNode.level + 1,
        diagramProperties: { x: parentNode.diagramProperties.x + 75, y: parentNode.diagramProperties.y + 75 }
      }
      const newStudyNodeChild = {
        ...emptyStudyNode(),
        conditionList: [condition],
        operator: 'OR',
        level: newStudyNode.level + 1,
        diagramProperties: { x: 0, y: 0 }
      }

      parentNode.children.push(newStudyNode)
      newStudyNode.children.push(newStudyNodeChild)
    },
    removeStudyCondition(state, { studyNodeId, condition }) {
      const parent = findStudyNodeInStudy(state.currentStudy, studyNodeId)
      parent.conditionList = _.filter(parent.conditionList, (c) => c.id !== condition.id)
    },
    removeStudyNode(state, studyNode) {
      const parent = findParentInStudy(state.currentStudy, studyNode)
      if (parent) {
        parent.children = _.filter(parent.children, (c) => c.id !== studyNode.id)
      }
    },
    removeStep(state, step) {
      const steps = []
      // remove step and update positions
      _.forEach(state.currentStudy.steps, (s) => {
        if (s.id === step.id) {
          return
        }
        if (s.position > step.position) {
          s.position--
        }
        steps.push(s)
      })
      state.currentStudy.steps = steps
    },
    // usefull when state.currentStudy is completely refreshed as we loose selectedStep|StudyNode object reference so it is not reactive anymore
    refreshSelected(state) {
      if (state.selectedStep) {
        state.selectedStep = state.currentStudy.steps.find((s) => s.id === state.selectedStep.id)
      }
      if (state.selectedStudyNode) {
        state.selectedStudyNode = findStudyNodeInStudy(state.currentStudy, state.selectedStudyNode.id)
      }
    },
    resetSelected(state) {
      if (state.selectedStep) {
        state.selectedStep = null
      }
      if (state.selectedStudyNode) {
        state.selectedStudyNode = null
      }
    },
    updateStudyProperty(state, { prop, value }) {
      state.currentStudy[prop] = value
    },
    updateStudyNodeProperty(state, { studyNodeId, prop, value }) {
      const studyNode = findStudyNodeInStudy(state.currentStudy, studyNodeId)
      if (studyNode) {
        studyNode[prop] = value
      }
    },
    updateStepProperty(state, { stepId, prop, value }) {
      const step = state.currentStudy.steps.find((s) => s.id === stepId)
      if (step) {
        step[prop] = value
      }
    },
    refreshStep(state, stepId) {
      const step = state.currentStudy.steps.find((s) => s.id === stepId)
      diagram.refreshStep(step)
    },
    refreshStudyNode(state, studyNodeId) {
      const studyNode = findStudyNodeInStudy(state.currentStudy, studyNodeId)
      diagram.refreshStudyNode(studyNode)
    },
    updateStepsPositions(state, newPositions) {
      state.currentStudy.steps = updateStepsPositions(state.currentStudy.steps, newPositions)

      // refresh links and refresh all positions
      diagram.removeAllStepsLinks()
      updateAllDiagramPositions(state.currentStudy, diagram)
      let previousStep
      for (const step of state.currentStudy.steps) {
        if (previousStep) {
          diagram.addStepLink(previousStep, step)
        }
        previousStep = step
      }
    },
    updateStudyCriterion(state, { studyNodeId, criterion }) {
      const toBeUpdatedNode = findStudyNodeInStudy(state.currentStudy, studyNodeId)
      const newNode = convertCriterionToStudyNode(criterion, toBeUpdatedNode.level, toBeUpdatedNode.diagramProperties)
      Object.assign(toBeUpdatedNode, newNode)
    },

    // patient list count
    fetchPatientCountStart(state) {
      state.request.fetchPatientCount = { fetching: true, error: null, ok: false }
    },
    fetchPatientCountSuccess(state, response) {
      state.request.fetchPatientCount = { fetching: false, error: null, ok: true }
      state.patientCount = response
    },
    fetchPatientCountError(state, res) {
      state.request.fetchPatientCount = { fetching: false, error: res.message, ok: false }
      state.patientCount = 0
    },

    // patient list count
    fetchStudyStatsStart(state) {
      state.request.fetchPatientCount = { fetching: true, error: null, ok: false }
    },
    fetchStudyStatsSuccess(state, response) {
      state.request.fetchPatientCount = { fetching: false, error: null, ok: true }
      state.currentStudyStats = response
    },
    fetchStudyStatsError(state, res) {
      state.request.fetchPatientCount = { fetching: false, error: res.message, ok: false }
      state.currentStudyStats = []
    },

    // SVG rendering
    setPaperElement(state, element) {
      if (element === null) {
        state.paperReady = false
        diagram.reset()
        return
      }
      diagram = new Diagram(element, this.commit, this.dispatch)
      state.paperReady = true
    },
    setDiagramReadonly(state, isReadonly) {
      diagram.setReadonly(isReadonly)
    },
    setStudyComponentReady(state, ready) {
      state.studyComponentReady = ready
    },
    renderStudyNode(state, { element, studyNode }) {
      diagram.addStudyNode(element, studyNode)
      if (state.studyComponentReady) {
        let parent = findParentInStudy(state.currentStudy, studyNode)
        if (parent.level === 1) {
          parent = findParentInStudy(state.currentStudy, parent)
        }
        diagram.addStudyNodeLink(parent, studyNode)
        diagram.createChildrenLinks(studyNode, studyNode.children)
      }
    },
    renderStep(state, { element, step }) {
      if (!step.rootNode.diagramProperties.x) {
        step.rootNode.diagramProperties = computeNewStepPosition(diagram, element, state.currentStudy.steps.find((s) => s.position === step.position - 1))
        if (step.copyFromY) {
          translateStepY(step, step.rootNode.diagramProperties.y - step.copyFromY, true)
          delete step.copyFromY
        }
      }
      diagram.addStep(element, step)
      diagram.initStepLinks(step)
    },
    renderStepLinkWithPrevious(state, step) {
      if(step.position === 0) {
        return
      }
      const sourceStep = state.currentStudy.steps.find((s) => s.position === step.position - 1)
      const targetStep = state.currentStudy.steps.find((s) => s.id === step.id)
      if (sourceStep && targetStep) {
        diagram.addStepLink(sourceStep, targetStep)
      }
    },
    selectElement(state, { elType, elId }) {
      switch (elType) {
        case 'sancare.Step':
          state.selectedStudyNode = null
          state.selectedStep = state.currentStudy.steps.find((s) => s.id === elId)
          break
        case 'sancare.StudyNode':
          state.selectedStudyNode = findStudyNodeInStudy(state.currentStudy, elId)
          state.selectedStep = null
          break
        default:
          state.selectedStudyNode = null
          state.selectedStep = null
      }
    },
    setNodeId(state, { oldId, newId }) {
      diagram.setNodeId(oldId, newId)
    },
    setStepId(state, { oldId, newId }) {
      diagram.setStepId(oldId, newId)
    },
    setStudyNodePosition(state, { id, x, y }) {
      const studyNode = findStudyNodeInStudy(state.currentStudy, id)
      if (studyNode) {
        studyNode.diagramProperties.x = x
        studyNode.diagramProperties.y = y
      }
    },
    setStepPosition(state, { id, x, y }) {
      const step = state.currentStudy.steps.find((s) => s.id === id)
      if (step) {
        step.rootNode.diagramProperties.x = x
        step.rootNode.diagramProperties.y = y
      }
    },
    unzoomDiagram() {
      diagram.scale(0.9)
    },
    zoomDiagram() {
      diagram.scale(1.1)
    },
    unrenderStudyNode(state, studyNode) {
      diagram.removeStudyNode(studyNode)
    },
    unrenderStep(state, step) {
      diagram.removeStep(step)
      const nextStep = state.currentStudy.steps.find((s) => s.position === step.position)
      if (nextStep) {
        this.commit('renderStepLinkWithPrevious', nextStep)
      }
    },
    resetStudyStats(state) {
      state.currentStudyStats = []
    },
    resetStudy(state) {
      state.currentStudy = emptyStudy()
      state.currentStudyStep = null
      state.currentStudyNode = null
      state.patientCount = 0
    },
    resetDiagram(state) {
      state.selectedStudyNode = null
      state.selectedStep = null
      diagram.reset()
    },
    setCurrentStudyVariables(state, variables) {
      state.currentStudy.variables = variables
    },

    // create study from json file
    createStudyFromFileStart(state) {
      state.request.createStudyFromFile = { fetching: true, error: null, ok: false }
    },
    createStudyFromFileSuccess(state, study) {
      state.request.createStudyFromFile = { fetching: false, error: null, ok: true }
      state.currentStudy = study
      this.commit('toast/setSuccess', 'La structure de l\'étude a bien été importée')
    },
    createStudyFromFileError(state, res) {
      state.request.createStudyFromFile = { fetching: false, error: res.message, ok: false }
      this.commit('toast/setError', res.message)
    },
    ...mutations,
  },
  actions: {
    createStudy({ commit, state }) {
      return Api.post(
        {
          url: '/api/study/',
          body: state.currentStudy,
          startMut: 'createStudyStart',
          successMut: 'createStudySuccess',
          errorMut: 'createStudyError'
        },
        commit
      )
    },
    async fetchStudy({ commit, state }, { studyId, forceFetch }) {
      if(!forceFetch && Number(studyId) === state.currentStudy.id) {
        return
      }

      await Api.get(
        {
          url: `/api/study/${studyId}`,
          startMut: 'fetchStudyStart',
          successMut: 'fetchStudySuccess',
          errorMut: 'fetchStudyError',
        },
        commit
      )
    },
    updateStudy({ commit, state }) {
      return Api.put(
        {
          url: `/api/study/${state.currentStudy.id}`,
          body: state.currentStudy,
          startMut: 'updateStudyStart',
          successMut: 'updateStudySuccess',
          errorMut: 'updateStudyError'
        },
        commit
      )
    },
    updateStudyAttributes({ commit }, study) {
      return Api.put(
        {
          url: `/api/study/${study.id}/attributes`,
          body: {
            id: study.id,
            name: study.name,
            privacy: study.privacy,
            foundationDataId: study.foundation?.dataGeneratedId ?? null,
          },
          startMut: 'updateStudyAttributesStart',
          successMut: 'updateStudyAttributesSuccess',
          errorMut: 'updateStudyAttributesError'
        },
        commit
      )
    },
    addStudyStep({ commit }, { studyStep, studyId }) {
      return Api.post(
        {
          url: '/api/study-step/',
          body: {
            parentId: studyId,
            step: studyStep,
          },
          startMut: 'addStudyStepStart',
          successMut: 'addStudyStepSuccess',
          errorMut: 'addStudyStepError'
        },
        commit
      )
    },
    updateStepAttributesById({ dispatch, state }, stepId) {
      const step = state.currentStudy.steps.find((s) => s.id === stepId)
      dispatch('updateStudyNodeAttributes', step.rootNode)
    },
    updateStudyStepAttributes({ commit }, studyStep) {
      return Api.put(
        {
          url: `/api/study-step/${studyStep.id}/attributes`,
          body: {
            name: studyStep.name,
            isCore: studyStep.isCore,
          },
          startMut: 'updateStudyStepAttributesStart',
          successMut: 'updateStudyStepAttributesSuccess',
          errorMut: 'updateStudyStepAttributesError'
        },
        commit
      )
    },
    updateStudyUsers({ commit }, { studyId, userIds }) {
      return Api.put(
        {
          url: `/api/study/${studyId}/users`,
          body: {
            users: userIds,
          },
          startMut: 'updateStudyUsersStart',
          successMut: 'updateStudyUsersSuccess',
          errorMut: 'updateStudyUsersError'
        },
        commit
      )
    },
    studyAddVariable({ commit }, { studyId, variable }) {
      return Api.post(
        {
          url: `/api/variable/add/to-study/${studyId}`,
          body: variable,
          startMut: 'studyAddVariableStart',
          successMut: 'studyAddVariableSuccess',
          errorMut: 'studyAddVariableError'
        },
        commit
      )
    },
    fetchStudyVariablesStats({ commit }, { studyId, variableIds }) {
      let url = `/api/study/variable/stats/${studyId}`
      const urlParams = {}
      if (variableIds) {
        urlParams.variableIds = variableIds
      }
      if (Object.keys(urlParams).length > 0) {
        url += `?${(new URLSearchParams(urlParams)).toString()}`
      }
      return Api.get(
        {
          url,
          startMut: 'fetchStudyVariablesStatsStart',
          successMut: 'fetchStudyVariablesStatsSuccess',
          errorMut: 'fetchStudyVariablesStatsError'
        },
        commit
      )
    },
    addStudyNode({ commit }, { studyNode, parentId }) {
      return Api.post(
        {
          url: '/api/study-node/',
          body: {
            parentId,
            node: studyNode,
          },
          startMut: 'addStudyNodeStart',
          successMut: 'addStudyNodeSuccess',
          errorMut: 'addStudyNodeError'
        },
        commit
      )
    },
    updateStudyNodeAttributesById({ dispatch, state }, studyNodeId) {
      const studyNode = findStudyNodeInStudy(state.currentStudy, studyNodeId)
      dispatch('updateStudyNodeAttributes', studyNode)
    },
    updateStudyNodeAttributes({ commit }, studyNode) {
      return Api.put(
        {
          url: `/api/study-node/${studyNode.id}/attributes`,
          body: {
            id: studyNode.id,
            name: studyNode.name,
            diagramProperties: studyNode.diagramProperties,
          },
          startMut: 'updateStudyNodeAttributesStart',
          successMut: 'updateStudyNodeAttributesSuccess',
          errorMut: 'updateStudyNodeAttributesError'
        },
        commit
      )
    },
    getStudies({ commit }) {
      return Api.get(
        {
          url: '/api/study/',
          startMut: 'getStudiesStart',
          successMut: 'getStudiesSuccess',
          errorMut: 'getStudiesError',
        },
        commit
      )
    },
    removeStudy({ commit }, studyId) {
      return Api.del(
        {
          url: `/api/study/${studyId}`,
          startMut: 'removeStudyStart',
          successMut: () => commit('removeStudySuccess', studyId),
          errorMut: 'removeStudyError',
        },
        commit
      )
    },
    enableStudy({ commit }, studyId) {
      return Api.put(
        {
          url: `/api/study/${studyId}/enable`,
          startMut: 'enableStudyStart',
          successMut: 'enableStudySuccess',
          errorMut: 'enableStudyError'
        },
        commit
      )
    },
    disableStudy({ commit }, studyId) {
      return Api.put(
        {
          url: `/api/study/${studyId}/disable`,
          startMut: 'disableStudyStart',
          successMut: 'disableStudySuccess',
          errorMut: 'disableStudyError'
        },
        commit
      )
    },
    validateStudy({ commit }, studyId) {
      return Api.put(
        {
          url: `/api/study/${studyId}/validate`,
          startMut: 'validateStudyStart',
          successMut: 'validateStudySuccess',
          errorMut: 'validateStudyError'
        },
        commit
      )
    },
    unvalidateStudy({ commit }, studyId) {
      return Api.put(
        {
          url: `/api/study/${studyId}/unvalidate`,
          startMut: 'unvalidateStudyStart',
          successMut: 'unvalidateStudySuccess',
          errorMut: 'unvalidateStudyError'
        },
        commit
      )
    },
    fetchStudyNode({ commit }, studyNodeId) {
      return Api.get(
        {
          url: `/api/study-node/${studyNodeId}`,
          startMut: 'fetchStudyNodeStart',
          successMut: 'fetchStudyNodeSuccess',
          errorMut: 'fetchStudyNodeError',
        },
        commit
      )
    },
    fetchStudyStats({ commit }, studyId) {
      return Api.get({
        url: `/api/patients/stats/study/${studyId}`,
        startMut: 'fetchStudyStatsStart',
        successMut: 'fetchStudyStatsSuccess',
        errorMut: 'fetchStudyStatsError',
      }, commit)
    },
    fetchStudyPatientCount({ commit }, studyId) {
      return Api.get({
        url: `/api/patients/study-count/${studyId}`,
        startMut: 'fetchPatientCountStart',
        successMut: 'fetchPatientCountSuccess',
        errorMut: 'fetchPatientCountError',
      }, commit)
    },
    ...actions,
  }
}
