import { AnnotatedHint, Annotation } from './types'

export function mergeIntervals(intervals: AnnotatedHint[]): AnnotatedHint[] {
  return intervals
    .sort((a, b) => a.startIdx - b.startIdx)
    .reduce((acc: AnnotatedHint[], interval: AnnotatedHint) => {
      if (acc.length === 0) return [interval]

      const previous = acc[acc.length - 1]
      if (previous.endIdx >= interval.startIdx) {
        previous.endIdx = Math.max(previous.endIdx, interval.endIdx)
        previous.annotationValues = Array.from(new Set([
          ...previous.annotationValues,
          ...interval.annotationValues
        ])).sort()
        return acc
      }

      return [...acc, interval]
    }, [])
}


export function insertHint(currentHints: AnnotatedHint[], newHint: AnnotatedHint): AnnotatedHint[] {
  return mergeIntervals([...currentHints, newHint])
}

export function getValidHintsFromAnnotations(annotations: Annotation[]): AnnotatedHint[] {
  if (annotations.length === 0) return []

  return annotations.reduce((accumulatedHints: AnnotatedHint[], annotation: Annotation) => {
    if (annotation.removed) return accumulatedHints

    const currentHints = annotation.hints
      .filter((hint) => !hint.removed)
      .map((hint) => ({ ...hint, annotationValues: [annotation.value] }))

    return accumulatedHints.length === 0
      ? currentHints
      : currentHints.reduce((hints, hint) => insertHint(hints, hint), accumulatedHints)
  }, [] as AnnotatedHint[])
}
