import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "home-list-part" }
const _hoisted_3 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_4 = { class: "home-list-part-title-line d-flex align-items-center" }
const _hoisted_5 = { class: "home-list-part-title my-2" }
const _hoisted_6 = { class: "sancare-btn-group pr-2 mr-1 d-flex no-wrap" }
const _hoisted_7 = { class: "export-btn" }
const _hoisted_8 = { class: "import-btn" }
const _hoisted_9 = {
  key: 0,
  class: "row error-block mt-2 pl-3"
}
const _hoisted_10 = {
  key: 0,
  class: "d-flex align-self-center loader mx-auto my-4"
}
const _hoisted_11 = {
  key: 1,
  class: "d-flex flex-wrap"
}
const _hoisted_12 = { class: "row no-gutters" }
const _hoisted_13 = {
  key: 0,
  class: "col-auto align-self-end"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 1,
  class: "col-auto align-self-end"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { key: 1 }
const _hoisted_19 = ["onClick"]

import { SancareButton, SancareFileDrop } from '@sancare/ui-frontend-commons'
import { RequestStatus } from '@sancare/ui-frontend-commons/src/store/types'
import dayjs from 'dayjs'
import _ from 'lodash'
import { computed, ComputedRef, Ref, ref } from 'vue'

import { downloadFile } from '@/misc/DownloadHelper'
import { criterionPermissions, variablePermissions } from '@/misc/permissions/permissionsHelper'
import { parseJson } from '@/misc/utils'
import { useStore } from '@/store'
import { CriteriaJson } from '@/store/modules/criterion/types'
import { SearchInterface, SearchTypeEnum } from '@/store/modules/search/types'
import { VariablesJson } from '@/store/modules/variable/types'

import useRedirection from '../misc/composables/redirection'
import HomeThumbnail from './HomeThumbnail.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchList',
  props: {
    searchType: {},
    title: {},
    editPath: {},
    bgClass: {},
    searches: {}
  },
  emits: ['copy-search', 'remove-search'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const store = useStore()
const emit = __emit

// TODO: Use SearchListProps as type when Vue version >= 3.3
const props = __props

const currentUser = computed(() => store.state.settings.currentUser)
const permissions = computed(() => props.searchType === SearchTypeEnum.VARIABLE ? variablePermissions : criterionPermissions)
const selectedItems: ComputedRef<number[]> = computed(() => props.searchType === SearchTypeEnum.VARIABLE
  ? store.state.variable.selectedVariableIds
  : store.state.criterion.selectedCriteriaIds
)
const setSelectedItems: ComputedRef<string> = computed(() => props.searchType === SearchTypeEnum.VARIABLE
  ? 'variable/setSelectedVariableIds'
  : 'criterion/setSelectedCriteriaIds'
)
const exportItemsFunction: ComputedRef<string> = computed(() => props.searchType === SearchTypeEnum.VARIABLE
  ? 'variable/exportVariables'
  : 'criterion/exportCriteria'
)
const importItemsFunction: ComputedRef<string> = computed(() => props.searchType === SearchTypeEnum.VARIABLE
  ? 'variable/importVariables'
  : 'criterion/importCriteria'
)
const isFetchingSearches: ComputedRef<number[]> = computed(() => props.searchType === SearchTypeEnum.VARIABLE
  ? store.state.variables.fetchVariablesRequest.fetching
  : store.state.criteria.fetchCriteriaRequest.fetching
)
const orderedSearches = computed(() => _.orderBy(props.searches, [(search) => search.name.toLowerCase()]))
const showOverlay = computed(() =>  !isFetchingSearches.value && orderedSearches.value.length === 0)

const showRemoveConfirm = ref(null)
const exportItemsRequest: Ref<RequestStatus> = ref({ fetching: false, error: null, ok: true })
const isFetchingImportItems: Ref<boolean> = ref(false)
const importFileInput: Ref<HTMLInputElement | null> = ref(null)

const { redirect } = useRedirection()

function removeConfirmSearch(searchId: number) {
  showRemoveConfirm.value = {}
  showRemoveConfirm.value[searchId] = true
}

function copySearch(searchId: number) {
  emit('copy-search', searchId)
}

function removeSearch(searchId: number) {
  emit('remove-search', searchId)
}

const handleSelectItem = (itemId: number): void => {
  store.commit(setSelectedItems.value, itemId)
}

const clearInputFile = () => {
  if (importFileInput.value) importFileInput.value.value = ''
}

async function exportItems(): Promise<void> {
  exportItemsRequest.value = { fetching: true, error: null, ok: false }

  try {
    const exportResponse = await store.dispatch(exportItemsFunction.value, {
      ids: selectedItems.value,
    })

    if (exportResponse.length === null) {
      throw new Error('Une erreur est survenue lors de l\'export.')
    }

    const exportBlob = new Blob([JSON.stringify(exportResponse, null, 2)], { type: 'application/json' })
    const fileName = `${props.searchType}-export-${dayjs().format('YYYY-MM-DD-HH-mm')}.json`
    downloadFile(exportBlob, fileName)

    exportItemsRequest.value.ok = true
  } catch {
    exportItemsRequest.value.error = 'Une erreur est survenue lors de l\'export.'
  } finally {
    exportItemsRequest.value.fetching = false
  }
}

async function importItems(event: Event): Promise<void> {
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0) {
    const file = files[0]

    const rawContent = await file.text()
    const parsedContent = parseJson<CriteriaJson | VariablesJson>(rawContent)
    if (parsedContent === null) {
      store.dispatch('toast/setError', {
        message: 'Le fichier doit être au format JSON.',
        title: 'Erreur lors de l\'import',
        timeout: 15000,
      }, { root: true })

      clearInputFile()
      return
    }

    isFetchingImportItems.value = true

    await store.dispatch(importItemsFunction.value, parsedContent)

    clearInputFile()
    store.commit('resetNewSearch')
    isFetchingImportItems.value = false
  }
}

const handleFileDrop = (file: File) => {
  importItems({ target: { files: [file] } } as unknown as Event)
}

__expose({ importItems, isFetchingImportItems })

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, " Liste des " + _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_7, [
            _createVNode(_unref(SancareButton), {
              "start-icon": "upload",
              text: `Exporter des ${_ctx.title}`,
              class: "d-flex justify-content-center",
              "is-disabled": selectedItems.value.length === 0,
              "is-loading": exportItemsRequest.value.fetching,
              onClick: exportItems
            }, null, 8, ["text", "is-disabled", "is-loading"])
          ])), [
            [_directive_tooltip, {
              content: `Veuillez sélectionner des ${_ctx.title} pour pouvoir les exporter`,
              disabled: selectedItems.value.length > 0,
              triggers: ['hover', 'focus', 'click'],
            }]
          ]),
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_unref(SancareButton), {
              "start-icon": "download",
              text: `Importer des ${_ctx.title}`,
              class: "d-flex justify-content-center",
              "is-loading": isFetchingImportItems.value
            }, null, 8, ["text", "is-loading"]),
            _createElementVNode("input", {
              ref_key: "importFileInput",
              ref: importFileInput,
              type: "file",
              accept: "application/json",
              onChange: importItems
            }, null, 544)
          ])
        ])
      ]),
      (exportItemsRequest.value.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(exportItemsRequest.value.error), 1))
        : _createCommentVNode("", true),
      _createVNode(_unref(SancareFileDrop), {
        "accepted-file-types": ['application/json', '.json'],
        "is-displayed": showOverlay.value,
        class: "mt-3",
        onFileDrop: handleFileDrop
      }, {
        "overlay-content": _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("p", { class: "m-0" }, " Déposez votre fichier JSON ici pour l'importer ", -1)
        ])),
        default: _withCtx(() => [
          (isFetchingSearches.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderedSearches.value, (search) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: search.id,
                    class: "col-3 p-2 fixed-thumbnail"
                  }, [
                    _createVNode(HomeThumbnail, {
                      label: search.name,
                      "header-item": "#",
                      class: _normalizeClass(_ctx.bgClass),
                      "can-select": true,
                      "is-selected": selectedItems.value.includes(search.id),
                      onClick: () => _unref(redirect)(_ctx.editPath+'/'+search.id, {}),
                      onSelect: () => handleSelectItem(search.id)
                    }, {
                      footer: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, [
                          (permissions.value.canCopy())
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createElementVNode("button", {
                                  class: "btn btn-link",
                                  onClick: ($event: any) => (copySearch(search.id))
                                }, " Copier ", 8, _hoisted_14)
                              ]))
                            : _createCommentVNode("", true),
                          (permissions.value.canDelete(search, currentUser.value))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                (showRemoveConfirm.value && showRemoveConfirm.value[search.id])
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                      _createElementVNode("button", {
                                        class: "btn btn-link text-danger",
                                        onClick: ($event: any) => (removeSearch(search.id))
                                      }, " Confirmer ", 8, _hoisted_17)
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                      _createElementVNode("button", {
                                        class: "btn btn-link text-danger",
                                        onClick: ($event: any) => (removeConfirmSearch(search.id))
                                      }, " Supprimer ", 8, _hoisted_19)
                                    ]))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["label", "class", "is-selected", "onClick", "onSelect"])
                  ]))
                }), 128))
              ]))
        ]),
        _: 1
      }, 8, ["is-displayed"])
    ])
  ]))
}
}

})